import styled from "styled-components";
import { ifProp, prop } from "styled-tools";
import { ITabTitleStyled } from "./interface";

export const StyledTabTitle = styled.button<ITabTitleStyled>`
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  border-width: ${ifProp({ isActive: true }, "0 0 3px", "0 0 0px")};
  border-style: solid;
  outline: 0;
  border-color: ${prop("theme.colors.primary", "#46C6C2")};
  color: ${ifProp({ isActive: true }, prop("theme.colors.primary", "#46C6C2"), "#868686")};
  font-weight: ${ifProp({ isActive: true }, "500", "400")};
  background-color: ${ifProp("bgColor", prop("bgColor"), "#ffffff")};
  line-height: 26px;
  white-space: nowrap;
`;

export const Li = styled.li`
  @media (max-width: 995px) {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
`;
