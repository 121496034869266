import styled from "styled-components";
import { IBoxTable } from "./interface";


export const TableBox = styled.div`
  margin-top: 20px;
  border-radius: 30px;
  border: 1px solid #F1F1F1;
`;

export const WrapperTable = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`

export const WrapperInvalidEmails = styled.div`
  width: 100%;
  text-align: center;
  overflow-y: auto;
  max-height: 200px;
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const WrapperModalRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
`

export const TitleModalRemove = styled.div`
  margin-bottom: 29px;
`

export const SubtitleModalRemove = styled.div`
  margin-bottom: 6px;
`

export const WrapperButtonRemove = styled.div`
  width: 290px;
  margin-top: 30px;
`

export const WrapperShowInvalidEmails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FileName = styled.div`
  display: flex;
  flex-direction: "row";
  margin-top: 20px;
  @media(max-width: 750px){
  }
`

export const WrapperSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: -20px;
  
`


export const ButtonInvite = styled.button`
  font-size: 13px;
  background-color: #30AA44;
  border: none;
  margin-top: 16px;
  border-radius: 30px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  width: 280px;
  height: 35px;
`

export const WrapperPagination = styled.div`
  display: flex;
  height: 55px;
  justify-content: space-between;
  @media(max-width: 490px){
    display: block;
    height: 70px;
  }
  
`

export const PaginationButton = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 18px;
  margin-right: 18px;
  align-self: center;
  @media(max-width: 490px){
    display: block;
  }
  
`

export const PageNumbers = styled.div`
  display: flex;
  margin-left: 15px;
  @media(max-width: 490px){
    display: block;
    
  }
`

export const ItemPerPage = styled.div`
  align-self: center;
  margin-left: 16px;
  @media(max-width: 490px){
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
`

export const Arrows = styled.div`
   display: flex;
   @media(max-width: 490px){
    justify-content: space-between;
    margin-top: -30px;
  }
`

export const Select = styled.select`
  border: none;
  margin-left: 5px;
  cursor: pointer;
`

export const SendInviteButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`


export const WrapperAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 29px;
`

export const WrapperUnsuccessEmails = styled.div`
display: flex;
justify-content: center;
align-items: center; 
flex-direction: column; 
overflow-y: scroll;
margin-bottom: 20px;
margin-top: 20px;
height: 300px;
`
export const SectionWrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content:center;
`;
export const WrapperBack = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;