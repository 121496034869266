import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ThemeContext } from "styled-components";
import Icon from "../Icon";
import Typography from "../Typography";
import { IWebView } from "./interface";
import { BackButton, Header, Iframe, Page } from "./styles";

const WebView: React.FC<IWebView> = ({url, onBack, title}) => {
  const context = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);

  return <Page>
    <Header>
      <BackButton onClick={onBack}>
        <Icon name="arrow-left" color={context.colors.primaryContrast} />
        <Typography fontSize="16px" fontWeight="normal" marginLeft="10px">Voltar</Typography>
      </BackButton>
      <Typography fontSize="16px" fontWeight="normal">{title || "People Hub"}</Typography>
      { loading ? (
        <Spinner
          animation="border"
          size="sm"
          style={{ margin: "2px 10px" }}
        />
      ) : null}
    </Header>
    <Iframe src={url} onLoadStart={() => setLoading(true)} onLoad={() => setLoading(false)} />
  </Page>;
};

export default WebView;
