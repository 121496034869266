import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { useHistory } from "react-router-dom";
import { GridWrapper, SectionTitle, SectionWrapper, WrapperBack, } from "./styles";
import api from "../../../../services/api";
import { Paper } from "@material-ui/core";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import { ThemeContext } from "styled-components";


export interface WorkerType {
  selectedCompany: string;
  id: string;
  phone: string;
  cpf: string;
  birth: string;
  firstName: string;
  gender: string;
  lastName: string;
  email: string;
  canAccess: boolean;
  companyId: string;
  shopwoker: {
    length: number;
    id: string,
    shopid: string,
    workerid: string,
    valor: string;
    quantidade: string;
    createdAt: string,
    updatedAt: string,
    shops: {
      id: string,
      produto: string;
      produtoimage: string;
      sku: string;
      preco: number;
      estoque: string;
      pagamento: string;
      categoria: string;
      shortdescription: string;
      companyId: string;
    }
  }
}



function Historico() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useContext(ThemeContext);
  const isMobileScreen = useMediaQuery('only screen and (max-width: 768px)');


  const getWorkers = async () => {
    try {
      const params = {
        companyId: companyId
      };
      const { data } = await api.get("/worker/historico", { params });

      setWorkers(data); // Ajuste para usar o objeto de dados correto
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getWorkers();
  }, []);


  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Historico de Compras"
          subtitle="Aqui você pode gerenciar o historico de colaboradores que compraram produtos no shopping."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
            <Table stickyHeader>
              {/* Cabeçalhos das colunas */}
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Colaborador</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>CPF</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Imagem</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Produto</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Valor</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Quantidade</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Método de Pagamento</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Data da Compra</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {workers.map((b) => (
                  <TableRow key={b.id}>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.firstName} {b.lastName}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.cpf}
                    </TableCell>
                    <TableCell align="center">
                      {b.shopwoker && b.shopwoker.length > 0 && b.shopwoker[0].shops && (
                        <Tooltip title={b.shopwoker[0].shops.produto}>
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "cover",
                              borderRadius: "4px",
                              marginLeft: "20%"
                            }}
                            src={b.shopwoker[0].shops.produtoimage}
                            alt={b.shopwoker[0].shops.produto}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.shopwoker[0].shops.produto}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      R$ {Number(b.shopwoker[0].valor).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.shopwoker[0].quantidade}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.shopwoker[0].shops.pagamento}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {new Date(b.shopwoker[0].createdAt).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </WrapperBack>
      </GridWrapper>
    </SectionWrapper>
  );
}

export default Historico;