import { gql } from "@apollo/client";

export const FRAGMENT_PRODUCT_REQUEST_STORE = gql`
    fragment productRequestFields on ProductRequest {
        __typename
        id
        product {
            id
        }
        contact {
            firstName
            lastName
            email
            phone
            message
        }
        type
    }
`;

export const PRODUCT_DISABLE_REQUEST_MUTATION = gql`
    mutation productDisableRequest(
        $productId: ID!
        $companyId: ID!
        $contact: ProductRequestContactInput!
    ){
        productRequestCreate(
        productId: $productId
        companyId: $companyId
        contact: $contact
        productRequestType: PRODUCT_DISABLE
    ) {
        __typename
        ... on ProductRequest {
            ...productRequestFields
        }
        ... on ErrorWithMessage {
            message
        }
    }
    }
    ${FRAGMENT_PRODUCT_REQUEST_STORE}
`;