import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

import { IGraphicPie } from "./interface";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={5}
        textAnchor="middle"
        fill="#000000"
        fontSize="20px"
        fontWeight="500"
      >
        {props.value}
      </text>

      <text
        x={cx}
        y={cy}
        dy={25}
        textAnchor="middle"
        fill="#888888"
        fontSize="12px"
        fontWeight="500"
      >
        {props.value <= 1 ? `Usuário ativo` : `${props.name}`}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const wrapperStyled = {
  content: "",
  background: "#5e5e5e",
  borderRadius: "5px",
  borderLeft: "10px solid transparent",
  borderRight: "10px solid #5e5e5e",
  borderTop: "10px solid #5e5e5e",
  borderBottom: "10px solid transparent",
  right: "19px",
  bottom: "-19px",
};

const labelStyled = {
  color: "white",
};

const GraphicPie: React.FC<IGraphicPie> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };
  return (
    <ResponsiveContainer
      width="100%"
      height={props.height || 300}
    >
      <PieChart height={props.height || 300}>
        <Tooltip contentStyle={wrapperStyled} itemStyle={labelStyled} />
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={props.data}
          cx="50%"
          cy="50%"
          innerRadius={75}
          outerRadius={92}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          <Cell fill={props.color} />
          <Cell fill={`${props.color}${99}`} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default GraphicPie;
