import React, { useContext, useEffect, useState } from "react";
import { Panel } from "../../../../common/components/Panel";
import PageTitle from "../../../../common/components/PageTitle";
import { ButtonWrapper, Button, TableWrapper, ContainerTable, GridWrapper, SectionWrapper, WrapperBack } from "./styles";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import InputBackground from "../../../../common/components/InputBackground";
import {
  Table,
  Theader,
  TH,
  Tr,
} from "../../../../common/components/Table/styles";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import Item from "../../../../common/components/DropdownDefault/Items";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import Icon from "../../../../common/components/Icon";
import List from "../../../../common/components/DropdownDefault/List";
import Pagination from "../../../../common/components/Pagination";

import { OperationVariables, QueryResult, useQuery } from "@apollo/client";
import Paginator from "../../../../common/components/Paginator";
import CompanySelect from "../../../../common/components/CompanySelect";
import { companyAdmins, companyAdminsVariables } from "./types/companyAdmins";

import { COMPANY_ADMIN_QUERY } from "./graphql";
import ModalCompanyAdmin from "./Modal";
import Loading from "../../../../common/components/Loading";
import AuthContext from "../../../../common/components/hooks/Auth";
import { Col, Row } from "react-bootstrap";
import EnableModal from "./EnableModal";
import DisableModal from "./DisableModal";
import NoResult from "../../../../common/components/NoResult";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "../../../../common/components/Typography";
import api from "../../../../services/api";
import { Paper, TablePagination } from "@material-ui/core";
import { CompanyAdmins } from "./Modal/types/interfaces";
import { ThemeContext } from "styled-components";
import { Stack, Chip, TableSortLabel } from "@mui/material";

const CompanyAdmin: React.FC = () => {
  const context = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [companiesAdmins, setCompaniesAdmins] = useState<CompanyAdmins[]>([]);
  const companyId = context.companySelect.selectedValue;
  const [search, setSearch] = useState<string | null>();
  const [filterTrigger, setFilterTrigger] = useState(false);
  const theme = useContext(ThemeContext);
  const handleChangePage = (__, newPage: number) => {
    setPage(newPage);
  };
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('firstName');
  const handleSortRequest = (property: string) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = (data) => {
    return data.sort((a, b) => {
      if (orderDirection === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  };

  const capitalizeName = (name: string) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  

  const [updatingId, setUpdatingId] = useState<string | null>(null);
  const [enablingId, setEnablingId] = useState<string | null>(null);
  const [disablingId, setDisablingId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const getCompaniesAdmin = async () => {
    setIsLoading(true);
    try {
      const params: any = {
        page: page + 1,
        companyId
      }
      const { data } = await api.get("/company-admin", { params });

      setCompaniesAdmins(data.data);
      setTotal(data.total)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const refreshTable = () => {
    setPage(0);
    setCompaniesAdmins([]);
    getCompaniesAdmin();
  }

  useEffect(() => {
    getCompaniesAdmin();
  }, [page, filterTrigger]);

  return (
    <>


      <ModalCompanyAdmin
        id={updatingId}
        companyId={companyId}
        refresh={refreshTable}
        isShown={!!updatingId || isCreating}
        hide={() => {
          setUpdatingId(null);
          setIsCreating(false);
        }}
      />
      <DisableModal
        id={disablingId}
        isShown={!!disablingId}
        hide={() => setDisablingId(null)}
        refresh={getCompaniesAdmin}
      />
      <EnableModal
        id={enablingId}
        isShown={!!enablingId}
        hide={() => setEnablingId(null)}
        refresh={getCompaniesAdmin}

      />
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "3%", marginRight: "2%", width: "100%" }}>
              <PageTitle
                title="Gestores"
                subtitle="Aqui você pode convidar, listar e filtrar outros gestores."
              />


              <Stack direction="row" spacing={1}>
                <Chip label="Novo Gestor" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => setIsCreating(true)} />

              </Stack>
            </div>
            <TableWrapper>

            <Table>
      <thead>
        <tr>
          <Theader
            style={{ color: theme.colors.title }}
            onClick={() => handleSortRequest('firstName')}
          >
            <TableSortLabel
              active={orderBy === 'firstName'}
              direction={orderBy === 'firstName' ? orderDirection : 'asc'}
            >
              Nome
            </TableSortLabel>
          </Theader>
          <Theader
            style={{ color: theme.colors.title }}
            onClick={() => handleSortRequest('email')}
          >
            <TableSortLabel
              active={orderBy === 'email'}
              direction={orderBy === 'email' ? orderDirection : 'asc'}
            >
              Email
            </TableSortLabel>
          </Theader>
          <Theader
            style={{ color: theme.colors.title }}
            onClick={() => handleSortRequest('department')}
          >
            <TableSortLabel
              active={orderBy === 'department'}
              direction={orderBy === 'department' ? orderDirection : 'asc'}
            >
              Departamento
            </TableSortLabel>
          </Theader>
          <Theader
            style={{ color: theme.colors.title }}
            onClick={() => handleSortRequest('role')}
          >
            <TableSortLabel
              active={orderBy === 'role'}
              direction={orderBy === 'role' ? orderDirection : 'asc'}
            >
              Cargo
            </TableSortLabel>
          </Theader>
          <Theader style={{ color: theme.colors.title }}>Status</Theader>
          <Theader style={{ color: theme.colors.title }}>Ações</Theader>
        </tr>
      </thead>
      <tbody>
        {sortedData(companiesAdmins).map((row) => (
          <Tr key={row.id}>
            <TH style={{ whiteSpace: row.firstName.length > 2 && row.lastName.length > 6 ? 'normal' : 'nowrap' }}>
            {capitalizeName(`${row.firstName} ${row.lastName}`)}
            </TH>
            <TH>{row.email || '-'}</TH>
            <TH>{row.department || '-'}</TH>
            <TH>{row.role || '-'}</TH>
            <TH>
              {row.canAccess ? (
                <Chip
                  label="Ativo"
                  style={{
                    backgroundColor: '#3FBE3F',
                    color: '#fff',
                    width: '50px',
                    fontSize: '10px',
                    height: '20px',
                    fontWeight: 'bold',
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#3FBE3F',
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                />
              ) : (
                <Chip
                  label="Inativo"
                  style={{
                    backgroundColor: '#ab0303',
                    color: '#fff',
                    width: '60px',
                    fontSize: '10px',
                    height: '20px',
                    fontWeight: 'bold',
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#ab0303',
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                />
              )}
            </TH>
            <TH>
              <Dropdown>
                <Toggle>
                  <Icon name="more-vertical" />
                </Toggle>
                <List>
                  <Item>
                    <Button onClick={() => setUpdatingId(row.id)}>Editar gestor</Button>
                  </Item>
                  {row.canAccess ? (
                    <Item onClick={() => setDisablingId(row.id)}>
                      <Button>Desabilitar acesso</Button>
                    </Item>
                  ) : (
                    <Item onClick={() => setEnablingId(row.id)}>
                      <Button>Habilitar acesso</Button>
                    </Item>
                  )}
                </List>
              </Dropdown>
            </TH>
          </Tr>
        ))}
      </tbody>
    </Table>

              <TablePagination
                count={total}
                page={page}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                component="div"
              />
            </TableWrapper>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>
    </>
  );
};

export default CompanyAdmin;
