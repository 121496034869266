import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  PRODUCT_ACTIVATE_MUTATION, PRODUCT_INACTIVATE_MUTATION
} from "./graphql";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import Typography from "../../../../../common/components/Typography";
import Input from "../../../../../common/components/Input";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import ReactInputMask from "react-input-mask";
import AuthContext from "../../../../../common/components/hooks/Auth";
import { activateProduct, activateProductVariables } from "./types/activateProduct";
import { inactivateProduct, inactivateProductVariables } from "./types/inactivateProduct";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import { Col, Row } from "react-bootstrap";

const ActivationModal: React.FC<{
  companyId: string;
  productId: string;
  isActive: boolean,
  isShown: boolean;
  hide: () => any;
}> = ({ isShown, hide, companyId, productId, isActive }) => {
  const authContext = useContext(AuthContext);

  const [activateProduct, activateProductMutation] = useMutation<
    activateProduct,
    activateProductVariables
  >(PRODUCT_ACTIVATE_MUTATION, {
    // update: (cache) => {
    //   cache.evict({
    //     id: `Company:${companyId}`,
    //     fieldName: "activeProducts",
    //   });
    // }
  });

  const [inactivateProduct, inactivateProductMutation] = useMutation<
    inactivateProduct,
    inactivateProductVariables
  >(PRODUCT_INACTIVATE_MUTATION, {
    // update: (cache) => {
    //   cache.evict({
    //     id: `Company:${companyId}`,
    //     fieldName: "activeProducts",
    //   });
    // }
  });

  const onActivate = async () => {
    if (activateProductMutation.loading) {
      return;
    }

    const result = await activateProduct({
      variables: { companyId, productId }
    });

    if (result.data?.activateProduct.__typename !== "Company") {
      alert("Não foi possível alterar a visibilidade do produto. Tente novamente...");
      return;
    }

    alert("Visibilidade do produto alterada com sucesso!");
    hide();
  };

  const onInctivate = async () => {
    if (inactivateProductMutation.loading) {
      return;
    }

    const result = await inactivateProduct({
      variables: { companyId, productId }
    });

    if (result.data?.inactivateProduct.__typename !== "Company") {
      alert("Não foi possível alterar a visibilidade do produto. Tente novamente...");
      return;
    }

    alert("Visibilidade do produto alterada com sucesso!");
    hide();
  };

  const disabled = activateProductMutation.loading || inactivateProductMutation.loading;

  const body = (
    <>
      <Row className="justify-content-md-center">
        <Col xs="auto">
          <Typography fontSize="20px" fontWeight="500" textAlign="center">
            Tem certeza que deseja {isActive ? 'esconder' : 'exibir'} este produto?
          </Typography>
          <br /><br />
        </Col>
      </Row>
      <Row>
        <Col>
          <SecondaryButton
            disabled={disabled}
            fontSize="15px"
            paddingLeft="70px"
            paddingRight="70px"
            borderRadius="30px"
            onClick={() => hide()}
          >
            Cancelar
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            disabled={disabled}
            fontSize="15px"
            paddingLeft="70px"
            paddingRight="70px"
            borderRadius="30px"
            onClick={() => isActive ? onInctivate() : onActivate()}
          >
            {isActive ? 'Esconder' : 'Exibir'}
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default ActivationModal;
