import React from "react";
import { ISelected } from "./interface";
import { SelectStyled } from "./styles";

const SelectCustom: React.FC<ISelected> = (props) => {
  return (
    <SelectStyled
      {...props}
      onChange={(event) => {
        props.onChange(event);
      }}
    >
      {props.options.map((option) => (
        <option
          value={option.value}
          selected={option.value === props.value}
          hidden={typeof option.hidden === "boolean" ? option.hidden : false}
          disabled={typeof option.disabled === "boolean" ? option.disabled : false}
        >
          {option.text}
        </option>
      ))}
    </SelectStyled>
  );
};

export default SelectCustom;
