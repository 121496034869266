import styled from 'styled-components';

export const StyledTabs = styled.div`
    display: flex;
    flex-direction: column;
`
export const List = styled.ul`
     display: flex;
     flex-direction: row;
     list-style: none;
     margin: 0;
     padding: 0;
     overflow: hidden;
    overflow-x: scroll;
    width: auto;
    ::-webkit-scrollbar {
    display: none;
    }
`