import {gql} from '@apollo/client';

export const FRAGMENT_THEME_GRAPHIC = gql`
    fragment ThemeCompanyGraphics on CompanyTheme {
        __typename
        primaryColorHex
        
    }
`

export const QUERY_THEME_GRAPHIC = gql`
    query QueryThemeGraphic($companyId: ID!){
        company(companyId: $companyId){
            __typename
            ... on Company {
                id
                theme {
                    ...ThemeCompanyGraphics
                }
            }
        }
    }
    ${FRAGMENT_THEME_GRAPHIC}
`

export const QUERY_WORKER_COUNTER = gql`
  query QueryWorkerCounter(
    $companyId: ID!
    $includeChildren: Boolean = false
    $startDate: String
    $endDate: String
  ) {
    company(companyId: $companyId) {
      __typename
      ... on Company {
        id
        name
        workersCount(
          includeChildren: $includeChildren
          includeGrandchildren: false
          startDate: $startDate
          endDate: $endDate
          canAccess: true
        )
        pendingWorkerInvitesCount: workerInvitesCount(
          includeChildren: $includeChildren
          includeGrandchildren: false
          startDate: $startDate
          endDate: $endDate
          accepted: false
        )
        acceptedWorkerInvitesCount: workerInvitesCount(
          includeChildren: $includeChildren
          includeGrandchildren: false
          startDate: $startDate
          endDate: $endDate
          accepted: true
        )
        workersCountExclusive: workersCount(
          includeChildren: false
          includeGrandchildren: false
          startDate: $startDate
          endDate: $endDate
          canAccess: true
        )
        pendingWorkerInvitesCountExclusive: workerInvitesCount(
          includeChildren: false
          includeGrandchildren: false
          startDate: $startDate
          endDate: $endDate
          accepted: false
        )
        acceptedWorkerInvitesCountExclusive: workerInvitesCount(
          includeChildren: false
          includeGrandchildren: false
          startDate: $startDate
          endDate: $endDate
          accepted: true
        )
        childrenCompanies {
          __typename
          id
          name
          workersCount(
            includeChildren: false
            includeGrandchildren: false
            startDate: $startDate
            endDate: $endDate
            canAccess: true
          )
          pendingWorkerInvitesCount: workerInvitesCount(
            includeChildren: false
            includeGrandchildren: false
            startDate: $startDate
            endDate: $endDate
            accepted: false
          )
          acceptedWorkerInvitesCount: workerInvitesCount(
            includeChildren: false
            includeGrandchildren: false
            startDate: $startDate
            endDate: $endDate
            accepted: true
          )
        }
      }
      ... on ErrorWithMessage {
        message
      }
    }
  }
`

export const QUERY_MOUNTLY_COUNT = gql`
   query QueryMountlyCount(
  $companyId: ID!
  $year: Int!
  $includeChildren: Boolean!
) {
  workerMonthlyCount(
    companyId: $companyId
    startYear: $year
    startMonth: 1
    endYear: $year
    endMonth: 12
    includeChildren: $includeChildren
    includeGrandchildren: false
  ) {
    count
    year
    month
  }
  workerInviteMonthlyCount(
    companyId: $companyId
    startYear: $year
    startMonth: 1
    endYear: $year
    endMonth: 12
    includeChildren: $includeChildren
    includeGrandchildren: false
  ) {
    count
    year
    month
    
  }
}
`