import styled from "styled-components";

interface Props {
    background?: string;
}

export const InputFileContainer = styled.div<Props>`
    input {
        display: none;
    }

    label {
        padding: 0.5rem 1rem;
        border-radius: 25px;
        border: 1px solid ${(props)=>props.background};
        background-color: ${(props)=>props.background};
        color: white;
        cursor: pointer;
    }
`;