import React, { useState } from "react";

import DangerButton from "../../../../../common/components/DangerButton";

import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import api from "../../../../../services/api";

interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh: () => void;
}

const DeleteModal: React.FC<Props> = ({ isShown, hide, id,refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteInvite = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      await api.delete(`/invites/${id}`);

      alert("Convite excluído com sucesso!");
      refresh();
      hide();
    } catch (e) {
      console.log(e);
      alert("Erro ao excluir o convite. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  }

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja excluir esse convite?
      </Typography>
      <br />
      <Typography fontSize="22px" fontWeight="300">
        Depois de confirmado, não poderá reverter essa ação.
      </Typography>
      <br />
      <DangerButton
        color="red"
        disabled={isLoading}
        onClick={deleteInvite}
      >
        Excluir convite
      </DangerButton>
      <SecondaryButton disabled={isLoading} onClick={hide}>Voltar</SecondaryButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default DeleteModal;
