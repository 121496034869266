import React from "react";
import { IDangerButton } from "./interface";
import { DangerButtonDefault } from "./styles";

const DangerButton: React.FC<IDangerButton> = ({children, ...props}) => {
  return (
    <div>
      <DangerButtonDefault
        {...props}
      >
        {children}
      </DangerButtonDefault>
    </div>
  );
};

export default DangerButton;
