import React from 'react';
import { Wrapper, WrapperBtn, Button } from './styles';
import Icon from '../Icon'
import { IPagination } from './interface';

const Pagination: React.FC<IPagination> = ({
    disableNext,
    disablePrev,
    from,
    onNextClick,
    onPrevClick,
    to,
    total
}) => {
    return(
        <Wrapper>
            <span>Exibindo de {from} a {to} (total: {total || "?"})</span>
            <WrapperBtn>
                <Button onClick={onPrevClick} disabled={disablePrev}><Icon name="arrow-left" /></Button>
                <Button onClick={onNextClick} disabled={disableNext}><Icon name="arrow-right" /></Button>
            </WrapperBtn>
        </Wrapper>
    )
};

export default Pagination;