import React, { useState } from "react";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import api from "../../../../../services/api";

interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh: () => void;
}

const EnableModal: React.FC<Props> = ({ isShown, hide, id,refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await api.put(`/worker/${id}/enable`);
      alert("Acesso habilitado com sucesso!");
      refresh();
      hide();
    } catch (e) {
      alert("Erro ao habilitar acesso do colaborador. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        <Typography fontSize="22px" fontWeight="500">
          Tem certeza que deseja habilitar o acesso desse colaborador?
        </Typography>
        <br />
        <PrimaryButton
          color="red"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Habilitar acesso
        </PrimaryButton>
        <SecondaryButton disabled={isLoading} onClick={hide}>Voltar</SecondaryButton>
      </ModalDefault>
    </>
  );
};

export default EnableModal;
