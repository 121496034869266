import { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import AuthContext from "../common/components/hooks/Auth";

const GuestRoute: React.FC<RouteProps> = (props) => {
  const context = useContext(AuthContext);

  if (context.authenticated) {
    return <Redirect to="/home" />;
  }

  return <Route {...props} />;
};

export default GuestRoute;
