import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography"
import { useHistory, useParams } from "react-router-dom";
import { GridWrapper, Images, SectionTitle, SectionWrapper, Wrapper, WrapperBack, } from "./styles";
import api from "../../../../services/api";
import { Card as BenefitCard } from "../../../../common/components/Card";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { BeneficConfig, Category, SubCategory, Benefit } from "./interfaces";
import { Avatar, Button, Card, Chip, FormControlLabel, Paper, Switch, styled } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import PageSubTitle from "../../../../common/components/PagSubTitle";
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function BenefitsPage() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [beneficios, setBeneficios] = useState<BeneficConfig[]>([]);
  const [benefitToBuy, setBenefitToBuy] = useState<Benefit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const [error, setError] = useState<string | null>(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const theme = useContext(ThemeContext);
  const [showAll, setShowAll] = useState(false);
  const beneficiosToShow = showAll ? beneficios : beneficios.slice(0, 8);

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>(companyId);

  const handleSortRequest = (property: string) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = (data) => {
    return data.sort((a, b) => {
      const titleA = a.Benefit.title.toUpperCase(); // Ignora a diferença entre maiúsculas e minúsculas
      const titleB = b.Benefit.title.toUpperCase();
  
      if (orderDirection === 'asc') {
        return titleA < titleB ? -1 : 1;
      } else {
        return titleA > titleB ? -1 : 1;
      }
    });
  };


  const handleBuyBenefit = async (benefitId: string) => {
    try {
      await api.post(`/product/add-to-company`, {
        benefitId,
        companyId: selectedCompany.id
      });

      alert("Beneficio adicionado a empresa!");
      setBeneficios([]);
      setBenefitToBuy([]);

      getProductsToBuy();
      getBeneficios();
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os produtos da empresa.");
    } finally {
      setLoading(false);
    }
  }

  const getProductsToBuy = async () => {
    try {
      const { data } = await api.get(`/product/${selectedCompany.id}/store`);

      setBenefitToBuy(data);
    } catch (e) {
      console.log(e)
    }
  }


  const toggleActive = async (configId: string) => {
    setIsProcessingRequest(true);

    try {
      const { data } = await api.put(`/benefit/${configId}/toggle-active`);

      setBeneficios(prev => prev.map((b) => {
        if (b.id !== configId) {
          return b;
        }

        return data.data;
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsProcessingRequest(false);
    }
  }



  const getBeneficios = async () => {
    try {
      const params = {
        companyId
      }

      const { data } = await api.get("/benefit-config", { params });

      setBeneficios(data.data);
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");

    } finally {
      setLoading(false);
    }
  }



  const handleRemoveBenefit = async (id: string) => {
    try {
      const { data } = await api.delete(`/product/remove-to-company/${id}`,);
      setBeneficios(data.data.data);

    } catch (e) {

      alert("Beneficio Removido a empresa!");
      getBeneficios();
      getProductsToBuy();

    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getBeneficios();
    getProductsToBuy();
  }, [])
  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Benefícios"
          subtitle="Aqui você pode gerenciar os benefícios da sua empresa."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          <div style={{ display: "flex", justifyContent: "end", width: "92%", marginBottom: "1%", marginTop: "10px" }}>
            <Stack direction="row" spacing={1}>
              <Chip label="Novo Beneficio" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"

              }}
                onClick={() => history.push(`/novos-beneficios`)} />
              <Chip label="Novo Banner" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"

              }}
                onClick={() => history.push(`/novos-banners`)} />
              <Chip label="Novo Parceiro"
                style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",

                }}
                onClick={() => history.push('/novos-parceiros')}
              />
            </Stack>
          </div>
          <GridWrapper>
            {sortedData(beneficiosToShow).map(b => (
              <Wrapper key={b.id}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  {b.Benefit.title !== "People Club" && (
                    <IconButton onClick={() => handleRemoveBenefit(b.id)}>
                      <CancelIcon
                        style={{
                          color: "#ac3021",
                          width: "20px",
                          height: "20px"
                        }}
                      />
                    </IconButton>
                  )}
                  <div style={{ marginLeft: 'auto' }}>
                    <IconButton onClick={() => history.push(`/beneficio/${b.Benefit.code}`)}>
                      <InfoIcon
                        style={{
                          color: theme.colors.primary,
                          width: "20px",
                          height: "20px"
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    style={{ width: "100px", height: "100px", border: "1px solid #e0e0e0" }}
                    src={b.Benefit.logoUrl}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
                  <h1
                    style={{
                      fontSize: '16px',
                      fontFamily: "inherit",
                      fontWeight: 700,
                      lineHeight: '24px',
                      color: theme.colors.primary
                    }}
                  >{b.Benefit.title}</h1>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "1%" }}>
                  <FormControlLabel
                    style={{ fontFamily: "inherit", fontSize: '12px', color: '#757575', whiteSpace: "nowrap" }}
                    onChange={() => toggleActive(b.id)}
                    control={
                      <Switch
                        checked={b.visibleToWorkers}
                        style={{ color: theme.colors.primary, }}
                      />
                    }
                    label="Disponivel Para Usuários"
                  />
                </div>
              </Wrapper>
            ))}
          </GridWrapper>

          {beneficios.length > 8 && (
           <div style={{ display: "flex", justifyContent: "center", width: "100%", zIndex:1001, marginTop:"1%"}}>
              <IconButton 
              style={{ backgroundColor: theme.colors.primary, }}
              onClick={() => setShowAll(!showAll)}>
                {showAll ? <ArrowDropUpIcon style={{ color: theme.colors.primaryContrast}}/> : <ArrowDropDownIcon style={{ color: theme.colors.primaryContrast}}/>}
              </IconButton>
            </div>
          )}
        </WrapperBack>

      </GridWrapper>
    </SectionWrapper >
  );
}

export default BenefitsPage;