import React from "react";
import {
  HashRouter as Router,
  Switch,
  RouteProps,
  Redirect,
  matchPath,
  useLocation,
} from "react-router-dom";
import { Login, Sso } from "../modules/Login/screens";
import ForgetPassword from "../modules/Login/screens/forgetPassword";
import Layout from "../modules/Login/screens/layout";
import Store from "../modules/Sections/screens/Store/index";
import Profile from "../modules/Sections/screens/Profile";
import CustomLabel from "../modules/Sections/screens/CustomLabel";
import Companies from "../modules/Sections/screens/Companies";
import GuestRoute from "./guest";
import PrivateRoute from "./private";
import Worker from "../modules/Sections/screens/Colab";
import Home from "../modules/Sections/screens/Home";
import CompanyAdmin from "../modules/Sections/screens/CompanyAdmin";
import ChangePassword from "../modules/Login/screens/ChangePassword";
import Benefit from "../modules/Sections/screens/Benefit";
import BenefitsPage from "../modules/Sections/screens/Benefits";
import BenefitDetailsPage from "../modules/Sections/screens/BenefitDetails";
import Invite from "../modules/Sections/screens/Invite";
import { useContext } from "react";
import AuthContext from "../common/components/hooks/Auth";
import Loading from "../common/components/Loading";
import Screen from "../modules/Sections/screens/ScreenDefault";
import Dashboard from "../modules/Sections/screens/Dashboard";
import Gestao from "../modules/Sections/screens/Gestao";
import Notifications from "../modules/Sections/screens/Notifications";
import WorkSheets from "../modules/Sections/screens/WorkSheets";
import BenefitContext from "../common/components/hooks/Benefit";
import BenefitRequestsPage from "../modules/Sections/screens/BenefitRequests";
import NewBenefits from "../modules/Sections/screens/NewBenefits";
import NewPartner from "../modules/Sections/screens/NewPartner";
import EditarBeneficio from "../modules/Sections/screens/EditarBeneficio";
import BenefitsAdquirir from "../modules/Sections/screens/BenefitsAdquirir";
import EditarBanner from "../modules/Sections/screens/EditarBanner";
import NewBenefitsBanner from "../modules/Sections/screens/NewBenefitsBanner";
import Terms from "../modules/Sections/screens/Terms";
import Chat from "../modules/Sections/screens/Chat";
import Pacotes from "../modules/Sections/screens/Pacotes";
import NewPack from "../modules/Sections/screens/NewPack";
import CadastroEmpresa from "../modules/Sections/screens/CadastroEmpresa";
import PacotesAtivos from "../modules/Sections/screens/PacotesAtivos";
import InvitePremium from "../modules/Sections/screens/InvitePremium";
import PacotesPremium from "../modules/Sections/screens/PacotesPremium";
import PacotesPremiumAtivos from "../modules/Sections/screens/PacotesPremiumAtivos";
import NewPackPremium from "../modules/Sections/screens/NewPackPremium";
import NewProductShop from "../modules/Sections/screens/NewProductShop";
import Lojinha from "../modules/Sections/screens/Lojinha";
import DisponibilizarProduto from "../modules/Sections/screens/DisponibilizarProduto";
import EditarPack from "../modules/Sections/screens/EditarPack";
import EditarPackPremium from "../modules/Sections/screens/EditarPackPremium";
import EditarProductShop from "../modules/Sections/screens/EditarProductShop";
import Historico from "../modules/Sections/screens/Historico";
import Dependentes from "../modules/Sections/screens/Dependentes";
import Fornecedores from "../modules/Sections/screens/Fornecedores";
import Finance from "../modules/Sections/screens/Finance";



interface IRoutes {
  path?: string;
  exact?: boolean;
}

const guestRoutes: RouteProps[] = [
  { path: "/sso", exact: true, render: () => <Sso /> },
  { path: "/login", exact: true, render: () => <Login /> },
  { path: "/cadastro-empresa", exact: true, render: () => <CadastroEmpresa /> },
  {
    path: "/password-reset-request",
    exact: true,
    render: () => <ForgetPassword />,
  },
  { path: "/password-reset", exact: true, render: () => <ChangePassword /> },
  { path: "*", render: () => <Redirect to="/login" /> },
];

const privateRoutesWithShell: RouteProps[] = [
  { path: "/", exact: true, render: () => <Dashboard /> },
  { path: "/home", exact: true, render: () => <Dashboard /> },
  { path: "/layout", exact: true, render: () => <Layout /> },
  { path: "/beneficios/adquirir", exact: true, render: () => <Store /> },
  { path: "/usuarios", exact: true, render: () => <Worker /> },
  { path: "/usario/:id/dependentes", exact: true, render: () => <Dependentes /> },
  { path: "/historico", exact: true, render: () => <Historico /> },
  { path: "/empresas", exact: true, render: () => <Companies /> },
  { path: "/perfil", exact: true, render: () => <Profile /> },
  { path: "/custom-label", exact: true, render: () => <CustomLabel /> },
  { path: "/gestores", exact: true, render: () => <CompanyAdmin /> },
  { path: "/finance", exact: true, render: () => <Finance /> },
  { path: "/velhos-beneficios", exact: true, render: () => <Benefit /> },
  { path: "/beneficios", exact: true, render: () => <BenefitsPage /> },
  { path: "/fornecedor", exact: true, render: () => <Fornecedores /> },
  { path: "/disponivel/lojinha", exact: true, render: () => <Lojinha /> },
  { path: "/pacotes", exact: true, render: () => <Pacotes /> },
  { path: "/pacotes/premium", exact: true, render: () => <PacotesPremium /> },
  { path: "/pacotes/ativos", exact: true, render: () => <PacotesAtivos /> },
  { path: "/pacotes/premium/ativos", exact: true, render: () => <PacotesPremiumAtivos /> },
  { path: "/feed", exact: true, render: () => <Chat/> },
  { path: "/termos", exact: true, render: () => <Terms /> },
  { path: "/novos-beneficios", exact: true, render: () => <NewBenefits /> },
  { path: "/novo/produto/shop", exact: true, render: () => <NewProductShop /> },
  { path: "/editar/produto/shop/:id", exact: true, render: () => <EditarProductShop /> },
  { path: "/novos-banners", exact: true, render: () => <NewBenefitsBanner /> },
  { path: '/store', exact: true, render: () => <BenefitsAdquirir /> },
  { path: '/disponibilizar/produto', exact: true, render: () => <DisponibilizarProduto /> },
  { path: "/editar-beneficio/:id", exact: true, render: () => <EditarBeneficio /> },
  { path: "/editar-banner/:id", exact: true, render: () => <EditarBanner /> },
  { path: "/novos-parceiros", exact: true, render: () => <NewPartner /> },
  { path: "/novo-pacote", exact: true, render: () => <NewPack /> },
  { path: "/editar-pacote/:id", exact: true, render: () => <EditarPack /> },
  { path: "/novo-pacote/premium", exact: true, render: () => <NewPackPremium /> },
  { path: "/editar-pacote/premium/:id", exact: true, render: () => <EditarPackPremium /> },
  { path: "/beneficio/:benefitCode", exact: true, render: () => <BenefitDetailsPage /> },
  { path: '/cotacoes', exact: true, render: () => <BenefitRequestsPage /> },
  { path: "/convites", exact: true, render: () => <Invite /> },
  { path: "/convites/premium", exact: true, render: () => <InvitePremium /> },
  { path: "/screen", exact: true, render: () => <Screen /> },
  { path: "/dashboard", exact: true, render: () => <Dashboard /> },
  { path: "/notificacoes", exact: true, render: () => <Notifications /> },
  { path: "/planilha", exact: true, render: () => <WorkSheets /> },
];

const privateRoutesNoShell: RouteProps[] = [
  { path: "/gestao", exact: true, render: () => <Gestao /> },
];

const Routes: React.FC<IRoutes> = () => {
  const context = useContext(AuthContext);
  const location = useLocation();

  if (context.loading) {
    return <Loading />;
  }

  const noShell = privateRoutesNoShell.find((route) =>
    matchPath(location.pathname, route)
  );

  return (
    <Router>
      <Switch>
        {context.authenticated ? (
          <BenefitContext>
            {noShell ? (
              privateRoutesNoShell.map((route) => (
                <PrivateRoute {...route} key={route.path?.toString()} />
              ))
            ) : (
              <Layout>
                {privateRoutesWithShell.map((route) => (
                  <PrivateRoute {...route} key={route.path?.toString()} />
                ))}
              </Layout>
            )}
          </BenefitContext>
        ) : (
          guestRoutes.map((route) => (
            <GuestRoute {...route} key={route.path?.toString()} />
          ))
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
