import React, { useContext } from 'react';
import { INavBar } from './interface';
import { NavbarDefault, Toggle } from './styles';
import Icon from '../Icon/index';
import { ThemeContext } from 'styled-components';

const Navbar: React.FC<INavBar> = (props) => {
    const context = useContext(ThemeContext);

    return(
        
         <NavbarDefault bgColor={props.bgColor} open={props.open} isMobile={props.isMobile}>
            <div>
                 {props.setOpen ? <Toggle open={props.open} onClick={() => props.setOpen ? props?.setOpen(!props.open) : null}><Icon name="arrow" color={context.colors.primary}/></Toggle> : null}
            </div>
            
            <div>{props.children}</div>
            
        </NavbarDefault> 
        
    )
}

export default Navbar;