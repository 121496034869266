import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";
import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";

import { BenefitCategory, BenefitSubcategory, Partner } from "./interfacess";
import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import AvatarUpload from "../NewBenefits/AvatarUpload";
import { Beneficios } from "./interfaces";
import { ThemeContext } from "styled-components";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";


interface Errors {
  [field: string]: string;
}

const EditarBeneficio: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [parceiros, setParceiros] = useState<Partner[]>([]);
  const [categoria, setCategoria] = useState<BenefitCategory[]>([]);
  const [subCategoria, setSubCategoria] = useState<BenefitSubcategory[]>([]);
  const [subtitle, setSubtitle] = useState("");
  const [checkLabel, setCheckLabel] = useState<string[]>([]);
  const [shortDescription, setShortDescription] = useState("");
  const [code, setCode] = useState("");
  const [locationLabel, setLocationLabel] = useState("");
  const [title, setTitle] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [htmlDescriptionForWorker, setHtmlDescriptionForWorker] = useState("");
  const [htmlDescriptionForCompanyAdmin, setHtmlDescriptionForCompanyAdmin] = useState("");
  const [benefitLogo, setBenefitLogo] = useState<File>(null);
  const [selectedCategoria, setSelectedCategoria] = useState("");
  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});
  const [link, setLink] = useState(false);
  const [actions, setActions] = useState([]);
  const theme = useContext(ThemeContext);
  const { id } = useParams<{ id: string }>()
  

  const getBenefitInfos = async () => {
    try {
      const { data } = await api.get(`/benefit/info/${id}`,);
      console.log("beneeeeee", data)
      setSubtitle(data.data.subtitle || "");
      setShortDescription(data.data.shortDescription || "");
      setCheckLabel(data.data.checkLabel || []);
      setLogoUrl(data.data.logoUrl ?? "");
      setCode(data.data.code || "");
      setLocationLabel(data.data.locationLabel || "");
      setTitle(data.data.title || "");
      setPartnerId(data.data.partnerId || "");
      setHtmlDescriptionForWorker(data.data.htmlDescriptionForWorker || "");
      setHtmlDescriptionForCompanyAdmin(data.data.htmlDescriptionForCompanyAdmin || "");
      const uniqueActions = data.data.BenefitHasAction.filter(
        (action, index, self) => index === self.findIndex((a) => a.actionId === action.actionId)
      );
      setActions(uniqueActions || []);

    } catch (e) {

      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");

    } finally {
      setLoading(false);
    }
  }

  function saveCheckLabel() {
    if (inputValue.trim()) {
      setCheckLabel(prevState => [...prevState, inputValue]);
      setInputValue('');
    }
  }

  function removeCheckLabel(index: number) {
    setCheckLabel(prev => {
      const newLabels = [...prev];
      newLabels.splice(index, 1);
      return newLabels;
    });
  }
  const printFormData = (formData) => {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
  }
  const postBenefit = async () => {

    try {
      const formData = new FormData();

      formData.append("title", title);
      formData.append("subtitle", subtitle);
      formData.append("locationLabel", locationLabel);
      formData.append("shortDescription", shortDescription);
      formData.append("code", code);
      formData.append("checkLabel", checkLabel.join(";"));
      formData.append("htmlDescriptionForWorker", htmlDescriptionForWorker);
      formData.append("htmlDescriptionForCompanyAdmin", htmlDescriptionForCompanyAdmin);
      formData.append("actions", JSON.stringify(actions));
      if (benefitLogo) {
        formData.append("file", benefitLogo);
      }
      printFormData(formData);

      await api.put(`/benefit/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: {
          actions: JSON.stringify(actions)
        }

      });
      alert("Beneficio Atualizado com sucesso!");
      history.push("/beneficios");

    } catch (e) {
      console.log(e);
    }
  }
  const toggleIsLink = () => {
    setLink(prevState => !prevState);
  }
  useEffect(() => {
    getBenefitInfos();

  }, [id]);

  useEffect(() => {
    console.log(actions)
  }, [actions]);

  const handleLinkTextChange = (index, value) => {
    setActions((prevActions) => {
      // Crie uma cópia dos actions anteriores
      const updatedActions = [...prevActions];

      // Verifica se Action existe e altera o valor dentro dela
      if (updatedActions[index] && updatedActions[index].Action) {
        updatedActions[index] = {
          ...updatedActions[index], // Mantém o resto do objeto
          Action: { ...updatedActions[index].Action, value } // Atualiza o campo "value"
        };
      }

      return updatedActions;
    });
  };

  const handleTitleChange = (index, value) => {
    setActions((prevActions) => {
      // Crie uma cópia dos actions anteriores
      const updatedActions = [...prevActions];

      // Verifica se Action existe e altera o título dentro dela
      if (updatedActions[index] && updatedActions[index].Action) {
        updatedActions[index] = {
          ...updatedActions[index], // Mantém o resto do objeto
          Action: { ...updatedActions[index].Action, title: value } // Atualiza o campo "title"
        };
      }

      return updatedActions;
    });
  };


  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>
        <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
          <PageTitle
            title="Editar Benefícios"
            subtitle="Aqui você pode editar os benefícios da sua empresa."
          />
        </div>
        <GridWrapper>
          <WrapperBack>
            <div style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: "2%", marginBottom: "1%", marginRight: "2%" }}>
              <Stack direction="row" spacing={1}>
                <Chip label="Novo Beneficio" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push(`/novos-beneficios`)} />
                <Chip label="Novo Banner" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push(`/novos-banners`)} />
                <Chip label="Novo Parceiro"
                  style={{
                    backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",

                  }}
                  onClick={() => history.push('/novos-parceiros')}
                />
              </Stack>
            </div>
            <Grid
              container
              spacing={2}
              style={{ padding: "1rem" }}
            >
              <Grid item xs={12}>
                <AvatarUpload
                  onChangeImage={setBenefitLogo}
                  src={(logoUrl || "")}
                />
              </Grid>


              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="title"
                  name="title"
                  fullWidth
                  autoFocus
                  label="Título"
                  autoComplete="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  size="small"
                />


                {formErrors.title && (
                  <S.ErrorMessage>
                    {formErrors.title}
                  </S.ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="subtitle"
                  name="subtitle"
                  fullWidth
                  autoFocus
                  label="Subtítulo"
                  autoComplete="subtitle"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                  className="inputdados"
                  size="small"
                />

                {formErrors.subtitle && (
                  <S.ErrorMessage>
                    {formErrors.subtitle}
                  </S.ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="locationLabel"
                  name="locationLabel"
                  fullWidth
                  autoFocus
                  label="Localização"
                  autoComplete="locationLabel"
                  value={locationLabel}
                  onChange={(e) => setLocationLabel(e.target.value)}
                  className="inputdados"
                  size="small"
                />

                {formErrors.locationLabel && (
                  <S.ErrorMessage>
                    {formErrors.locationLabel}
                  </S.ErrorMessage>
                )}

              </Grid>
              <Grid item xs={12} md={6}>

                <TextField
                  variant="outlined"
                  id="shortDescription"
                  fullWidth
                  name="shortDescription"
                  multiline
                  autoFocus
                  label="Descrição"
                  autoComplete="municipio"
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                  className="inputdados"
                  size="small"
                />

                {formErrors.shortDescription && (
                  <S.ErrorMessage>
                    {formErrors.shortDescription}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} md={6}>

                <TextField
                  variant="outlined"
                  id="code"
                  fullWidth
                  name="code"
                  autoFocus
                  label="Código do Beneficio"
                  autoComplete="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="inputdados"
                  size="small"
                />

                {formErrors.code && (
                  <S.ErrorMessage>
                    {formErrors.code}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} sm={12}>

                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="checkLabel"
                  id="checkLabel"
                  label="Tags"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") saveCheckLabel();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={saveCheckLabel}>
                          <AddIcon style={{ width: "20px" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {!!formErrors["checkLabels"] && (
                  <span style={{ color: "red", fontSize: 15 }}>{`* ${formErrors["checkLabels"]}`}</span>
                )}

                {checkLabel.length <= 0 ? (
                  <span style={{ color: "grey", fontSize: 12, whiteSpace: "nowrap" }}>
                    * Digite o checkLabel e pressione Enter para incluí-lo, ou pressione o botão "+"
                  </span>
                ) : (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {checkLabel.map((label, index) => (
                      <Chip
                        key={index}
                        style={{ margin: 5 }}
                        label={label}
                        // color={wrongLabels.includes(label) ? "error" : "default"}
                        onDelete={() => removeCheckLabel(index)}
                      />
                    ))}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={12}>

                <TextField
                  variant="outlined"
                  id="htmlDescriptionForWorker"
                  name="htmlDescriptionForWorker"
                  fullWidth
                  multiline
                  rows={5}
                  label="Mensagem para os  Usuários"
                  autoComplete="htmlDescriptionForWorker"
                  value={htmlDescriptionForWorker}
                  onChange={(e) => setHtmlDescriptionForWorker(e.target.value)}
                  className="inputdados"
                  size="small"
                />

                {formErrors.htmlDescriptionForWorker && (
                  <S.ErrorMessage>
                    {formErrors.htmlDescriptionForWorker}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} md={12}>

                <TextField
                  variant="outlined"
                  id="htmlDescriptionForWorker"
                  name="htmlDescriptionForWorker"
                  fullWidth
                  multiline
                  rows={5}
                  label="Mensagem para os Gestores"
                  autoComplete="htmlDescriptionForWorker"
                  value={htmlDescriptionForCompanyAdmin}
                  onChange={(e) => setHtmlDescriptionForCompanyAdmin(e.target.value)}
                  className="inputdados"
                  size="small"
                />

                {formErrors.htmlDescriptionForCompanyAdmin && (
                  <S.ErrorMessage>
                    {formErrors.htmlDescriptionForCompanyAdmin}
                  </S.ErrorMessage>
                )}

                {actions?.map((benefitAction, index) => (
                  <div style={{ marginTop: "15px" }} key={index}>

                    <Typography fontSize={"14"} fontWeight={"1"}>
                      Acção {index+1}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          variant="outlined"
                          id={`linkText-${benefitAction.id}`}
                          fullWidth
                          name="linkText"
                          autoFocus
                          autoComplete="linkText"
                          onChange={(e) => handleTitleChange(index, e.target.value)}
                          value={benefitAction.Action?.title}
                          className="inputdados"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} key={benefitAction.id} style={{ marginTop: "8px" }}>
                        <div className="d-flex w-full">
                          <>
                            <TextField
                              variant="outlined"
                              id={`linkText-${benefitAction.id}`}
                              fullWidth
                              name="linkText"
                              autoFocus
                              placeholder="Link"
                              autoComplete="linkText"
                              onChange={(e) => handleLinkTextChange(index, e.target.value)}
                              value={benefitAction.Action?.value}
                              className="inputdados"
                              size="small"
                            />

                            {formErrors.linkText && (
                              <S.ErrorMessage>
                                {formErrors.linkText}
                              </S.ErrorMessage>
                            )}
                          </>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Grid>



            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
              <Chip label="Editar Beneficio" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"

              }}
                onClick={postBenefit} />
            </div>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper >

    </>
  );

  return (
    <>
      {content}
    </>
  );
};

export default EditarBeneficio;
function setError(arg0: string) {
  throw new Error("Function not implemented.");
}

