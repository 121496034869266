import React, { memo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Background, Image } from "../../../../common/components";
import { TitleBox, InputWrapper } from "./styles";
import { Panel } from "../../../../common/components/Panel";
import Helmet from 'react-helmet'
import Input from "../../../../common/components/Input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import logo from "../../../../assets/img/logo.png";
import Typography from "../../../../common/components/Typography";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import { IFormInputs } from "./interface";
import { useMutation } from "@apollo/client";
import { CompanyAdminRequestPassword, CompanyAdminRequestPasswordVariables } from "./types/CompanyAdminRequestPassword";
import { MUTATION_REQUEST_PASSWORD } from "./graphql";
import { useHistory, useLocation } from "react-router-dom";
import SecondaryButton from "../../../../common/components/SecondaryButton";
import api from "../../../../services/api";

const schema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email(),
});

const ForgetPassword: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const [isProcessing, setIsProcessing] = useState(false)

  const { push } = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmitRequestPassword = async (data: any) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);


    try {
      await api.post("/auth/request-reset-password", {
        email: data.email,
        user: "workerAdmin"
      });

      alert("Solicitação enviada com sucesso. Cheque sua caixa de e-mail!");
      push("/login");

    } catch (e) {
      console.log(e);
      alert("Nenhum gestor foi encontrado com esse e-mail. Tente novamente!")

    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Background>
      <Container style={{ height: "100%", paddingTop: 50, paddingBottom: 30 }}>
        <Row>
          <Col>
            <Image url={logo} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <TitleBox>
              <Typography fontSize="40px" fontColor="#ffffff" fontWeight="500">
                UM SISTEMA DE RH
              </Typography>

              <Typography fontSize="32px" fontColor="#ffffff" fontWeight="300">
                COMPLETO PARA VOCÊ
              </Typography>
            </TitleBox>
          </Col>
          <Col xs={12} lg={6}>
            <Panel
              borderRadius="50px"
              paddingBottom="72px"
              paddingTop="72px"
              paddingLeft="80px"
              paddingRight="80px"
            >
              <Container style={{ marginBottom: "32px" }}>
                <Typography
                  fontSize="32px"
                  fontColor="#000000"
                  fontWeight="300"
                >
                  Digite seu email
                </Typography>
              </Container>
              <form onSubmit={handleSubmit(onSubmitRequestPassword)}>
                <Container style={{ marginBottom: "30px" }}>
                  <div style={{ display: "flex" }}>
                    <InputWrapper>
                      <Controller
                        control={control}
                        name="email"
                        defaultValue={email || ""}
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled={isProcessing ? true : false}
                            placeholder="Email"
                            register={register("email")}
                            required
                          ></Input>
                        )}
                      />
                    </InputWrapper>
                  </div>
                  <Typography
                    fontColor="#ff0000"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    {errors.email?.message}
                  </Typography>
                </Container>
                <Container style={{ marginBottom: "120px" }}></Container>
                <Container>
                  <SecondaryButton
                    onClick={() => push(`/login?email=${encodeURIComponent(getValues().email)}`)}
                    text="Voltar"
                    type="button"
                  />
                  <PrimaryButton
                    onClick={() => { }}
                    disabled={isProcessing ? true : false}
                    text="Alterar Senha"
                    marginTop="5px"
                  ></PrimaryButton>
                </Container>
              </form>
            </Panel>
          </Col>
        </Row>
      </Container>
    </Background>
  );
};
export default memo(ForgetPassword);
