import { useMediaQuery } from "@react-hook/media-query";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { WrapperCarousel } from "./styles";
import { ICarousel } from "./types";

const CarouselComponent = (props: ICarousel) => {
  return (
    <WrapperCarousel {...props}>
      <></>
      <Carousel
        autoPlay={props.autoPlay}
        interval={6000}
        emulateTouch={true}
        infiniteLoop={props.infiniteLoop}
        transitionTime={3000}
        showArrows={true} // Habilita as setas de navegação
        showStatus={false}
        showThumbs={false}
        showIndicators={true} // Habilita os indicadores (bolinhas)
        centerMode={props.centerMode}
        centerSlidePercentage={100}
      >
        {props.children}
      </Carousel>
    </WrapperCarousel>
  );
};

export default CarouselComponent;
