
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { useHistory, useParams } from "react-router-dom";
import { ButtonBuy, Categoryaa, Description, Details, GridWrapper, Images, Price, SectionTitle, SectionWrapper, Title, Wrapper, WrapperBack, WrapperImage, } from "./styles";
import api from "../../../../services/api";
import { Chip, IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


interface PacksCompanyConfig {
  id: string,
  produtoshopid: string,
  companyId: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
  shops: {
    id: string,
    produto: string;
    produtoimage: string;
    sku: string;
    preco: number;
    estoque: string;
    pagamento: string;
    categoria: string;
    shortdescription: string;
    companyId: string;
  }
}

function Lojinha() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [beneficios, setBeneficios] = useState<PacksCompanyConfig[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useContext(ThemeContext);
  const [showAll, setShowAll] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getBeneficios = async () => {
    try {
      const params = {
        companyId
      }

      const { data } = await api.get("/shops-config", { params });

      setBeneficios(data.data);
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");

    } finally {
      setLoading(false);
    }
  }

  const DeletPacote = async (id: string) => {
    try {
      const { data } = await api.delete(`/delete/shop/colab/${id}`,);
      setBeneficios(data.data.data);
    } catch (e) {
      getBeneficios();
      alert("Produto Deletado com sucesso!");
      history.push("/disponibilizar/produto");


    } finally {
      setLoading(false);
    }
  }

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };


  useEffect(() => {
    getBeneficios();
  }, [])


  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Shopping"
          subtitle="Aqui você pode gerenciar os produtos que estão na sua loja e disponiveis para a compra."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          <div style={{ display: "flex", justifyContent: "end", width: "92%", marginBottom: "1%", marginTop: "10px" }}>
            <Stack direction="row" spacing={1}>
              <Chip label="Criar Produto" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"

              }}
                onClick={() => history.push(`/novo/produto/shop`)} />
            </Stack>
          </div>
          <GridWrapper>
            {beneficios.map(b => (
              <Wrapper key={b.id}>
                 <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <IconButton onClick={() => DeletPacote(b.id)}>
                  <CancelIcon
                    style={{
                      color: "#ac3021",
                      width: "20px",
                      height: "20px"
                    }} />
                </IconButton><div style={{ marginLeft: 'auto' }}>
                </div>
                </div>
                <WrapperImage>
                  <Images src={b.shops.produtoimage || 'default-image.png'} alt={b.shops.produto} />
                </WrapperImage>
                <Title>{b.shops.produto}</Title>
                <Price>{formatCurrency(b.shops.preco)}</Price>
                <Categoryaa>Categoria: {b.shops.categoria}</Categoryaa>
                <Details>Estoque: {b.shops.estoque}</Details>
                <Description>{b.shops.shortdescription}</Description>
                <ButtonBuy >Disponivel</ButtonBuy>
              </Wrapper>
            ))}
          </GridWrapper>
        </WrapperBack>

      </GridWrapper>
    </SectionWrapper >
  );
}

export default Lojinha;