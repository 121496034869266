import React from "react";
import { IChecked } from "./interface";
import { Check } from "./styles";

const Checkbox: React.FC<IChecked> = (props) => {
  return (
    <div>
      <Check id={props.id} name={props.name} onChange={props.onChange} checked={props.checked} type="checkbox" />
    </div>
  );
};

export default Checkbox;
