import { createGlobalStyle } from "styled-components";

type CustomTheme = {
    colors: {
        primary: string,
        primaryContrast: string,
        secondary: string,
        secondaryContrast: string,
        title: string;
        text: string,
        urlLogo: string,
    },
    name: string;
};

declare module 'styled-components' {
    export interface DefaultTheme extends CustomTheme {}
}

export default createGlobalStyle`
 



`