import React, { useState, ChangeEvent, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar } from '@mui/material';
import "./benefitBannerStyle.css";

interface BenefitBannerUploadProps {
  src: string;
  onChangeImage: (file: File) => void;
}

const BenefitBannerUpload: React.FC<BenefitBannerUploadProps> = ({ src, onChangeImage }) => {
  const [previewImage, setPreviewImage] = useState(src);

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = await verificaImage(e.target.files[0]);
    if (!isValid) {
      setPreviewImage(null)
      return; 
    }
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target?.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
      onChangeImage(e.target.files[0]);
    }
  };
    const verificaImage = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width < 1128 || height < 286) {
            window.alert("As imagens devem ser pelo menos 1128 × 286");
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
    });
  };

  useEffect(() => {
    setPreviewImage(src);
  }, [src]);

  return (
    <div className="banner-container">
      <div className="banner-upload">
        <div className="banner-edit">
        <input
            type="file"
            id="bannerUpload"
            accept=".png, .jpg, .jpeg"
            name="banner"
            className="input-file"
            onChange={handleImageChange}
          />
          <label htmlFor="bannerUpload">
            <EditIcon style={{color:"#000"}} />
          </label>
        </div>
        <div className="banner-preview">
          <div className="image-preview" style={{ backgroundImage: `url(${previewImage})` }}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitBannerUpload;
