import React from 'react';
import { useContext } from 'react';
import { DropdownContext } from '../DropdownContext';
import { Lists } from './styles';

const List: React.FC = ({children}) => {
    const {open}= useContext(DropdownContext)

    return(
        open ? <Lists>
            {children}
        </Lists>
        : null
        
    );
};

export default List;