import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { ThemeProvider, DefaultTheme } from "styled-components";
import AuthContext from "../Auth";
import { IThemeContextData } from "./interface";
import { createMuiTheme, Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

export const defaultTheme: DefaultTheme = {
  colors: {
    primary: "#8E5AED",
    primaryContrast: "#FFFFFF",
    secondary: "#FFFFFF",
    secondaryContrast: "#8E5AED",
    text: "#000000",
    title: "#000000",
    logoUrl: "https://res.cloudinary.com/peopleclub/image/upload/v1658404737/company_logo_custom_label/zvigkeaad6btxdf1eeun.png",
  },
  name: "default",
};

export const CustomThemeProvider: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext);

  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);
  const [muiTheme, setMuiTheme] = useState<Theme|null>(null);

  useEffect(() => {
    if (!authContext.selectedCompany) {
      setTheme(defaultTheme);
      return;
    }

    const newTheme: DefaultTheme = {
      ...defaultTheme,
      colors: {
        ...defaultTheme.colors,
        primary: authContext.selectedCompany.CompanyTheme?.primaryColorHex || defaultTheme.colors.primary,
        primaryContrast:
          authContext.selectedCompany.CompanyTheme?.primaryContrastColorHex ||
          defaultTheme.colors.primaryContrast,
        secondary:
          authContext.selectedCompany.CompanyTheme?.secondaryColorHex || defaultTheme.colors.secondary,
        secondaryContrast:
          authContext.selectedCompany.CompanyTheme?.secondaryContrastColorHex ||
          defaultTheme.colors.secondaryContrast,
        text: authContext.selectedCompany.CompanyTheme?.textColorHex || defaultTheme.colors.text,
        title: authContext.selectedCompany.CompanyTheme?.titleColorHex || defaultTheme.colors.title,
        logoUrl: authContext.selectedCompany.CompanyTheme?.logoUrl || defaultTheme.colors.logoUrl,
      },
    };

    setTheme(newTheme);
  }, [authContext.selectedCompany]);

  useEffect(() => {
    const newMuiTheme = createMuiTheme({
      palette: {
        primary: {
          main: theme.colors.primary,
          contrastText: theme.colors.primaryContrast,
        },
        secondary: {
          main: theme.colors.secondary,
          contrastText: theme.colors.secondaryContrast,
        },
      },
    });
    setMuiTheme(newMuiTheme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          {children}
        </MuiThemeProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
