import React, { memo } from 'react';

import { ImageDefault } from './styles';
import { IImage } from './interface';

const Image: React.FC<IImage> = ({ url, width, height }: IImage) => {
    return (
        <ImageDefault src={url} width={width} height={height} />
    )
}
export default memo(Image);