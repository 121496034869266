import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";

import { TapWrapper } from "./styles";
import Tab from "../../../../common/components/TabDefault/Tab";
import Tabs from "../../../../common/components/TabDefault/Tabs";
import { Card } from "../../../../common/components/Card";
import { useQuery } from "@apollo/client";

import CompanySelect from "../../../../common/components/CompanySelect";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import StoreModal from "./Modal";
import Helmet from "react-helmet";
import Typography from "../../../../common/components/Typography";
import { QUERY_SUBCATEGORIES } from "./graphql";
import { store, storeVariables } from "./types/store";
import { ProdutosCatergoria } from "./interfaces";
import api from "../../../../services/api";
import { ProdutosRequest } from "./interfacesss";

const StoreComponent: React.FC = () => {
  const context = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const [produtoCateogria, setProdutosCategoria] = useState<ProdutosCatergoria[]>([]);
  const [produtoRequest, setProdutoRequest] = useState<ProdutosRequest[]>([]);
  const [storeProductId, setStoreProductId] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const getProdutos = async () => {
    try {
      const { data } = await api.get("/produtos-categoria",);
      setProdutosCategoria(data);
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os produtos da empresa.");

    }
    finally {
      setLoading(false);
    }
  }

  const getProdutosRequest = async () => {
    try {
      const { data } = await api.get("/produtos-request",);
      setProdutoRequest(data);
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os produtos da empresa.");

    }
    finally {
      setLoading(false);
    }
  }



  const hasRequest = (produtoRequest: ProdutosRequest, productId: string): boolean => {
    if (!produtoRequest.company || produtoRequest.company.__typename !== "Company") {
      return false;
    }

    return !!produtoRequest.company.productRequests.find(
      (productRequest) =>
        productRequest.type === "PRODUCT_DISABLE" &&
        productRequest.product.id === productId
    );
  };


  useEffect(() => {
    getProdutos();
    getProdutosRequest();
  }, [])

  return (
    <>
      <>
  
        <StoreModal
          isShown={!!storeProductId}
          hide={() => setStoreProductId(null)}
          productId={storeProductId}
          companyId={companyId}
        />
        <Row>
          <Col md={8}>
            <PageTitle
              title="Store"
              subtitle="Aqui você pode adquirir benefícios para os seus colaboradores."
            />
          </Col>
        </Row>
        {produtoCateogria.length ? (
          produtoCateogria.map(productCategory => (
            // console.log("Testee",productCategory ),
            <Panel borderRadius="20px" paddingBottom="18px" marginBottom="22px">
              <Typography fontSize="18px" fontWeight="500">
                {productCategory.name}
              </Typography>
              {productCategory.ProductSubcategory.map((subcategory) => {
                console.log("Testee, sub", subcategory);
                return (
                  <div key={subcategory.id}>
                    <Tab title={subcategory.name}>

                      <TapWrapper>
                        {subcategory.Product.map(product => {
                          console.log("Product:", product);
                          return (
                            <div key={product.id}>
                              <Card
                                src={product.imageUrl}
                                description={product.title}
                                waiting={
                                  produtoRequest.some(pr => hasRequest(pr, product.id))
                                    ? "Benefício em negociação para esta empresa"
                                    : undefined
                                }
                                buttons={[
                                  {
                                    variant: "primary",
                                    text: "Adquirir benefício",
                                    onClick: () => setStoreProductId(product.id),
                                    disabled: produtoRequest.some(pr => hasRequest(pr, product.id))
                                  },
                                  {
                                    variant: "secondary",
                                    text: "Saiba mais",
                                    onClick: () =>
                                      window.open(product.detailsUrl || undefined),
                                    disabled: !product.detailsUrl,
                                  },
                                ]}
                              >

                              </Card>
                            </div>
                          );
                        })}
                      </TapWrapper>
                    </Tab>
                  </div>
                );
              })}

            </Panel>
          ))

          //  {productCategory.ProductSubcategory.map((subcategory) => (
          //   <Tab title={subcategory.name}>
          //     <TapWrapper>
          //       {subcategory.Product && subcategory.Product.map((product) => (
          //         <Card
          //           src={product.imageUrl}
          //           description={product.title}
          //           waiting={
          //             produtoRequest.some(pr => hasRequest(pr, product.id))
          //               ? "Benefício em negociação para esta empresa"
          //               : undefined
          //           }
          //           buttons={[
          //             {
          //               variant: "primary",
          //               text: "Adquirir benefício",
          //               onClick: () => setStoreProductId(product.id),
          //               disabled: produtoRequest.some(pr => hasRequest(pr, product.id))
          //             },
          //             {
          //               variant: "secondary",
          //               text: "Saiba mais",
          //               onClick: () =>
          //                 window.open(product.detailsUrl || undefined),
          //               disabled: !product.detailsUrl,
          //             },
          //           ]}
          //         ></Card>
          //       ))}
          //     </TapWrapper>
          //   </Tab>
          // ))} 
        ) : (
          <Panel borderRadius="20px" paddingBottom="18px" marginBottom="22px">
            Sua empresa não possui produtos disponíveis na Store.
            < br />
            Vá para aba de Benefícios!
          </Panel >
        )}
      </>
    </>
  );
};

export default StoreComponent;
