import React, { createContext, useEffect, useState } from "react";
import { useClearCache } from "react-clear-cache";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { DateTime } from "luxon";
import { MUTATION_REFRESH_TOKEN, MUTATION_SELECT_COMPANY, QUERY_ME, QUERY_SELECTED_COMPANY } from "./graphql";
import { Company, UserInfo } from "./types/MeFragment";
import { AuthContextData, Select, TokenInfos } from "./interface";
import { client } from "../../../../apollo";
import { RefreshToken } from "./types/RefreshToken";
import api from "../../../../services/api";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  let isLoggingOut = false;

  const [token, setToken] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(true);
  const [authenticating, setAuthenticating] = useState(false);
  const [userInfos, setUserInfos] = useState<UserInfo | undefined>();
  const { emptyCacheStorage } = useClearCache();
  const [companySelect, setCompanySelect] = useState<Select>({ selectedValue: null, options: [] });
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [lastSelectedCompanyId, setLastSelectedCompanyId] = useState<string | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  useEffect(() => {
    if (!companySelect.selectedValue) {
      return;
    }

    setLastSelectedCompanyId(companySelect.selectedValue);
  }, [companySelect.selectedValue]);

  useEffect(() => {
    if (!lastSelectedCompanyId) {
      return;
    }

    getCompanyInfo(lastSelectedCompanyId);
  }, [lastSelectedCompanyId])


  const getCompanyInfo = async (companyId: string) => {
    setIsLoadingCompany(true);

    try {
      const { data } = await api.get("/company/" + companyId);

      setSelectedCompany({
        ...data.data,
        CompanyTheme: data.data.CompanyTheme && data.data.CompanyTheme.length > 0
          ? data.data.CompanyTheme[0]
          : null
      });
    } catch (e) {
      setSelectedCompany(null);
      console.log(e);
    } finally {
      setIsLoadingCompany(false);
    }
  };

  const saveToken = (secretJwt: string, selectedCompanyId: string) => {
    localStorage.setItem("token", secretJwt);
    localStorage.setItem("selectedCompanyId", selectedCompanyId);
    setToken(secretJwt);
    setCompanySelect(prev => ({ ...prev, selectedValue: selectedCompanyId }))
  };

  const eraseToken = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpires");
    await emptyCacheStorage();
    await client.cache.reset();
    setToken(null);
    setUserInfos(undefined);
  };

  const getTokenInfos = (secretJwt: string): TokenInfos | null => {
    const decoded = jwtDecode<JwtPayload>(secretJwt);
    if (!decoded.exp || !decoded.iat || !decoded.iss || !decoded[decoded.iss]?.selectedCompanyId) {
      return null;
    }

    return {
      secretJwt,
      expiresAt: DateTime.fromSeconds(decoded.exp),
      issuedAt: DateTime.fromSeconds(decoded.iat),
      selectedCompanyId: decoded[decoded.iss]?.selectedCompanyId,
    }
  }

  useEffect(() => {
    async function loadStorageData() {
      console.log('loadStorageData');
      const secretJwt = localStorage.getItem("token");
      const selectedCompanyId = localStorage.getItem("selectedCompanyId");
      if (!secretJwt || !selectedCompanyId) {
        await eraseToken();
        setRefreshing(false);
        return;
      }

      const tokenInfos = getTokenInfos(secretJwt);
      if (!tokenInfos) {
        await eraseToken();
        setRefreshing(false);
        return;
      }

      if (tokenInfos.expiresAt < DateTime.now()) {
        await eraseToken();
        setRefreshing(false);
        return;
      }

      saveToken(secretJwt, selectedCompanyId);

      try {
        const { data } = await api.get("/auth/me");


        if (data.type !== "companyAdmin") {
          await eraseToken();
          setRefreshing(false);
          return;
        }

        setUserInfos(data.data);
        setRefreshing(false);
        setCompanySelect({
          selectedValue: selectedCompanyId,
          options: [
            { value: data.data.Company.id, text: data.data.Company.name + ' (Matriz)' },
            ...data.data.Company.companies.slice().sort((a, b) => a.name.localeCompare(b.name)).map(company => ({
              value: company.id,
              text: company.hasChildren ? `${company.name} (Grupo)` : company.name
            })),
          ],
        });

      } catch (e) {
        await eraseToken();
        setRefreshing(false);
        return;
      }
    }
    loadStorageData();
  }, []);

  async function loadUser(forceSelectedCompanyId?: string) {
    try {
      const { data } = await api.get("/auth/me");

      if (data.type !== "companyAdmin") {
        await eraseToken();
        setRefreshing(false);
        return false;
      }

      setUserInfos(data.data);

      setCompanySelect({
        selectedValue: forceSelectedCompanyId || companySelect.selectedValue,
        options: [
          {
            value: data.data.Company.id,
            text: data.data.Company.name + ' (Matriz)'
          },
          ...data.data.Company.companies.slice().sort((a, b) => a.name.localeCompare(b.name)).map(company => ({ value: company.id, text: company.name })),
        ],
      });

      return true;
    } catch (e) {
      console.log(e)
    }

    await eraseToken();
    setRefreshing(false);
    return false;
  }

  async function setSelectedCompanyId(id: string) {
    setRefreshing(true);

    try {
      const { data } = await api.get(`/company/${id}/select-company`);

      saveToken(
        data.token,
        data.companyId
      );
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  }

  async function signIn(email: string, password: string) {
    setAuthenticating(true);

    try {
      const { data } = await api.post("/auth/signIn", { email, password });
      setAuthenticating(false);

      setRefreshing(true);

      await eraseToken();
      saveToken(data.token, data.admin.companyId);

      await loadUser(data.admin.companyId);

      setRefreshing(false);
      return true;
    } catch (e) {
      console.log(e);
    }

    setAuthenticating(false);
    setRefreshing(false);
    return false;
  }

  async function refreshToken(): Promise<void> {
    const response = await client.mutate<RefreshToken>({
      mutation: MUTATION_REFRESH_TOKEN
    });
    const token = response.data?.refreshToken;
    if (token?.__typename !== "CreatedToken") {
      console.error(`Unable to refresh token: [${token?.__typename}] ${token?.message} `);
      return;
    }

    saveToken(token.secretJwt, token.selectedCompanyId);

    return;
  }

  async function signOut() {
    if (isLoggingOut) {
      return;
    }
    setRefreshing(true);
    isLoggingOut = true;
    await eraseToken();
    isLoggingOut = false;
    setRefreshing(false);
  }

  async function sso(token: string) {
    setRefreshing(true);
    const tokenInfo = getTokenInfos(token);
    saveToken(token, tokenInfo?.selectedCompanyId);
    await loadUser(tokenInfo?.selectedCompanyId);
    setRefreshing(false);
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!token,
        authenticating,
        jwt: token,
        authenticate: signIn,
        unauthenticate: signOut,
        loading: refreshing || isLoadingCompany,
        companyAdmin: userInfos,
        sso,
        companySelect,
        selectCompanyId: setSelectedCompanyId,
        selectedCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
