import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, ICardButton, ICardSwitch } from "../../../../common/components/Card";
import CompanySelect from "../../../../common/components/CompanySelect";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import { TapWrapper } from "../Store/styles";
import Tabs from "../../../../common/components/TabDefault/Tabs";
import Tab from "../../../../common/components/TabDefault/Tab";
import Typography from "../../../../common/components/Typography";
import {
  QUERY_SUBCATEGORIES_BENEFIT,
  QUERY_GET_BENEFICIO_FACIL_URL,
  QUERY_GET_GAMEFIC_URL,
  QUERY_GET_INMANO_URL,
} from "./graphql";
import BenefitModal from "./Modal";
import { Helmet } from "react-helmet";
import { GetGameficUrl } from "./types/GetGameficUrl";
import { GetBeneficioFacilUrl } from "./types/GetBeneficioFacilUrl";
import { benefits, benefitsVariables } from "./types/benefits";
import { GetInManoUrl } from "./types/GetInManoUrl";
import ActivationModal from "./Activation";
import { Switch } from '@material-ui/core';

const Benefits: React.FC = () => {
  const context = useContext(AuthContext);

  // const [companyId, setCompanyId] = useState<string>(
  //   context.companyAdmin?.company.__typename === "Company"
  //     ? context.companyAdmin.company.id
  //     : ""
  // );
  const companyId = context.companySelect.selectedValue;
  const [productId, setProductId] = useState<string | null>();
  const [activationProductId, setActivationProductId] = useState<string | null>(null);

  const products = useQuery<benefits, benefitsVariables>(
    QUERY_SUBCATEGORIES_BENEFIT,
    {
      variables: { companyId },
    }
  );

  const hasRequest = (productId: string) => {
    if (products.data?.company.__typename !== "Company") {
      return false;
    }

    return !!products.data.company.productRequests.find(
      (productRequest) =>
        productRequest.type === "PRODUCT_DISABLE" &&
        productRequest.product.id === productId
    );
  };

  const [getGameficUrl, gameficUrl] = useLazyQuery<GetGameficUrl>(
    QUERY_GET_GAMEFIC_URL,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!gameficUrl.data) {
      return;
    }

    if (
      gameficUrl.data?.company.__typename !== "Company" ||
      !gameficUrl.data.company.microApps.gameficUrl
    ) {
      alert(
        "Não foi possível conectar com a plataforma Gamefic. Tente novamente mais tarde."
      );
      return;
    }

    window.open(gameficUrl.data.company.microApps.gameficUrl, "_blank");
  }, [gameficUrl.data]);

  const [getBeneficioFacilUrl, beneficioFacilUrl] =
    useLazyQuery<GetBeneficioFacilUrl>(QUERY_GET_BENEFICIO_FACIL_URL, {
      fetchPolicy: "network-only",
    });

    const [getInManoUrl, inmanoUrl] =
    useLazyQuery<GetInManoUrl>(QUERY_GET_INMANO_URL, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (!beneficioFacilUrl.data) {
      return;
    }

    if (
      beneficioFacilUrl.data?.company.__typename !== "Company" ||
      !beneficioFacilUrl.data.company.microApps.beneficioFacilUrl
    ) {
      alert(
        "Não foi possível conectar com a plataforma Benefício Fácil. Tente novamente mais tarde."
      );
      return;
    }

    window.open(
      beneficioFacilUrl.data.company.microApps.beneficioFacilUrl,
      "_blank"
    );
  }, [beneficioFacilUrl.data]);

  useEffect(() => {
    if (!inmanoUrl.data) {
      return;
    }

    if (
      inmanoUrl.data?.company.__typename !== "Company" ||
      !inmanoUrl.data.company.microApps.inmanoUrl
    ) {
      alert(
        "Não foi possível conectar com a plataforma InMano. Tente novamente mais tarde."
      );
      return;
    }

    window.open(
      inmanoUrl.data.company.microApps.inmanoUrl,
      "_blank"
    );
  }, [inmanoUrl.data]);

  const openIfood = () =>
    window.open("https://empresas-app.ifood.com.br/", "_blank");

  const benefitsCategories =
    products?.data?.company.__typename !== "Company"
      ? null
      : products.data.company.productCategories;

      const isProductActive = (productId: string) => products?.data?.company.__typename !== "Company" ? false : !!products.data.company.activeProducts.find((product) => product.id === productId);

  return (
    <>
      <>
        <BenefitModal
          isShown={!!productId}
          hide={() => setProductId(null)}
          productId={productId}
          companyId={companyId}
        />
        {companyId && activationProductId ? (
          <ActivationModal
            isActive={isProductActive(activationProductId)}
            isShown={!!activationProductId}
            hide={() => setActivationProductId(null)}
            productId={activationProductId}
            companyId={companyId}
          />
        ) : null}
        <Row>
          <Col md={8}>
            <PageTitle
              title="Benefícios"
              subtitle="Aqui você pode gerenciar os benefícios adquiridos pela sua empresa."
            />
          </Col>
        </Row>
        {products.loading ? (
          <Loading />
        ) : null}
        {benefitsCategories?.map((benefitsCategory) => (
          <Panel borderRadius="20px" paddingBottom="18px" marginBottom="22px">
            <Typography fontSize="18px" fontWeight="500">
              {benefitsCategory.name}
            </Typography>
            <Tabs>
              {benefitsCategory.subcategories.map((subcategory) => (
                <Tab title={subcategory.name}>
                  <TapWrapper>
                    {subcategory.products.map((product) => {
                      const cancelButton: ICardButton = {
                        variant: "danger",
                        text: "Cancelar benefício",
                        disabled: hasRequest(product.id),
                        onClick: () => setProductId(product.id),
                      };
                      const activationSwitch: ICardSwitch = isProductActive(product.id) ? {
                        text: "Visível",
                        switchProps: {
                          onChange: () => setActivationProductId(product.id),
                          checked: true,
                        },
                      } : {
                        text: "Escondido",
                        switchProps: {
                          onChange: () => setActivationProductId(product.id),
                          checked: false,
                        },
                      }
                      const buttons: Record<string, ICardButton[]> = {
                        gamefic: [
                          cancelButton,
                          {
                            variant: "primary",
                            text: "Acessar",
                            disabled: gameficUrl.loading,
                            loading: gameficUrl.loading,
                            onClick: () =>
                              getGameficUrl({ variables: { companyId } }),
                          },
                        ],
                        beneficio_facil: [
                          cancelButton,
                          {
                            variant: "primary",
                            text: "Acessar",
                            disabled: beneficioFacilUrl.loading,
                            loading: beneficioFacilUrl.loading,
                            onClick: () =>
                              getBeneficioFacilUrl({
                                variables: { companyId },
                              }),
                          },
                        ],
                        inmano: [
                          cancelButton,
                          {
                            variant: "primary",
                            text: "Acessar",
                            disabled: inmanoUrl.loading,
                            loading: inmanoUrl.loading,
                            onClick: () =>
                              getInManoUrl({
                                variables: { companyId },
                              }),
                          },
                        ],
                        meal_ticket_ifood: [
                          cancelButton,
                          {
                            variant: "primary",
                            text: "Acessar",
                            onClick: openIfood,
                          },
                        ],
                      };
                      return (
                        <Card
                          src={product.imageUrl}
                          description={product.title}
                          waiting={
                            hasRequest(product.id)
                              ? "Cancelamento em progresso"
                              : undefined
                          }
                          switches={[activationSwitch]}
                          buttons={
                            buttons[product.id] || [
                              cancelButton,
                              {
                                variant: "secondary",
                                text: "Saiba mais",
                                disabled: !product.detailsUrl,

                                onClick: () =>
                                  window.open(product.detailsUrl || undefined),
                              },
                            ]
                          }
                        ></Card>
                      );
                    })}
                  </TapWrapper>
                </Tab>
              ))}
            </Tabs>
          </Panel>
        ))}
      </>
    </>
  );
};

export default Benefits;
