import React from "react";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";

export const SuccessModal: React.FC<{
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  children: any;
}> = ({ isShown, hide, children }) => {
  return (
    <ModalDefault
      isShown={isShown}
      hide={hide}
      minWidth="550px"
      maxWidth="550px"
    >
      {children}
    </ModalDefault>
  );
};
