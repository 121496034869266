import styled from "styled-components";
import { prop } from "styled-tools";

export const Header = styled.div`
  background-color: ${prop("theme.colors.primary")};
  color: ${prop("theme.colors.primaryContrast")};
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;

export const BackButton = styled.button`
  border: none;
  background-color: transparent;
  color: inherit;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const Iframe = styled.iframe`
  height: 100%;
  border: none;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;
