import {gql} from '@apollo/client';

export const MUTATION_WORKER_INVITES = gql`
    mutation WorkerMultiplesInvites($data: [CreateWorkerInviteInput!]!) {
  createWorkerInvites(data: $data) {
    __typename
    ... on WorkerInvite {
      id
      createdAt
      acceptedAt
      emailSentAt
    }
    ... on ErrorWithMessage {
      message
    }
    ... on ErrorWithInputPath {
      inputPath
    }
    ... on ErrorWithValidationRule {
      validationRule
    }
  }
}
`