import { OperationVariables, QueryResult, useQuery } from "@apollo/client";

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CompanySelect from "../../../../common/components/CompanySelect";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import List from "../../../../common/components/DropdownDefault/List";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import AuthContext from "../../../../common/components/hooks/Auth";
import Icon from "../../../../common/components/Icon";
import InputBackground from "../../../../common/components/InputBackground";
import Loading from "../../../../common/components/Loading";
import NoResult from "../../../../common/components/NoResult";
import PageTitle from "../../../../common/components/PageTitle";
import Pagination from "../../../../common/components/Pagination";
import Paginator from "../../../../common/components/Paginator";
import { Panel } from "../../../../common/components/Panel";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import DownloadIcon from '@mui/icons-material/Download';
import {
  Table,
  TH,
  Theader,
  Tr,
} from "../../../../common/components/Table/styles";
import DeleteModal from "./DeleteModal";
import { QUERY_WORKER_INVITES } from "./graphql";
import ModalInvite from "./Modal";
import { Button, ButtonWrapper, ContainerTable, GridWrapper, SectionWrapper, TableWrapper, WrapperBack } from "./styles";
import { WorkerInvites, WorkerInvitesVariables } from "./types/WorkerInvites";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "../../../../common/components/Typography";
import SendEmailModal from "./SendEmailModal";
import Items from "../../../../common/components/DropdownDefault/Items";
import SelectCustom from "../../../../common/components/Selected";

import { useHistory, useLocation } from "react-router-dom";
import api from "../../../../services/api";
import { Chip, Paper, TablePagination } from "@material-ui/core";
import { ThemeContext } from "styled-components";
import { Stack } from "@mui/material";
import ModalInvitePremium from "./Modal";

interface Invite {
  id: string;
  workerEmail: string;
  externalId: string | null;
  companyId: string;
  createdAt: Date;
  acceptedAt: Date | null;
  emailSentAt: Date | null;
  sequential: number;
}

const schema = yup.object().shape({
  workerEmail: yup.string().email(),
});

const InvitePremium: React.FC = () => {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const location = useLocation();

  const [filterTrigger, setFilterTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [invites, setInvites] = useState<Invite[]>([]);

  const params = new URLSearchParams(location.search);
  const [workerEmail, setWorkerEmail] = useState<string | null>();
  const [isCreating, setIsCreating] = useState(false);
  const theme = useContext(ThemeContext);

  const [deletingId, setDeletingId] = useState<string | null>();
  const [sendingEmailId, setSendingEmailId] = useState<string | null>();
  const [emailSentAtFrom, setEmailSentAtFrom] = useState<string | null>(null);
  const [emailSentAtTo, setEmailSentAtTo] = useState<string | null>(null);
  const [acceptedAtFrom, setAcceptedAtFrom] = useState<string | null>(null);
  const [acceptedAtTo, setAcceptedAtTo] = useState<string | null>(null);
  const [queryStatus, setQueryStatus] = useState<string>(
    params.get("status") || "todos"
  );

  const handleChangePage = (__, newPage: number) => {
    setPage(newPage);
  };
  const downloadModelo = () => {
    api.get(`/invites/download-modelo`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
  
        // Criação do elemento <a> temporário para download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "modelo.csv"); // Nome do arquivo ao ser baixado
        document.body.appendChild(link);
        link.click();
  
        // Limpeza dos recursos temporários
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Erro ao baixar o arquivo:', error);
      });
  };
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const getInvites = async () => {
    setIsLoading(true);

    try {
      const params: any = {
        page: page + 1,
        companyId: context.companySelect.selectedValue,
        workerEmail,
        accepted: queryStatus,
        emailSentAtFrom,
        emailSentAtTo,
        acceptedAtFrom,
        acceptedAtTo,
      };

      const { data } = await api.get('invites', { params });

      setTotal(data.total);
      setInvites(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const resetSearch = (): void => {
    setWorkerEmail('');
    setPage(0);
    setQueryStatus('todos');
    setEmailSentAtFrom(null);
    setEmailSentAtTo(null);
    setAcceptedAtFrom(null);
    setAcceptedAtTo(null);
  };

  useEffect(() => {
    getInvites();
  }, [page, filterTrigger]);

  return (
    <>

      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "1%", marginRight: "6%", marginBottom: "2%", width: "100%" }}>
              <PageTitle
                title="Convites"
                subtitle="Aqui você pode visualizar, criar e editar convites dos colaboradores."
              />

              <Stack direction="row" spacing={1}>
                <Chip label="Novo Convite" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"
                }}
                  onClick={() => setIsCreating(true)} />

                <Chip
                  label="Baixar modelo"
                  onClick={downloadModelo}
                  color="secondary"
                  style={{
                    backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"

                  }}

                />

                <Chip label="Envio em massa" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push("/planilha")} />

              </Stack>

            </div>
            <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "1%", marginRight: "2%", width: "100%" }}>

            </div>
            <ModalInvitePremium
              isShown={isCreating}
              hide={() => setIsCreating(false)}
              companyId={context.companySelect.selectedValue}
              refresh={resetSearch}
            />
            <DeleteModal
              isShown={!!deletingId}
              hide={() => setDeletingId(null)}
              id={deletingId}
              refresh={getInvites}
            />
            <SendEmailModal
              isShown={!!sendingEmailId}
              hide={() => setSendingEmailId(null)}
              id={sendingEmailId}
            />



            <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-5%", paddingRight: "2%" }}>
              <form onSubmit={handleSubmit((data) => {
                setWorkerEmail(data.workerEmail || null);
                setPage(0);
                setFilterTrigger(prev => !prev);
              })} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Controller
                    control={control}
                    name="workerEmail"
                    defaultValue=""
                    render={({ field }) => (
                      <InputBackground {...field} onChange={(event) => field.onChange(event.target.value.toLowerCase().trim())} borderRadius="30px" placeholder="Filtrar por e-mail" />
                    )}
                  />
                  <Typography fontSize="15px" fontWeight="300" fontColor="red">
                    {errors.workerEmail?.message}
                  </Typography>

                  <div style={{ marginTop: "-3%" }}>
                    <label style={{ fontSize: "12px", }}>Data de envio (de): </label>
                    <input type="date" value={emailSentAtFrom || ''} onChange={(e) => setEmailSentAtFrom(e.target.value || null)} />
                  </div>
                  <div style={{ marginTop: "-3%" }}>
                    <label style={{ fontSize: "12px" }}>Data de envio (até): </label>
                    <input type="date" value={emailSentAtTo || ''} onChange={(e) => setEmailSentAtTo(e.target.value || null)} />
                  </div>

                  <div style={{ marginTop: "-3%" }}>
                    <label style={{ fontSize: "12px" }}>Data de aceitação (de): </label>
                    <input type="date" value={acceptedAtFrom || ''} onChange={(e) => setAcceptedAtFrom(e.target.value || null)} />
                  </div>
                  <div style={{ marginTop: "-3%" }}>
                    <label style={{ fontSize: "12px" }}>Data de aceitação (até): </label>
                    <input type="date" value={acceptedAtTo || ''} onChange={(e) => setAcceptedAtTo(e.target.value || null)} />
                  </div>
                  <div style={{ width: "200px", marginTop: "4%" }}>
                    <SelectCustom
                      onChange={(event) => {
                        setQueryStatus(event.target.value);
                      }}
                      value={queryStatus}
                      options={[
                        {
                          value: "todos",
                          text: "Todos",
                        },
                        {
                          value: "pendentes",
                          text: "Pendentes",
                        },
                        {
                          value: "aceitos",
                          text: "Aceitos",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', gap: '10px', justifyContent: 'start', marginLeft: "2%", marginBottom: "4%", marginTop: "-3%" }}>
                  <Button type="submit" disabled={false}
                    style={{
                      backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                      fontSize: "10px",
                      width: "100px",
                      padding: "7px 7px",
                      borderRadius: "30px",
                      fontWeight: "bold",
                    }}>
                    Buscar
                  </Button>

                  <Button onClick={resetSearch}
                    style={{
                      backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                      fontSize: "10px",
                      width: "100px",
                      padding: "7px 7px",
                      borderRadius: "30px",
                      fontWeight: "bold",
                    }}>
                    Limpar Filtros
                  </Button>
                </div>

              </form>
            </div>


            {!isLoading && total === 0 && (
              <NoResult />
            )}

            <TableWrapper>
              <ContainerTable>
                <Table>
                  <thead>
                    <tr>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Criado</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Enviado</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Aceito</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Email do convite</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Ações</Theader>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading && invites.map((item) => (
                      <Tr key={item.id}>
                        <TH>
                          {item.createdAt
                            ? moment(item.createdAt).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </TH>
                        <TH>
                          {item.emailSentAt
                            ? moment(item.emailSentAt).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </TH>
                        <TH>
                          {item.acceptedAt
                            ? moment(item.acceptedAt).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                        </TH>
                        <TH>{item.workerEmail}</TH>
                        <TH>
                          <Dropdown>
                            <Toggle>
                              <Icon name="more-vertical" />
                            </Toggle>
                            <List>
                              <Items
                                onClick={() =>
                                  item.acceptedAt
                                    ? alert(
                                      "Este convite não pode ser excluído pois já foi aceito!"
                                    )
                                    : setDeletingId(item.id)
                                }
                              >
                                <Button>Excluir convite</Button>
                              </Items>
                              <Items
                                onClick={() =>
                                  item.acceptedAt
                                    ? alert(
                                      "Não é possível enviar o e-mail para este convite pois ele já foi aceito!"
                                    )
                                    : setSendingEmailId(item.id)
                                }
                              >
                                <Button>Enviar novamente</Button>
                              </Items>
                            </List>
                          </Dropdown>
                        </TH>
                      </Tr>
                    ))}
                  </tbody>
                </Table>

              </ContainerTable>
              {isLoading && (
                <Loading />
              )}

              <TablePagination
                count={total}
                page={page}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                component="div"
              />

            </TableWrapper>

          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>
    </>
  );
};

export default InvitePremium;
