import React, { useState } from "react";
import DangerButton from "../../../../../common/components/DangerButton";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import api from "../../../../../services/api";


interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh: () => void;
}

const DisableModal: React.FC<Props> = ({ isShown, hide, id,refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await api.put(`/worker/${id}/disable`);
      alert("Acesso desabilitado com sucesso!");
      refresh();
      hide();
    } catch (e) {
      alert("Erro ao desabilitar acesso do colaborador. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  }

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja desabilitar o acesso desse colaborador?
      </Typography>
      <br />
      <DangerButton
        color="red"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        Desabilitar acesso
      </DangerButton>
      <SecondaryButton disabled={isLoading} onClick={hide}>Voltar</SecondaryButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default DisableModal;
