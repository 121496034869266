import React from "react";
import { Panel } from "../Panel";
import PrimaryButton from "../PrimaryButton/styles";
import SecondaryButton from "../SecondaryButton";
import { Top, ImageWrapper, Wrapper } from "./styles";
import Icon from "../Icon";
import Typography from "../Typography";
import DangerButton from "../DangerButton";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Spinner } from "react-bootstrap";
import { Switch, SwitchProps } from "@material-ui/core";

export type ICardButton = {
  display?: string;
  text: string;
  onClick?: any;
  disabled?: boolean | any;
  loading?: boolean;
  variant?: "primary" | "secondary" | "danger";
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  fontSize?: string;
  iconName?: string;
  maxWidth?: string;
};

export type ICardSwitch = {
  text: string;
  switchProps?: SwitchProps;
}

type ICards = {
  description?: string;
  waiting?: string;
  approved?: string;
  src: string;
  buttons: ICardButton[];
  switches?: ICardSwitch[];
};

export const Card: React.FC<ICards> = (props) => {
  return (
    <Panel
      borderRadius="25px"
      marginRight="18px"
      marginLeft="18px"
      marginBottom="18px"
      marginTop="10px"
      maxWidth="260px"
    >
      <Wrapper>
        <div>
          <Top>
            <ImageWrapper imageUrl={props.src} />
            <Typography wrapLine={4} fontSize="14px" fontWeight="400">
              {props.description}
            </Typography>
          </Top>
          <Typography
            marginLeft="8px"
            paddingTop="7px"
            paddingBottom="7px"
            fontSize="12px"
            fontWeight="500"
            fontColor="#969696"
          >
            {props.waiting}
          </Typography>
          {props.approved ? (
            <Typography
              marginTop="-15px"
              paddingBottom="7px"
              paddingTop="7px"
              fontSize="12px"
              fontWeight="500"
              fontColor="green"
            >
              <Icon name="check" /> {props.approved}
            </Typography>
          ) : null}
        </div>
        <div>
          <>
            {props.switches?.map((item) => {
              const content = <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 10}}>
                <div><Switch {...item.switchProps} color="primary" /></div>
                <div>{item.text}</div>
              </div>
              return content;
            })}
          </>
          {props.buttons.map((button) => {
            const content = (
              <>
                <Icon name={button.iconName} />
                {button.text}
                {button.loading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginLeft: 5 }}
                  />
                ) : null}
              </>
            );

            return button.variant === "primary" ? (
              <div style={{ marginTop: '14px' }}>
                <PrimaryButton
                  paddingRight="17px"
                  paddingLeft="17px"
                  paddingBottom="7px"
                  paddingTop="7px"
                  fontSize="12px"
                  disabled={button.disabled}
                  onClick={button.onClick}
                >
                  {content}
                </PrimaryButton>
              </div>
            ) : button.variant === "secondary" ? (
              <div style={{ marginTop: '14px' }}>
                <SecondaryButton
                  paddingRight="17px"
                  paddingLeft="17px"
                  paddingBottom="2px"
                  paddingTop="2px"
                  fontSize="12px"
                  disabled={button.disabled}
                  onClick={button.onClick}
                >
                  {content}
                </SecondaryButton>
              </div>
            ) : button.variant === "danger" ? (
              <div style={{ marginTop: '14px' }}>
                <DangerButton
                  paddingRight="17px"
                  paddingLeft="17px"
                  paddingBottom="2px"
                  paddingTop="2px"
                  fontSize="12px"
                  disabled={button.disabled}
                  onClick={button.onClick}
                >
                  {content}
                </DangerButton>
              </div>
            ) : null;
          })}
        </div>
      </Wrapper>
    </Panel>
  );
};
