import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";

import {
  ProfileDataContainer,
  ButtonWrapperAccount,
  ButtonWrapperProfile,
  FormWrapper,
  SavePasswordButton,
  EditDataButton,
  SectionWrapper,
  SectionTitle,
  GridWrapper,
  WrapperBack,
} from "./styles";
import { Link, useHistory, useParams } from "react-router-dom";
import { Card, Container, Divider, List, ListItem, } from "@material-ui/core";
import { Typography } from "@mui/material";
import { ThemeContext } from "styled-components";



function Terms() {
  const { selectedCompany, ...context } = useContext(AuthContext);

  return (
    <>
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <Typography variant="h4" gutterBottom style={{ paddingTop: "2%" }}>
              TERMOS E CONDIÇÕES DE USO
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              EMPRESA: <strong>{selectedCompany.name}</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              CNPJ: <strong>{selectedCompany.cnpj}</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Última atualização em junho de 2024.
            </Typography>
            <Typography variant="body1" paragraph>
              É importante que você leia com atenção os presentes Termos e Condições de Uso antes de utilizar o serviço. Esses Termos e Condições são as regras para a utilização do <strong>{selectedCompany.name}</strong> , plataforma de gestão e comunicação de Clubes de Vantagens e Gestão de Benefícios para empresas CONTRATANTE, que oferecem benefícios a seus USUÁRIOS.
            </Typography>
            <Typography variant="body1" paragraph>
              Você pode acessar a Política de Privacidade do <strong>{selectedCompany.name}</strong>  <Link href="#" to={""}>AQUI</Link>.
            </Typography>
            <Typography variant="body1" paragraph>
              Desse modo, o <strong>{selectedCompany.name}</strong>  oferece diversas funcionalidades, como por exemplo:
            </Typography>
            <List>
              <ListItem>Acesso ao Hub de Benefícios;</ListItem>
              <ListItem>Disponibilização de vouchers e cupons;</ListItem>
              <ListItem>Disponibilização de links parametrizados e/ou formato de identificação específico para acesso a descontos e benefícios em PARCEIROS.</ListItem>
            </List>
            <Typography variant="body1" paragraph>
              As funcionalidades acima descritas são disponibilizadas pelo <strong>{selectedCompany.name}</strong>  aos USUÁRIOS, sem qualquer custo para o USUÁRIO. O valor cobrado pelo <strong>{selectedCompany.name}</strong>  será pago pela empresa CONTRATANTE.
            </Typography>
            <Typography variant="body1" paragraph>
              Vale mencionar que apenas USUÁRIOS elegíveis pela empresa CONTRATANTE poderão ter acesso ao Hub de Benefícios.
            </Typography>
            <Typography variant="body1" paragraph>
              Os Termos de Uso nada mais são do que um contrato entre as partes envolvidas nos serviços disponibilizados através da plataforma <strong>{selectedCompany.name}</strong> . Assim, neste documento são apresentados todos aqueles envolvidos com a prestação dos serviços; bem como são apresentadas as responsabilidades, obrigações e os deveres de cada uma das partes.
            </Typography>
            <Typography variant="body1" paragraph>
              Os Termos de Uso são um tipo de contrato de adesão, desse modo, ao acessar ou utilizar a plataforma <strong>{selectedCompany.name}</strong> , os USUÁRIOS entendem que estão de acordo com as regras apresentadas.
            </Typography>
            <Typography variant="body1" paragraph>
              A concordância em relação às normas deverá ser ratificada antes de concluir uma operação de cadastro. De qualquer maneira, aconselhamos que esse documento seja lido com atenção antes de utilizar a plataforma, bem como seja revisitado de tempos em tempos.
            </Typography>
            <Typography variant="body1" paragraph>
              Informamos também que este documento pode ser eventualmente alterado. A data da última atualização ficará sempre disponibilizada e os USUÁRIOS receberão uma comunicação por e-mail acerca das alterações realizadas.
            </Typography>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              1. DAS DEFINIÇÕES
            </Typography>
            <List>
              <ListItem>Hub de Benefícios: Plataforma online e pacote de serviços, funcionalidades e produtos desenvolvidos pelo  <strong style={{ paddingLeft: "1%" }}>{selectedCompany.name}</strong> ;</ListItem>
              <ListItem>CONTRATANTE: Todas as pessoas físicas ou jurídicas que contratarem o Hub de Benefícios;</ListItem>
              <ListItem>PARCEIROS: Terceiros que oferecem descontos, promoções diversas e benefícios;</ListItem>
              <ListItem>USUÁRIO: Toda pessoa física, titular ou dependente, apresentada pela CONTRATANTE, que fará parte do Hub de Benefícios administrado pelo {selectedCompany.name};</ListItem>
            </List>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              2. DO SISTEMA DO <strong>{selectedCompany.name}</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              2.1. Ao utilizar a plataforma <strong>{selectedCompany.name}</strong> , o USUÁRIO terá acesso a serviços de descontos, promoções diversas e benefícios ofertados por nossos PARCEIROS, por meio de vouchers, cupons, links parametrizados e/ou formato de identificação específico para acesso a descontos e benefícios nas empresas desses PARCEIROS.
            </Typography>
            <Typography variant="body1" paragraph>
              2.2. Os USUÁRIOS ficam cientes de que o fluxo completo do serviço disponibilizado pelo <strong>{selectedCompany.name}</strong>  consiste nas fases relacionadas nos itens abaixo e que a conclusão do cadastro dos USUÁRIOS dependerá da ratificação de concordância quanto às condições dos Termos de Uso e da Política de Privacidade.
            </Typography>
            <Typography variant="body1" paragraph>
              2.3. Vale lembrar, desde já, que os dados pessoais solicitados para a realização do cadastro, bem como a finalidade de coleta desses dados estão explicados de maneira detalhada na Política de Privacidade.
            </Typography>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              3. DO CADASTRO DO USUÁRIO
            </Typography>
            <Typography variant="body1" paragraph>
              3.1. O USUÁRIO realizará o cadastro na plataforma. Os dados coletados quando do cadastro são mencionados na Política de Privacidade. Seu cadastro está sujeito a validação e aprovação pela respectiva CONTRATANTE.
            </Typography>
            <Typography variant="body1" paragraph>
              3.2. Realizado o cadastro do USUÁRIO, o fluxo do processo para se ter acesso aos serviços e as ferramentas disponibilizadas pela plataforma é o seguinte:
            </Typography>
            <List>
              <ListItem>Cadastro do USUÁRIO na plataforma;</ListItem>
              <ListItem>Aprovação e validação do cadastro por parte da CONTRATANTE;</ListItem>
              <ListItem>Confirmação do cadastro através do recebimento de email de boas-vindas;</ListItem>
              <ListItem>Acesso ao Hub de Benefícios através de login e senha;</ListItem>
              <ListItem>Acesso aos benefícios e ofertas disponibilizados na plataforma <strong>{selectedCompany.name}</strong> .</ListItem>
            </List>
            <Typography variant="body1" paragraph>
              3.3. Importante ressaltar que a disponibilização de acesso à plataforma <strong>{selectedCompany.name}</strong>  para os dependentes do USUÁRIO é de opção, escolha e responsabilidade do mesmo, estando o <strong>{selectedCompany.name}</strong>  completamente isento de qualquer responsabilidade nesse sentido.
            </Typography>
            <Typography variant="body1" paragraph>
              3.4. Os cupons e vouchers possuem o nome do USUÁRIO e seu CPF, sendo pessoais e intransferíveis.
            </Typography>
            <Typography variant="body1" paragraph>
              3.5. Caso o usuário esqueça sua senha, deverá selecionar o comando “esqueci minha senha” e gerar nova senha através do link que será enviado por email, após confirmação de email e CPF.
            </Typography>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              4. DAS CONSIDERAÇÕES GERAIS
            </Typography>
            <Typography variant="body1" paragraph>
              4.1. O <strong>{selectedCompany.name}</strong>  atua no processo como ferramenta de comodidade aos USUÁRIOS, mas sem nenhuma participação na execução dos serviços e produtos oferecidos, através da plataforma, pelo PARCEIRO.
            </Typography>
            <Typography variant="body1" paragraph>
              4.2. Por essa razão o USUÁRIO da plataforma está ciente, e desde já concorda, que qualquer produto e/ou serviço ofertado por meio do Hub de Benefícios é adquirido diretamente do PARCEIRO e que este responde exclusiva e inteiramente pela entrega, qualidade, quantidade, estado, existência, legitimidade e integridade dos produtos e/ou serviços ofertados. O USUÁRIO está ciente de que o <strong>{selectedCompany.name}</strong>  e a CONTRATANTE não detêm a posse nem propriedade dos produtos e/ou serviços ofertados através do Hub de Benefícios.
            </Typography>
            <Typography variant="body1" paragraph>
              4.3. Nesse sentido, o <strong>{selectedCompany.name}</strong>  e seus funcionários, representantes e procuradores se eximem de qualquer responsabilidade advinda da relação entre o USUÁRIO e a PARCEIRO, seja a que título e a que tempo for. O <strong>{selectedCompany.name}</strong> , portanto, atua somente como intermediadora no acesso a esses serviços e produtos.
            </Typography>
            <Typography variant="body1" paragraph>
              4.4. Portanto, tanto o <strong>{selectedCompany.name}</strong>  quanto os seus funcionários, representantes e procuradores, não respondem em nenhuma hipótese por pagamento total ou parcial relativo aos serviços prestados e produtos adquiridos junto ao PARCEIRO.
            </Typography>
            <Typography variant="body1" paragraph>
              4.5. O <strong>{selectedCompany.name}</strong>  desempenha atividades-meio no processo de disponibilização dos benefícios e vantagens aos USUÁRIOS da plataforma e, portanto, não se responsabiliza pelo atendimento de solicitações específicas de cupons e benefícios, ou pela eventual exclusão de benefícios ofertados por parte dos PARCEIROS.
            </Typography>
            <Typography variant="body1" paragraph>
              4.6. Por isso, o USUÁRIO desde já reconhece e concorda que não terá qualquer direito de regresso em face ao <strong>{selectedCompany.name}</strong>  por quaisquer danos relacionados às suas compras, serviços e benefícios ofertados, isentando-a, desde já, de quaisquer problemas dessa natureza.
            </Typography>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              5. DAS OBRIGAÇÕES DO USUÁRIO
            </Typography>
            <Typography variant="body1" paragraph>
              5.1. Os USUÁRIOS da plataforma <strong>{selectedCompany.name}</strong>  comprometem-se a utilizar a plataforma de maneira correta e diligente, reconhecendo que é vedado:
            </Typography>
            <List>
              <ListItem>Utilizar a plataforma para quaisquer propósitos ilegais ou não-autorizados;</ListItem>
              <ListItem>Utilizar a plataforma de maneira que possa prejudicar, desativar, sobrecarregar ou comprometer o funcionamento da mesma;</ListItem>
              <ListItem>Utilizar a plataforma para disseminar informações ou materiais difamatórios, ofensivos ou de qualquer forma ilícitos;</ListItem>
              <ListItem>Compartilhar sua senha com terceiros ou permitir acesso não autorizado à sua conta;</ListItem>
              <ListItem>Praticar qualquer ação que possa ser interpretada como violação de direitos de propriedade intelectual.</ListItem>
            </List>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              6. DAS RESPONSABILIDADES DO USUÁRIO
            </Typography>
            <Typography variant="body1" paragraph>
              6.1. O USUÁRIO se compromete a:
            </Typography>
            <List>
              <ListItem>Fornecer informações verídicas e completas no momento do cadastro;</ListItem>
              <ListItem>Manter suas informações de cadastro atualizadas;</ListItem>
              <ListItem>Utilizar a plataforma de acordo com os Termos e Condições de Uso e com a legislação aplicável;</ListItem>
              <ListItem>Respeitar as condições e normas de utilização de cupons, vouchers e benefícios oferecidos pelos PARCEIROS;</ListItem>
              <ListItem>Informar imediatamente à CONTRATANTE sobre qualquer utilização não autorizada de sua conta ou qualquer outra quebra de segurança;</ListItem>
              <ListItem>Responder por qualquer ato ilícito ou uso indevido da plataforma que venha a causar danos a terceiros, à CONTRATANTE ou ao <strong>{selectedCompany.name}</strong> .</ListItem>
            </List>
            <Typography variant="body1" paragraph>
              6.2. Nesse sentido, é importante mencionar que o <strong>{selectedCompany.name}</strong>  armazena os dados coletados em serviços de nuvem contratados pelo <strong>{selectedCompany.name}</strong> , como Amazon e Google Cloud. Esses serviços não necessariamente estão localizados no Brasil. Caso queira entender melhor sobre eles, acesse:
              <br /><Link href="https://aws.amazon.com" target="_blank" to={""}>Amazon</Link><br />
              <Link href="https://cloud.google.com" target="_blank" to={""}>Google Cloud</Link>.
            </Typography>
            <Typography variant="body1" paragraph>
              6.3. Vale mencionar que todos os dados pessoais coletados através do cadastro são armazenados neste banco de dados na nuvem que possui acesso restrito a pessoas habilitadas, que são obrigadas, por contrato, a manter a confidencialidade das informações e não utilizá-las inadequadamente.
            </Typography>
            <Typography variant="body1" paragraph>
              6.4. Como medida de proteção, as informações de identificação coletadas pelo <strong>{selectedCompany.name}</strong>  passam por processo de criptografia em todas as páginas de coleta de dados e nas áreas seguras da plataforma, onde um email e senha são solicitados. Esse processo é certificado por autoridade certificadora de servidores web, garantindo comunicações e transações seguras para compartilhá-los. Trata-se de medida de segurança que visa mitigar qualquer tipo de incidente de vazamento de dados.
            </Typography>
            <Typography variant="body1" paragraph>
              6.5. Vale lembrar que esses dados são armazenados para que se possa manter uma relação comercial e de prestação de serviço ao USUÁRIO.
            </Typography>
            <Typography variant="body1" paragraph>
              6.6. Além disso, vale ainda dizer: o <strong>{selectedCompany.name}</strong>  somente compartilha os dados coletados com empresas que façam parte de sua rede de PARCEIROS e que, portanto, necessitam de tais dados para o seu regular funcionamento.
            </Typography>
            <Typography variant="body1" paragraph>
              6.7. O <strong>{selectedCompany.name}</strong>  não realiza nenhum compartilhamento de dados, com intuito mercantil, com empresas estrangeiras.
            </Typography>
            <Typography variant="body1" paragraph>
              6.8. Os dados pessoais dos USUÁRIOS da plataforma nunca serão divulgados. Contudo, de maneira desidentificada e desvinculada dos USUÁRIOS, os dados referentes às empresas cadastradas pelo USUÁRIO, podem ser eventualmente utilizados com a finalidade de publicidade da plataforma, gerando exemplos reais do uso da mesma.
            </Typography>

            <Divider />

            <Typography variant="h6" gutterBottom style={{ paddingTop: "2%" }}>
              7. OS SEUS DIREITOS COMO TITULAR DOS DADOS
            </Typography>
            <Typography variant="body1" paragraph>
              7.1. Finalmente, é importante mencionar que você, titular dos dados, tem seus direitos garantidos pelo <strong>{selectedCompany.name}</strong> , como previsto na Lei Geral de Proteção de Dados, como:
            </Typography>
            <List>
              <ListItem>Acesso aos seus dados;</ListItem>
              <ListItem>Correção de dados incompletos, inexatos ou desatualizados;</ListItem>
              <ListItem>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a lei;</ListItem>
              <ListItem>Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional;</ListItem>
              <ListItem>Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas na lei;</ListItem>
              <ListItem>Informação das entidades públicas e privadas com as quais o <strong style={{ paddingLeft: "1%", paddingRight: "1%" }}>{selectedCompany.name}</strong>  realizou uso compartilhado de dados;</ListItem>
              <ListItem>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</ListItem>
              <ListItem>Revogação do consentimento.</ListItem>
            </List>
            <Typography variant="body1" paragraph>
              Vale pontuar que a proteção dos dados comerciais coletados pelo <strong>{selectedCompany.name}</strong>  está melhor especificada nos Termos de Uso, no capítulo denominado DA RELAÇÃO DE CONFIDENCIALIDADE ENTRE <strong>{selectedCompany.name}</strong>  E USUÁRIO.
            </Typography>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper >
    </>
  );
}

export default Terms;