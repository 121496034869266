import {gql} from '@apollo/client';

export const QUERY_GET_GAMEFIC_URL = gql`
  query GetGameficUrl($companyId: ID!) {
    company(companyId: $companyId) {
      __typename
      ... on Company {
        id
        microApps {
          __typename
          gameficUrl
        }
      }
    }

  }
`;

export const QUERY_GET_BENEFICIO_FACIL_URL = gql`
  query GetBeneficioFacilUrl($companyId: ID!) {
    company(companyId: $companyId) {
      __typename
      ... on Company {
        id
        microApps {
          __typename
          beneficioFacilUrl
        }
      }
    }
  }
`;

export const QUERY_GET_INMANO_URL = gql`
  query GetInManoUrl($companyId: ID!) {
    company(companyId: $companyId) {
      __typename
      ... on Company {
        id
        microApps {
          __typename
          inmanoUrl
        }
      }
    }
  }
`;

export const FRAGMENT_PRODUCTS = gql`
  fragment ProductsFields on Product {
    __typename
    id
    imageUrl
    title
    shortDescription
    accessUrl
    detailsUrl
  }
`;


export const QUERY_SUBCATEGORIES_BENEFIT = gql`
  query benefits(
  $companyId: ID!
){
  company(companyId: $companyId) {
    __typename
    ... on Company {
      id
      name
      activeProducts {
        id
        title
        imageUrl
        accessUrl
        detailsUrl
        shortDescription
      }
      productRequests {
        id
        type 
        product {
          id
        }
      }
      productCategories(
        withProductsOnStatus: ACTIVE
      ) {
        id
        name
        subcategories(filter: {
          companyId: $companyId
          withProductsOnStatus: ACTIVE
        }) {
          id
          name
          products(filter: {
            companyId: $companyId
            status: ACTIVE
          }) {
            id
            title
            imageUrl
            accessUrl
            detailsUrl
            shortDescription
          }
        }
      }
    }
  }
}
`