import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import { WORKER_ENABLE_MUTATION as COMPANY_ADMIN_ENABLE_MUTATION } from "./graphql";
import { enableCompanyAdmin, enableCompanyAdminVariables } from "./types/enableCompanyAdmin";
import api from "../../../../../services/api";

interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh: () => void;
}

const EnableModal: React.FC<Props> = ({ isShown, hide, id, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const enable = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await api.put(`/company-admin/${id}/toggle-acess`);

      refresh();
      alert("Acesso habilitado com sucesso!");
      hide();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja habilitar o acesso desse gestor?
      </Typography>
      <br />
      <PrimaryButton
        color="red"
        disabled={isLoading}
        onClick={enable}
      >
        Habilitar acesso
      </PrimaryButton>

      <SecondaryButton
        disabled={isLoading}
        onClick={hide}>
        Voltar
      </SecondaryButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default EnableModal;
