import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../../../../common/components/Input";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import Typography from "../../../../../common/components/Typography";
import {
  createNotifications,
  createNotificationsVariables,
} from "./types/createNotifications";
import {
  MUTATION_CREATE_NOTIFICATIONS,
  MUTATION_UPDATE_NOTIFICATIONS,
  QUERY_NOTIFICATION,
} from "./graphql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Body } from "./styles";
import EditorComponent from "../../../../../common/components/EditorComponent";
import {
  workerNotification,
  workerNotificationVariables,
} from "../Modal/types/workerNotification";
import {
  updateNotifications,
  updateNotificationsVariables,
} from "./types/updateNotifications";
import AuthContext from "../../../../../common/components/hooks/Auth";
import CompanySelectBorder from "../../../../../common/components/CompanySelectBorder";

const schema = yup.object().shape({
  title: yup.string().required(),
  subtitle: yup.string().required(),
  body: yup.string().required(),
});
const NotificationsModal: React.FC<{
  isShown: boolean;
  hide: () => void;
  companyId: string;
  id: string | null | undefined;
}> = ({ isShown, hide, companyId, id }) => {
  // const context = useContext(AuthContext);
  // const [companyId, setCompanyId] = useState<string>(
  //   context.companyAdmin?.company.__typename === "Company"
  //     ? context.companyAdmin.company.id
  //     : "");

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });


  const [notificationsLazyQuery, notificationsQuery] = useLazyQuery<
    workerNotification,
    workerNotificationVariables
  >(QUERY_NOTIFICATION, {
    variables: {
      id: id || "",
    },
    onCompleted: (data) => {
      if (data.workerNotification.__typename !== "WorkerNotification") {
        return;
      }
      reset({
        title: data.workerNotification.title || "",
        subtitle: data.workerNotification.subtitle || "",
        body: data.workerNotification.body || "",
      });
    },
  });

  useEffect(() => {
    reset({
      title: "",
      subtitle: "",
      body: "",
    });

    if (!id) {
      return;
    }

    notificationsLazyQuery({
      variables: { id },
    });
  }, [id, companyId]);

  const [createNotification, mutationCreateNotification] = useMutation<
    createNotifications,
    createNotificationsVariables
  >(MUTATION_CREATE_NOTIFICATIONS, {
    update: (cache) => {
      cache.evict({
        fieldName: "workerNotification",
        args: { companyId },
      });
    },
  });

  const [updateNotifications, mutationUpdateNotifications] = useMutation<
    updateNotifications,
    updateNotificationsVariables
  >(MUTATION_UPDATE_NOTIFICATIONS);

  const onSubmitCreateNotification = async (data: any) => {
    if (mutationCreateNotification.loading) {
      return;
    }

    const result = await createNotification({
      variables: {
        data: {
          companyId: companyId,
          ...data,
        },
      },
    });

    if (result.data?.createWorkerNotification.__typename === "InputError") {
      alert("Houve um erro nos campos. Tente novamente!");
      return;
    }

    if (result.data?.createWorkerNotification.__typename === "NotFoundError") {
      alert("Não foi possível enivar. Tente novamente!");
      return;
    }

    if (
      result.data?.createWorkerNotification.__typename === "UnauthorizedError"
    ) {
      alert("Erro desconhecido. Tente novamente.");
      return;
    }

    if (result.data?.createWorkerNotification.__typename === "TemporaryError") {
      alert(
        "Não foi possível enviar a notificação. Tente novamente mais tarde."
      );
      return;
    }

    alert("Notificação enviada com sucesso!");
    hide();
  };

  const onSubmitUpdate = async (data: any) => {
    if (mutationUpdateNotifications.loading) {
      return;
    }

    const result = await updateNotifications({
      variables: {
        data: {
          title: data.title,
          subtitle: data.subtitle,
          body: data.body,
          id: id || "",
        },
      },
    });

    if (
      result.data?.updateWorkerNotification.__typename === "WorkerNotification"
    ) {
      alert("Notificação atualizada com sucesso!");
      hide();
      return;
    }

    if (result.data?.updateWorkerNotification.__typename === "InputError") {
      alert("Não foi possível atualizar a notificação. Tente novamente!");
      return;
    }

    if (result.data?.updateWorkerNotification.__typename === "NotFoundError") {
      alert("Erro desconhecido. Tente novamente!");
      return;
    }

    alert("Erro nos dados informados. Tente novamente!");
    return;
  };

  const disabled =
    mutationCreateNotification.loading ||
    mutationUpdateNotifications ||
    notificationsQuery.loading;

  const body = (
    <Body>
      <Typography fontSize="22px" fontWeight="500">
        {id ? "Edite a notificação abaixo:" : "Adicionar notificação:"}
      </Typography>
      <form
        onSubmit={handleSubmit(
          id ? onSubmitUpdate : onSubmitCreateNotification
        )}
      >
        <div style={{ marginTop: "33px" }}>
          {!id ? (
            <div style={{ marginBottom: "50px" }}>
              <Controller
                control={control}
                name="companyId"
                defaultValue={companyId || ""}
                render={({ field }) => (
                  <CompanySelectBorder {...field} options={[]} />
                )}
              />
            </div>
          ) : null}
          {id ? (
            <Controller
              control={control}
              name="id"
              defaultValue={id}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={disabled}
                  placeholder=""
                  type="hidden"
                />
              )}
            />
          ) : null}
          <Controller
            control={control}
            name="title"
            defaultValue=""
            render={({ field }) => (
              <Input placeholder="Digite o título da notificação" {...field} />
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.title?.message}
          </Typography>
        </div>
        <div style={{ marginTop: "33px", marginBottom: "15px" }}>
          <Controller
            control={control}
            name="subtitle"
            defaultValue=""
            render={({ field }) => (
              <Input
                placeholder="Digite o sub-título da notificação"
                {...field}
              />
            )}
          />
          <Typography fontSize="13px" fontWeight="300" fontColor="gray">
            Resumo breve que aparecerá na notificação do celular do colaborador
          </Typography>
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.subtitle?.message}
          </Typography>
        </div>
        <div style={{ marginTop: "33px", marginBottom: "15px" }}>
          <Controller
            control={control}
            name="body"
            defaultValue=""
            render={({ field }) => <EditorComponent {...field} />}
          />
          <Typography fontSize="13px" fontWeight="300" fontColor="gray">
            {" "}
            Conteúdo da notificação que aparecerá dentro do aplicativo do
            colaborador
          </Typography>
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.body?.message}
          </Typography>
        </div>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <PrimaryButton type="submit">Enviar</PrimaryButton>
          </Col>
        </Row>
      </form>
    </Body>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default NotificationsModal;
