import { useContext } from "react";
import { BenefitContext } from "../../hooks/Benefit";
import Icon from "../../Icon";
import Loading from "../../Loading";
import { FabBadge, FabButton } from "./styles";

const Fab: React.FC = () => {
  const benefitContext = useContext(BenefitContext);

  return <FabButton
    onClick={() => benefitContext.setIsSidebarOpen(!benefitContext.isSidebarOpen)}
    className={`${!!benefitContext.sidebarBadge && 'shown'}`}
  >
    <Icon name="shopping-cart" color="white" />
    <FabBadge>{benefitContext.sidebarBadge}</FabBadge>
  </FabButton>
}

export default Fab;