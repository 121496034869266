import React, { useEffect, useRef, useState } from 'react';
import { IDropdown } from './interface';
import { DropdownContext } from '../DropdownContext';
import { DropdownWrapper } from './styles';

const Dropdown: React.FC<IDropdown> = ({children}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current?.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [open])

    return(
        <DropdownWrapper ref={ref}>
            <DropdownContext.Provider value={{
                open,
                setOpen
            }}>
            {children}
            </DropdownContext.Provider>
        </DropdownWrapper>
    )
}



export default Dropdown;