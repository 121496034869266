import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;

export const WrapperBtn = styled.div`
  margin-left: 48px;
  @media (max-width: 450px) {
    margin-left: 30px;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
`;
