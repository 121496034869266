import { useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { BenefitFragment } from "../../../../modules/Sections/screens/Benefits/types/BenefitFragment";
import { generateActionUrl, generateActionUrlVariables } from "../../../../modules/Sections/screens/Benefits/types/generateActionUrl";
import { MUTATION_GENERATE_ACTION_URL } from "../../../../modules/Sections/screens/Benefits/graphql";
import AuthContext from "../Auth";
import { DRAFT_COMPANY_BENEFIT_REQUEST, MUTATION_FINISH_COMPANY_BENEFIT_REQUEST, MUTATION_PUT_BENEFIT_INTO_REQUEST_DRAFT, MUTATION_REMOVE_BENEFIT_FROM_REQUEST_DRAFT } from "./graphql";
import { CompanyBenefitRequestFragment } from "./types/CompanyBenefitRequestFragment";
import { draftCompanyBenefitRequest, draftCompanyBenefitRequestVariables } from "./types/draftCompanyBenefitRequest";
import { finishCompanyBenefitRequest, finishCompanyBenefitRequestVariables } from "./types/finishCompanyBenefitRequest";
import { putBenefitIntoRequestDraft, putBenefitIntoRequestDraftVariables } from "./types/putBenefitIntoRequestDraft";
import { removeBenefitFromRequestDraft, removeBenefitFromRequestDraftVariables } from "./types/removeBenefitFromRequestDraft";
import { ActionValueType, UrlWebTarget } from "../../../../types/graphql-global-types";
import { ActionFragment } from "../../../../modules/Sections/screens/Benefits/types/ActionFragment";

type BenefitInfo = Pick<BenefitFragment, 'id' | 'code' | 'title'>;

export type BenefitContextData = {
  run: (action: ActionFragment, benefit: BenefitInfo) => any;
  runningActionId: string|null;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  sidebarBadge: number;
  draftCompanyBenefitRequest: CompanyBenefitRequestFragment | null;
  putBenefitIntoRequestDraft: (benefitId: string) => void;
  removeBenefitFromRequestDraft: (benefitId: string) => void;
  changingDraft: boolean;
  putingBenefitIntoRequestDraft: string | null;
  finishCompanyBenefitRequest: (contact: { name: string, phone: string, email: string, message: string }) => Promise<boolean>;
  finishingRequest: boolean;
}

export const BenefitContext = createContext<BenefitContextData>({} as BenefitContextData);

export const BenefitProvider: React.FC = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarBadge, setSidebarBadge] = useState(0);
  const [putingBenefitIntoRequestDraft, setPutingBenefitIntoRequestDraft] = useState<string | null>(null);
  const context = useContext(AuthContext);
  const [runDraftCompanyBenefitRequest, draftCompanyBenefitRequest] = useLazyQuery<draftCompanyBenefitRequest, draftCompanyBenefitRequestVariables>(DRAFT_COMPANY_BENEFIT_REQUEST, {
    variables: {
      companyId: context.companySelect.selectedValue,
    }
  });
  const [runningActionId, setRunningActionId] = useState<string|null>(null);

  const [generateActionUrl, mutationGenerateActionUrl] = useMutation<
    generateActionUrl,
    generateActionUrlVariables
  >(MUTATION_GENERATE_ACTION_URL, {
    fetchPolicy: "no-cache",
  });

  const [putBenefitIntoRequestDraft, putBenefitIntoRequestDraftMutation] = useMutation<putBenefitIntoRequestDraft, putBenefitIntoRequestDraftVariables>(MUTATION_PUT_BENEFIT_INTO_REQUEST_DRAFT, {
    refetchQueries: [{ query: DRAFT_COMPANY_BENEFIT_REQUEST, variables: { companyId: context.companySelect.selectedValue } }]
  });
  
  const [removeBenefitFromRequestDraft, removeBenefitFromRequestDraftMutation] = useMutation<removeBenefitFromRequestDraft, removeBenefitFromRequestDraftVariables>(MUTATION_REMOVE_BENEFIT_FROM_REQUEST_DRAFT, {
    refetchQueries: [{ query: DRAFT_COMPANY_BENEFIT_REQUEST, variables: { companyId: context.companySelect.selectedValue } }]
  });

  const [finishCompanyBenefitRequest, finishCompanyBenefitRequestMutation] = useMutation<finishCompanyBenefitRequest, finishCompanyBenefitRequestVariables>(MUTATION_FINISH_COMPANY_BENEFIT_REQUEST, {
    refetchQueries: [{ query: DRAFT_COMPANY_BENEFIT_REQUEST, variables: { companyId: context.companySelect.selectedValue } }]
  });

  useEffect(() => {
    if (!context.companySelect.selectedValue) {
      return;
    }
    runDraftCompanyBenefitRequest();
  }, [context.companySelect.selectedValue]);

  useEffect(() => {
    if (!draftCompanyBenefitRequest.data?.draftCompanyBenefitRequest) {
      setSidebarBadge(0);
      setIsSidebarOpen(false);
      return;
    }
    setSidebarBadge(draftCompanyBenefitRequest.data.draftCompanyBenefitRequest?.benefits.length || 0);
  }, [draftCompanyBenefitRequest.data]);

  const onSubmitGenerateUrl = async (actionToken: string) => {
    if (mutationGenerateActionUrl.loading) {
      return;
    }

    const result = await generateActionUrl({
      variables: {
        actionToken,
      },
    });

    return result.data?.generateActionUrl || null;
  };

  const run = async (action: ActionFragment, benefit: BenefitInfo) => {
    const knownTypes = [
      ActionValueType.TOKEN_TO_GENERATE_URL,
      ActionValueType.URL_TO_OPEN,
    ];
    if (runningActionId || !knownTypes.includes(action.valueType)) {
      return;
    }

    setRunningActionId(action.id);

    const url = action.valueType === ActionValueType.TOKEN_TO_GENERATE_URL
      ? await onSubmitGenerateUrl(
          action.value
        )
      : action.value;
    if (!url) {
      setRunningActionId(null);
      return;
    }

    if (
      action.webTarget === UrlWebTarget.IFRAME
    ) {
      // router.push(`/iframe?title=${benefit.title}&url=${encodeURIComponent(url)}`, `/beneficio/${benefit.code}/${urlSlug(action.title)}/${action.id}`);
    } else {
      window.open(url);
    }
    setRunningActionId(null);
  }

  return (
    <BenefitContext.Provider
      value={{
        runningActionId,
        run,
        isSidebarOpen,
        setIsSidebarOpen,
        sidebarBadge,
        draftCompanyBenefitRequest: draftCompanyBenefitRequest.data?.draftCompanyBenefitRequest || null,
        changingDraft: putBenefitIntoRequestDraftMutation.loading || removeBenefitFromRequestDraftMutation.loading || finishCompanyBenefitRequestMutation.loading,
        putBenefitIntoRequestDraft: async (benefitId: string) => {
          setPutingBenefitIntoRequestDraft(benefitId);
          await putBenefitIntoRequestDraft({
            variables: {
              input: {
                companyId: context.companySelect.selectedValue,
                benefitId,
              },
            }
          });
          setPutingBenefitIntoRequestDraft(null);
        },
        removeBenefitFromRequestDraft: (benefitId: string) => {
          removeBenefitFromRequestDraft({
            variables: {
              input: {
                companyId: context.companySelect.selectedValue,
                benefitId,
              },
            }
          })
        },
        putingBenefitIntoRequestDraft,
        finishingRequest: finishCompanyBenefitRequestMutation.loading,
        finishCompanyBenefitRequest: async (contact) => {
          if (!draftCompanyBenefitRequest.data?.draftCompanyBenefitRequest?.id) {
            return false;
          }

          try {
            const result = await finishCompanyBenefitRequest({
              variables: {
                input: {
                  companyBenefitRequestId: draftCompanyBenefitRequest.data.draftCompanyBenefitRequest.id,
                  contactEmail: contact.email,
                  contactName: contact.name,
                  contactPhone: contact.phone,
                  message: contact.message,
                },
              }
            });
  
            return !!result.data?.finishCompanyBenefitRequest;
          } catch (error) {
            return false;
          }
        },
      }}
    >
      {children}
    </BenefitContext.Provider>
  );
};

export default BenefitProvider;
