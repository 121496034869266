import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CompanySelectBorder from "../../../../../common/components/CompanySelectBorder";
import Input from "../../../../../common/components/Input";
import {
  ModalDefault,
  ModalProps,
} from "../../../../../common/components/ModalDefault/Modal";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import Typography from "../../../../../common/components/Typography";
import {
  MUTATION_CREATE,
  MUTATION_UPDATE,
  QUERY_COMPANYADMIN,
} from "./graphql";
import { CompanyAdmin, CompanyAdminVariables } from "./types/CompanyAdmin";
import {
  createCompanyAdminVariables,
  createCompanyAdmin,
} from "./types/createCompanyAdmin";
import {
  UpdateCompanyAdmin,
  UpdateCompanyAdminVariables,
} from "./types/UpdateCompanyAdmin";
import api from "../../../../../services/api";

const schema = yup.object().shape({
  companyId: yup.string().required(),
  firstName: yup.string().required().min(3).max(255),
  lastName: yup.string().required().min(3).max(255),
  email: yup.string().required().email(),
  department: yup.string().required().min(3).max(255),
  role: yup.string().required().min(3).max(255),
});

const ModalCompanyAdmin: React.FC<ModalProps> = ({ isShown, hide, id, companyId, refresh }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const getGestor = async () => {
    try {
      const { data } = await api.get("/company-admin/" + id);

      if (data) {
        reset({
          firstName: data.data.firstName || "",
          lastName: data.data.lastName || "",
          email: data.data.email || "",
          role: data.data.role || "",
          department: data.data.department || "",
          companyId: data.data.companyId || ""   
        });
      }

    } catch (e) {
      console.log(e)
    }
  }
  const onSubmitCreate = async (data: any) => {
    if (isLoading) {
      return;
    }
  
    try {
      setIsLoading(true);
  
      if (!id) {
        await api.post("/company-admin", data);
      } else {
        await api.put("/company-admin/" + id, data);
      }
  
      alert(id
        ? "Gestor atualizado com sucesso!"
        : "Gestor criado com sucesso!");
  
      refresh();
      hide();
      resetForm();
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.message) {
        // Verifica se a mensagem de erro do backend está relacionada ao email repetido
        if (e.response.data.message.includes("email já está em uso")) {
          alert("Este email já está cadastrado. Por favor, use um email diferente.");
        } else {
          alert("Este email já está cadastrado. Por favor, use um email diferente.");
        }
      } else {
        alert("Este email já está cadastrado. Por favor, use um email diferente.");
      }
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const resetForm = (): void => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      department: "",
      companyId: companyId || "",
    });
  }

  useEffect(() => {
    resetForm();

    if (!id) {
      return;
    }

    getGestor();
  }, [id, companyId]);

  const body = (
    <>
      <Typography fontSize="20px" fontWeight="500">
        {id
          ? "Edite os dados do gestor abaixo:"
          : "Adicione um novo Gestor : "}
      </Typography>

      <div style={{ alignItems: "center" }}>
        <form onSubmit={handleSubmit(onSubmitCreate)}>
          <div style={{ marginTop: "33px" }}>
            {id ? null : (
              <Controller
                control={control}
                name="companyId"
                defaultValue={companyId || ""}
                render={({ field }) => (
                  <CompanySelectBorder
                    {...field}
                    disabled={isLoading}
                    options={[]}
                  />
                )}
              />
            )}
            <Typography fontSize="15px" fontWeight="300" fontColor="red">
              {errors.companyId?.message}
            </Typography>
          </div>

          <div style={{ marginTop: "33px" }}>
            <Controller
              control={control}
              name="firstName"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Primeiro nome"
                  disabled={isLoading}
                />
              )}
            />
            <Typography fontSize="15px" fontWeight="300" fontColor="red">
              {errors.firstName?.message}
            </Typography>
          </div>

          <div style={{ marginTop: "33px" }}>
            <Controller
              control={control}
              name="lastName"
              defaultValue=""
              render={({ field }) => (
                <Input {...field} placeholder="Sobrenome" disabled={isLoading} />
              )}
            />
            <Typography fontSize="15px" fontWeight="300" fontColor="red">
              {errors.lastName?.message}
            </Typography>
          </div>

          <div style={{ marginBottom: "30px", marginTop: "38px" }}>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="E-mail institucional"
                  disabled={isLoading}
                />
              )}
            />
            <Typography fontSize="15px" fontWeight="300" fontColor="red">
              {errors.email?.message}
            </Typography>
          </div>

          <div style={{ marginTop: "33px" }}>
            <Controller
              control={control}
              name="department"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Departamento"
                  disabled={isLoading}
                />
              )}
            />
            <Typography fontSize="15px" fontWeight="300" fontColor="red">
              {errors.department?.message}
            </Typography>
          </div>

          <div style={{ marginTop: "33px", marginBottom: "33px" }}>
            <Controller
              control={control}
              name="role"
              defaultValue=""
              render={({ field }) => (
                <Input {...field} placeholder="Cargo" disabled={isLoading} />
              )}
            />
            <Typography fontSize="15px" fontWeight="300" fontColor="red">
              {errors.role?.message}
            </Typography>
          </div>

          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <PrimaryButton
                disabled={isLoading}
                fontSize="15px"
                paddingLeft="70px"
                paddingRight="70px"
                borderRadius="30px"
                onClick={() => { }}
                width="100%"
                type="submit"
              >
                Salvar
              </PrimaryButton>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default ModalCompanyAdmin;
