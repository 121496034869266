import styled from "styled-components";
import { prop } from "styled-tools";

export const SelectStyled = styled.select`
  width: 100%;
  height: 47px;
  border-width: 0 0 3px;
  width: 100%;
  font-weight: 400;
  margin-top: ${prop("marginTop")};
  margin-bottom: ${prop("marginBottom")};
  margin-left: ${prop("marginLeft")};
  margin-right: ${prop("marginRight")};
  outline: none;
  background-color: transparent;
`;

export const Label = styled.label`
  line-height: 10px;
`;
