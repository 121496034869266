import { gql } from "@apollo/client";
import { FRAGMENT_ACTION, FRAGMENT_BENEFIT, FRAGMENT_BENEFIT_CATEGORY, FRAGMENT_BENEFIT_CHECKOUT_CARD, FRAGMENT_BENEFIT_SUBCATEGORY, FRAGMENT_PARTNER } from "../../../../modules/Sections/screens/Benefits/graphql";

export const FRAGMENT_COMPANY_BENEFIT_REQUEST = gql`
  fragment CompanyBenefitRequestFragment on CompanyBenefitRequest {
    __typename
    id
    code
    contactName
    contactEmail
    contactPhone
    message
    status
    benefits {
      ...BenefitFragment
    }
    createdAt
  }
`;

export const DRAFT_COMPANY_BENEFIT_REQUEST = gql`
  query draftCompanyBenefitRequest($companyId: ID!) {
    draftCompanyBenefitRequest(companyId: $companyId) {
      ...CompanyBenefitRequestFragment
    }
  }
  ${FRAGMENT_COMPANY_BENEFIT_REQUEST}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;

export const MUTATION_PUT_BENEFIT_INTO_REQUEST_DRAFT = gql`
  mutation putBenefitIntoRequestDraft($input: PutBenefitIntoRequestDraftInput!) {
    putBenefitIntoRequestDraft(input: $input) {
      ...CompanyBenefitRequestFragment
    }
  }
  ${FRAGMENT_COMPANY_BENEFIT_REQUEST}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;

export const MUTATION_REMOVE_BENEFIT_FROM_REQUEST_DRAFT = gql`
  mutation removeBenefitFromRequestDraft($input: RemoveBenefitFromRequestDraftInput!) {
    removeBenefitFromRequestDraft(input: $input) {
      ...CompanyBenefitRequestFragment
    }
  }
  ${FRAGMENT_COMPANY_BENEFIT_REQUEST}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;

export const MUTATION_FINISH_COMPANY_BENEFIT_REQUEST = gql`
  mutation finishCompanyBenefitRequest($input: FinishCompanyBenefitRequestInput!) {
    finishCompanyBenefitRequest(input: $input) {
      ...CompanyBenefitRequestFragment
    }
  }
  ${FRAGMENT_COMPANY_BENEFIT_REQUEST}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;
