// Components
import { EditorState, convertToRaw } from "draft-js";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { WrapperEditor } from "./styles";

const EditorComponent = (props: any) => {
  console.log(props);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    return props.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  return (
    <>
      <WrapperEditor
        style={{
          maxHeight: "200px",
          overflowY: "scroll",
        }}
      >
        <Editor 
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ["inline", "link", "list", "history",],
            inline: { inDropdown: false, options: ["bold", "italic"] },
            list: { inDropdown: false, 
              options: ['unordered', 'ordered'] },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
        />
      </WrapperEditor>
    </>
  );
};

export default EditorComponent;
