import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'fontsource-roboto';
import Routes from '../src/routes';
import { CustomThemeProvider } from './common/components/hooks/Theme';

import GlobalStyle from '../src/styles/global';
import AuthContext from './common/components/hooks/Auth';
import Loading from './common/components/Loading';

function App() {
  const authContext = useContext(AuthContext);
  if (authContext.loading) {
    return <Loading />;
  }

  return (
    <CustomThemeProvider>
      <GlobalStyle />
      <Routes />
    </CustomThemeProvider>
  );
}

export default App;
