import { Spinner } from "react-bootstrap";
import { WrapperLoading } from "./styles";

const Loading: React.FC<{color?: string}> = ({color}) => {
 return <WrapperLoading>
    <Spinner animation="border" style={{ color }}/>
  </WrapperLoading>;
}

export default Loading;
