import {gql} from '@apollo/client';

export const FRAGMENT_BENEFIT = gql`
  fragment BenefitFragment on Benefit {
    __typename
    id
    code
    logoUrl
    title
    subtitle
    locationLabel
    checkLabel
    shortDescription
    htmlDescription
    partner {
      ...PartnerFragment
    }
    actions {
      ...ActionFragment
    }
    carouselImages
    subcategory {
      ...BenefitSubcategoryFragment
    }
    checkoutCards {
      ...BenefitCheckoutCardFragment
    }
  }
`;

export const FRAGMENT_PARTNER = gql`
  fragment PartnerFragment on Partner {
    __typename
    id
    logoUrl
    name
    shortDescription
    actions {
      ...ActionFragment
    }
  }
`;

export const FRAGMENT_ACTION = gql`
  fragment ActionFragment on Action {
    __typename
    id
    name
    title
    value
    valueType
    webTarget
    knownAction
  }
`;

export const FRAGMENT_BENEFIT_CHECKOUT_CARD = gql`
  fragment BenefitCheckoutCardFragment on BenefitCheckoutCard {
    __typename
    id
    title
    subtitle
    description
    price
    priceUnit
  }
`;

export const FRAGMENT_BENEFIT_CATEGORY = gql`
  fragment BenefitCategoryFragment on BenefitCategory {
    __typename
    id
    name
  }
`;

export const FRAGMENT_BENEFIT_SUBCATEGORY = gql`
  fragment BenefitSubcategoryFragment on BenefitSubcategory {
    __typename
    id
    name
    benefitCategory {
      ...BenefitCategoryFragment
    }
  }
`;

export const FRAGMENT_BENEFIT_COMPANY_CONFIG = gql`
  fragment BenefitCompanyConfigFragment on BenefitCompanyConfig {
    __typename
    id
    visibleToWorkers
    benefit {
      ...BenefitFragment
    }
  }
`;

export const QUERY_BENEFITS = gql`
  query Benefits($companyId: ID!) {
    company(companyId: $companyId) {
      __typename
      ... on Company {
        id
        inactiveBenefitSections {
          __typename
          id
          title
          benefits {
            ...BenefitFragment
          }
        }
        benefitConfigs(filter: { active: true, visibleToCompanyAdmins: true }) {
          ...BenefitCompanyConfigFragment
        }
      }
    }
  }
  ${FRAGMENT_BENEFIT_COMPANY_CONFIG}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;

export const MUTATION_GENERATE_ACTION_URL = gql`
  mutation generateActionUrl($actionToken: String!) {
    generateActionUrl(actionToken: $actionToken)
  }
`;

export const MUTATION_SET_BENEFIT_VISIBILITY = gql`
  mutation setBenefitVisibilityToWorkers($input: BenefitVisibilityToWorkersInput!) {
    setBenefitVisibilityToWorkers(input: $input) {
      ...BenefitCompanyConfigFragment
    }
  }
  ${FRAGMENT_BENEFIT_COMPANY_CONFIG}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;
