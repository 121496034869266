import {gql} from '@apollo/client';


export const MUTATION_CREATE_NOTIFICATIONS = gql`
mutation createNotifications(
 $data: CreateWorkerNotificationInput!
) {
 createWorkerNotification(data: $data) { 
     __typename
   ... on WorkerNotification {
    companyId
     title 
     subtitle
     body
   }
 }
}
`

export const MUTATION_UPDATE_NOTIFICATIONS = gql`
  mutation updateNotifications(
    $data: UpdateWorkerNotificationInput!
  ){
    updateWorkerNotification(data: $data){
      ... on WorkerNotification {
        id
        companyId
        createdAt
        title
        subtitle
        body
      }
      ... on NotFoundError {
        message
      }
      ... on InputError {
        message
      }
      ... on UnauthorizedError {
        message
      }
    }
  }
`

export const QUERY_NOTIFICATION = gql`
  query workerNotification($id: ID!) {
  workerNotification(id: $id) {
    ... on WorkerNotification {
      id
      title
      subtitle
      body
    }
  }
}
`