import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import DangerButton from "../../../../../common/components/DangerButton";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import { WORKER_DISABLE_MUTATION as COMPANY_ADMIN_DISABLE_MUTATION } from "./graphql";
import { disableCompanyAdmin, disableCompanyAdminVariables } from "./types/disableCompanyAdmin";
import api from "../../../../../services/api";

interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh: () => void;
}

const DisableModal: React.FC<Props> = ({ isShown, hide, id, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const enable = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await api.put(`/company-admin/${id}/toggle-acess`);

      refresh();
      alert("Acesso desabilitado com sucesso!");
      hide();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja desabilitar o acesso desse gestor?
      </Typography>
      <br />
      <DangerButton
        color="red"
        disabled={isLoading}
        onClick={enable}
      >
        Desabilitar acesso
      </DangerButton>
      <SecondaryButton disabled={isLoading} onClick={hide}>Voltar</SecondaryButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default DisableModal;
