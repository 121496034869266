import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "styled-components";
import CompanySelect, { InputCompanySelect, OnChangeCompanySelect } from "./CompanySelect";
import GraphicBar from "../../../../common/components/GraphicBar";
import GraphicCards from "../../../../common/components/GraphicCards";
import GraphicMultiLines from "../../../../common/components/GraphicMultiLines";
import GraphicPie from "../../../../common/components/GraphicPie";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import MiniGraphic from "../../../../common/components/MiniGraphicPie";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import YearSelect from "../../../../common/components/YearSelect";
import GraphicDetail from "./GraphicDetails";
import cpfLib from "cpf";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { QUERY_MOUNTLY_COUNT } from "./graphql";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Link,
  WrapperLegends,
  WrapperPie,
  WrapperGraphicBar,
  WrapperSelect,
  SectionWrapper,
  GridWrapper,
  WrapperBack,
  WrapperDash,
  BeneficioContainer,
  BeneficioHeader,
  AvatarContainer,
  BeneficioTitle,
  Wrapper,
  Images,
  WrapperTitle,
  HeaderContainer,
  WrapperTables,
  TableContainer,
  StyledTable,
  TableHeader,
  TableCell
  

} from "./styles";
import { QueryMountlyCount, QueryMountlyCountVariables } from "./types/QueryMountlyCount";
import { Avatar, Box, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import api from "../../../../services/api";
import { PieChartInviteInterface, PieChartWorkerInterface } from "./interface";
import { BeneficConfig } from "../NewBenefits/interfaces";
import { WorkerInvite } from "./interfaceWorker";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';


const Dashboard: React.FC = () => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [colaboradoresDataset, setColaboradoresDataset] = useState<any[]>([]);
  const [pieChartDataset, setPieChartDataset] = useState<PieChartInviteInterface>({
    data: [],
    totalInvites: 0,
    totalInvitesAccepted: 0,
    totalInvitesPendente: 0,
  });
  const [workerDataset, setWorkerDatset] = useState<PieChartWorkerInterface>({
    data: [],
    totalWorker: 0,
    totalWorkerInvites: 0,
    totalWorkerActive: 0,
    totalWorkerInative: 0,
  });
  const themeColor = useContext(ThemeContext);
  const { selectedCompany, ...authContext } = useContext(AuthContext);
  const authCompany = authContext.companyAdmin?.Company ? authContext.companyAdmin.Company : null;
  const currentYear = (new Date()).getFullYear();
  const [year, setYear] = useState(currentYear.toString());
  const [open, setOpen] = useState(false);
  const [childCompanyName, setChildCompanyName] = useState<string | undefined>();
  const [childCompanyId, setChildCompanyId] = useState<string | undefined>();
  const [workers, setWorkers] = useState<WorkerInvite[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // const [beneficios, setBeneficios] = useState<BeneficConfig[]>([]);
  const [beneficios, setBeneficios] = useState<BeneficConfig[]>([]);
  const [indiceAtual, setIndiceAtual] = useState(0);
  const [selectedParentCompany, setSelectedParentCompany] = useState<OnChangeCompanySelect | null>(authCompany ? {
    companyId: selectedCompany?.id || authCompany.id,
    includeChildren: authCompany.hasChildren,
    hasChildren: authCompany.hasChildren,
  } : null);
  const [selectedChildCompany, setSelectedChildCompany] = useState<OnChangeCompanySelect | null>(null);
  const [activeWorkersCount, setActiveWorkersCount] = useState(0);
  const currentCompanyFilter: (InputCompanySelect) | null = selectedChildCompany || selectedParentCompany ? {
    companyId: selectedChildCompany?.companyId || selectedParentCompany?.companyId,
    includeChildren: selectedChildCompany?.includeChildren || selectedParentCompany?.includeChildren,
  } as InputCompanySelect : null;

  useEffect(() => {
    if (!authContext.companySelect.selectedValue) {
      return;
    }
    setSelectedParentCompany({
      companyId: authContext.companySelect.selectedValue,
      includeChildren: true,
      hasChildren: false,
    })
  }, [authContext.companySelect.selectedValue]);

  const currentCompanyMonthly = useQuery<QueryMountlyCount, QueryMountlyCountVariables>(QUERY_MOUNTLY_COUNT, {
    variables: {
      ...currentCompanyFilter,
      year: parseInt(year),
    },
  });

  const [runChildCompanyMonthly, childCompanyMonthly] = useLazyQuery<QueryMountlyCount, QueryMountlyCountVariables>(QUERY_MOUNTLY_COUNT, {
    variables: {
      year: parseInt(year),
      companyId: childCompanyId,
      includeChildren: false,
    }
  });

  const contentRef = useRef(null);
  const generatePDF = async () => {
    const input = contentRef.current;

    try {
      if (!input) {
        throw new Error("Erro ao obter o conteúdo para gerar o PDF.");
      }

      // Garantir que a imagem está carregada
      const images = input.getElementsByTagName('img') as HTMLCollectionOf<HTMLImageElement>;
      const loadPromises = Array.from(images).map((img) => {
        if (!img.complete) {
          console.log(`Imagem não carregada: ${img.src}`);
          return new Promise<void>((resolve, reject) => {
            img.onload = () => {
              console.log(`Imagem carregada: ${img.src}`);
              resolve();
            };
            img.onerror = () => {
              console.error(`Erro ao carregar imagem: ${img.src}`);
              reject(new Error('Erro ao carregar imagem.'));
            };
          });
        }
        console.log(`Imagem já carregada: ${img.src}`);
        return Promise.resolve();
      });

      await Promise.all(loadPromises);

      console.log("Todas as imagens foram carregadas. Gerando PDF...");

      const canvas = await html2canvas(input, {
        scale: 2, // Ajuste opcional de escala
        useCORS: true, // Adiciona esta linha para lidar com imagens de diferentes domínios
        allowTaint: false // Evita erros de segurança CORS
      });

      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save("dashboard.pdf");

      console.log("PDF gerado com sucesso.");
    } catch (error) {
      console.error("Erro ao gerar o PDF:", error);
    }
  };


  const getDataBar = (data: QueryMountlyCount) => {
    const workers = data.workerMonthlyCount || [];
    const invites = data.workerInviteMonthlyCount || [];

    return invites.map((invite) => {
      const worker = workers.find((worker) => worker.month === invite.month);
      const date = new Date(invite.year, invite.month - 1);

      return {
        name: date.toLocaleString("pt-BR", { month: "short" }),
        convites: invite.count,
        colaboradores: worker?.count || 0,
      };
    });
  }

  const dataBar = currentCompanyMonthly.data ? getDataBar(currentCompanyMonthly.data) : null;
  const childDataBar = childCompanyMonthly.data ? getDataBar(childCompanyMonthly.data) : null;

  const percent = (workers: number | null, invites: number) => {
    const result = ((workers || 0) / invites) * 100;
    return Math.round(result);
  };

  const getTotalInvites = async () => {
    try {
      const params = {
        companyId: selectedCompany?.id || authCompany.id,
        year
      }
      const { data } = await api.get("/dashboard/total-invites", { params });

      setColaboradoresDataset(data.totalWorkersAndInvitesAccepted.map((d: any) => ({
        name: d.mes,
        colaboradores: d.totalInvitesAccepted,
        convites: d.totalWorkers,
      })));
    } catch (e) {
      console.log(e)
    }
  }
  const reEnvioPendente = async () => {
    try {
      setIsLoading(true);
      const params = {
        companyId: selectedCompany?.id || authCompany.id,
        year
      };
      const { data } = await api.post("/re-send/pendentes", params);

      if (data.status === "ok") {
        alert(`Convites pendentes reenviados com sucesso! Total reenviado: ${data.reenvioCount}`);
      } else {
        alert("Erro ao reenviar convites pendentes.");
      }
    } catch (e) {
      console.log(e);
      alert("Erro ao reenviar convites pendentes.");
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalInvitesAccepted = async () => {
    try {
      const params = {
        companyId: selectedCompany?.id || authCompany.id,
        year
      }
      const { data } = await api.get("/dashboard/total-invites-accepted", { params });

      setPieChartDataset({
        data: [
          { name: "convites", value: data.totalInvites },
          { name: "convertidos", value: data.totalInvitesAccepted },
          { name: "pendentes", value: data.totalInvitesPendente },


        ],
        totalInvites: data.totalInvites,
        totalInvitesPendente: data.totalInvitesPendente,
        totalInvitesAccepted: data.totalInvitesAccepted
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getTotalWorkerActive = async () => {
    try {
      const params = {
        companyId: selectedCompany?.id || authCompany.id,
        year
      }
      const { data } = await api.get("/dashboard/total-worker-active", { params });

      setWorkerDatset({
        data: [
          { name: "worker", value: data.totalWorker },
          { name: "invitesWorker", value: data.totalWorkerInvites },
          { name: "ativos", value: data.totalWorkerActive },
          { name: "inativos", value: data.totalWorkerInative },

        ],
        totalWorker: data.totalWorker,
        totalWorkerInvites: data.totalWorkerInvites,
        totalWorkerActive: data.totalWorkerActive,
        totalWorkerInative: data.totalWorkerInative
      })
    } catch (e) {
      console.log(e)
    }
  }
  const getBeneficios = async () => {
    try {
      const params = { companyId: selectedCompany?.id || authCompany.id, };
      const { data } = await api.get('/benefit-config', { params });
      setBeneficios(data.data);
    } catch (e) {
      console.log(e);
      setError('Erro ao obter os benefícios da empresa.');
    } finally {
      setLoading(false);
    }
  };
  const getWorkers = async () => {
    setIsLoading(true);

    try {
      const params = {
        companyId: selectedCompany?.id || authCompany.id,
      };

      const { data } = await api.get("/dashboard/worker-accepted-data", { params });
      console.log('Dados recebidos:', data.workerDataAccept); // Verifique os dados recebidos
      setWorkers(data.workerDataAccept);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getBeneficios();
    getWorkers()
  }, [selectedParentCompany]);

  const getData = async () => {
    setIsLoading(true);

    await Promise.all([
      getTotalInvites(),
      getTotalInvitesAccepted(),

      getTotalWorkerActive()
    ]);

    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [year]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndiceAtual((prevIndice) => (prevIndice + 1) % beneficios.length);
    }, 3000); // Muda o benefício a cada 3 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
  }, [beneficios]);

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  const beneficioAtual = beneficios[indiceAtual];

  const body = (
    <>
      <Row>
        <Col md={4}>
          <Typography fontSize="18px" fontWeight="500" marginBottom="12px">
            Usuários e convites
          </Typography>
        </Col>
        <Col md={3}>
          <YearSelect
            startYear={2021}
            endYear={currentYear}
            value={year}
            onChange={(event) => {
              setYear(event.target.value);
            }}
          />
        </Col>
        <Col md={5} style={{ textAlign: 'center' }}>
          <Typography fontSize="18px" fontWeight="500" marginBottom="12px" textAlign="center">
            Total acumulado
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          {!isLoading ? (
            <GraphicBar
              data={colaboradoresDataset}
              dataKeyPrimary="convites"
              dataKeySecondary="colaboradores"
              color={themeColor.colors.primary}
              height={300}
            />
          ) : <div style={{ margin: '100px 0' }}><Loading /></div>}
        </Col>

        <Col md={5}>
          {!isLoading ? (
            <WrapperPie>
              <WrapperGraphicBar>
                <GraphicPie data={pieChartDataset.data} color={themeColor.colors.primary} />
              </WrapperGraphicBar>
              <WrapperLegends>
                <Typography fontSize="13px" fontWeight="500">
                  <span style={{ color: themeColor.colors.primary }}>
                    {percent(pieChartDataset.totalInvitesAccepted, pieChartDataset.totalInvites) || 0}%
                  </span>{" "}
                  dos colaboradores foram convertidos.
                </Typography>
              </WrapperLegends>
            </WrapperPie>
          ) : <div style={{ margin: '100px 0' }}><Loading /></div>}
        </Col>
      </Row>

      {authContext.companySelect.options.some(option => option.value === childCompanyId) ? (
        <Row>
          <Col md={4} />
          <Col md={4}>
            <PrimaryButton
              marginTop="40px"
              fontSize="16px"
              onClick={() => {
                authContext.selectCompanyId(childCompanyId);
                push(`/convites?status=pendentes`);
              }}
            >
              Convites pendentes
            </PrimaryButton>
          </Col>
        </Row>
      ) : null}
    </>
  );

  return (
    <>
      <SectionWrapper ref={contentRef}>
        <GridWrapper>
          <WrapperBack style={{backgroundColor: themeColor.colors.primary}} >

            <GridWrapper style={{backgroundColor: themeColor.colors.primary}}>
              <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "1%", marginRight: "2%", width: "100%" }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <img width="200px" src={selectedCompany.logoUrl || selectedCompany.parent?.logoUrl} />
                </div>
      
                <GraphicDetail
                  maxWidth="90%"
                  minWidth="90%"
                  minHeight="80%"
                  maxHeight="80%"
                  isShown={open}
                  hide={() => setOpen(false)}
                  headerText={childCompanyName}
                >
                  {body}
                </GraphicDetail>

                <WrapperSelect>
                  <YearSelect
                    startYear={2021}
                    endYear={currentYear}
                    value={year}
                    onChange={(event) => {
                      setYear(event.target.value);
                    }}
                  />
                </WrapperSelect>
              </div>
              <WrapperDash>
                <GraphicBar
                  data={colaboradoresDataset}
                  dataKeyPrimary="convites"
                  dataKeySecondary="colaboradores"
                  color={themeColor.colors.primary}
                  height={340}
                />

              </WrapperDash>
            </GridWrapper>
            <GridWrapper>
              <Wrapper>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
                  <Box style={{ marginTop: "8%", marginLeft: "5%", width: "50px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <Person2OutlinedIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "6%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                    <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Usuários Ativos
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                  <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {workerDataset.totalWorkerActive}
                  </h1>
                </div>
              </Wrapper>
              <Wrapper>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
              <Box style={{ marginTop: "8%", marginLeft: "5%", width: "50px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <NoAccountsIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "6%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                  <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Usuários Inativos
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {workerDataset.totalWorkerInative}
                  </h1>
                </div>
              </Wrapper>
              <Wrapper>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
              <Box style={{ marginTop: "8%", marginLeft: "5%", width: "60px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <ForwardToInboxOutlinedIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "4%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                  <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Convites Pendentes no Ano
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                  <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {pieChartDataset.totalInvitesPendente}
                  </h1>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "4%", marginLeft: "50%" }}>


                  <Typography fontSize="12px" fontWeight="500" fontColor={themeColor.colors.primary}>
                    <Link
                      onClick={() => {
                        push(`/convites?status=pendentes`);
                      }}
                    >
                      Ver lista de convites pendentes
                    </Link>
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "2%", marginRight: "3%" }}>
                  <Button style={{
                    color: themeColor.colors.primaryContrast, backgroundColor: themeColor.colors.primary, borderRadius: "30px", border: `solid 2px ${themeColor.colors.primary}`, fontSize: '10px',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                    onClick={reEnvioPendente}
                    disabled={isLoading}>
                    {isLoading ? 'Reenviando...' : 'Reenviar Convites'}
                  </Button>
                </div>
              </Wrapper>
              <Wrapper>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
              <Box style={{ marginTop: "8%", marginLeft: "5%", width: "50px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <MarkEmailReadOutlinedIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "6%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                  <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Convites Aceitos no Ano
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                  <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {pieChartDataset.totalInvitesAccepted}
                  </h1>
                </div>
              </Wrapper>
              {beneficioAtual && (
                <Wrapper key={beneficioAtual.id}>
                  <BeneficioHeader>
                    <Typography fontSize="18px" fontWeight="500" >
                      Beneficios Ativos
                    </Typography>
                  </BeneficioHeader>
                  <AvatarContainer>
                    <Images
                      style={{
                        border: "1px solid #e0e0e0",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                      }}
                      src={beneficioAtual.Benefit.logoUrl}
                    />
                  </AvatarContainer>
                  <BeneficioTitle>
                    <h1 style={{ fontSize: '16px', marginTop: "20%", fontWeight: 700, lineHeight: '24px', textAlign: 'center', color: themeColor.colors.primary }}>
                      {beneficioAtual.Benefit.title}
                    </h1>
                  </BeneficioTitle>
                </Wrapper>
              )}
              <Wrapper>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
              <Box style={{ marginTop: "8%", marginLeft: "5%", width: "50px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <TrendingUpIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "1%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                  <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Total de Usuários Convidados
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                  <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {workerDataset.totalWorkerInvites}
                  </h1>
                </div>
              </Wrapper>
              <Wrapper>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
              <Box style={{ marginTop: "8%", marginLeft: "5%", width: "50px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <DataSaverOffIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "6%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                  <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Total Convites no Ano
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                  <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {pieChartDataset.totalInvites}
                  </h1>
                </div>
              </Wrapper>
              <Wrapper>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%", marginBottom: "5%" }}>
              <Box style={{ marginTop: "8%", marginLeft: "5%", width: "50px", height: "50px", borderRadius: "8px", border: `1px solid ${themeColor.colors.primary}`, display: "flex" }}>
                    <BarChartIcon style={{ width: "40px", height: "40px", color: themeColor.colors.primary, marginLeft: "6%", marginTop: "3%",  }} />
                  </Box>
                  <div style={{ display: "flex", justifyContent: "end", paddingTop: "8%", marginLeft:"7%" }}>
                    <Typography fontSize="17px" fontWeight="500" fontColor={themeColor.colors.primary}>
                      Usuários Total
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", marginTop: "8%", marginRight: "8%" }}>
                <h1 style={{ fontSize: '36px', fontWeight: 700, lineHeight: '42px', textAlign: 'center', color: themeColor.colors.primary }}>
                    {workerDataset.totalWorker}
                  </h1>
                </div>
              </Wrapper>
            </GridWrapper>
            

          </WrapperBack >
          <WrapperTables >
      <HeaderContainer>
        <Typography
          fontSize="18px"
          fontWeight="500"
        >
          Novos Usuários
        </Typography>
        <Button
          style={{
            color: themeColor.colors.primaryContrast,
            backgroundColor: themeColor.colors.primary,
            borderRadius: "30px",
            border: `solid 2px ${themeColor.colors.primary}`,
            fontSize: '10px',
            fontWeight: 500,
            textAlign: 'center',
          }}
          onClick={generatePDF}
        >
          Gerar Relátorio
        </Button>
      </HeaderContainer>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr >
              <TableHeader style={{backgroundColor: themeColor.colors.primary}}>E-mail</TableHeader>
              <TableHeader style={{backgroundColor: themeColor.colors.primary}}>Data de Envio do Convite</TableHeader>
              <TableHeader style={{backgroundColor: themeColor.colors.primary}}>Data de Aceite do Convite</TableHeader>
            </tr>
          </thead>
          <tbody>
            {!isLoading && workers.map((item) => (
              <tr key={item.id}>
                <TableCell >{item.workerEmail ? item.workerEmail : "-"}</TableCell>
                <TableCell >{item.emailSentAt ? new Date(item.emailSentAt).toLocaleDateString() : "-"}</TableCell>
                <TableCell >{item.acceptedAt ? new Date(item.acceptedAt).toLocaleDateString() : "-"}</TableCell>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
    </WrapperTables>
        </GridWrapper>
      </SectionWrapper>
    </>
  );
};

export default Dashboard;


