import React, { useContext } from "react";
import { NavButton, IconContainer, Span } from "./styles";
import { INavBarItem } from "./interface";
import Icon from "../Icon";
import { ThemeContext } from "styled-components";

const NavBarItem: React.FC<INavBarItem> = ({
  children,
  iconName,
  ...props
}) => {
  const context = useContext(ThemeContext);
  return (
    <NavButton {...props}>
      <IconContainer>
        <Icon
          name={iconName}
          color={
            props.active ? context.colors.primary : context.colors.primaryContrast
          }
        />
      </IconContainer>

      <Span>{props.compact ? null : children}</Span>
    </NavButton>
  );
};

export default NavBarItem;
