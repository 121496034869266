import React, { useState, ChangeEvent, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar } from '@mui/material';
import "./avatarStyle.css";

interface AvatarUploadProps {
  src: string;
  onChangeImage: (file: File) => void;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ src, onChangeImage }) => {
  const [previewImage, setPreviewImage] = useState(src);

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const isValid = await verificaImage(e.target.files[0]);
      if (!isValid) {
        setPreviewImage(null)
        return; 
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target?.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
      onChangeImage(e.target.files[0]);
    }
  };

  const verificaImage = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width < 500 || height < 500) {
            window.alert("As imagens devem ser pelo menos 500x500");
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
    });
  };
  useEffect(() => {
    setPreviewImage(src);
  }, [src]);

  return (
    <div className="container">
      <div className="avatar-upload">
        <div className="avatar-edit">
          <input
            type="file"
            id="imageUpload"
            accept=".png, .jpg, .jpeg"
            name="avatar"
            className="alert alert-danger"
            onChange={handleImageChange}
          />
          <label htmlFor="imageUpload">
            <EditIcon />
          </label>
        </div>
        <div className="avatar-preview container2">
          <div className="imagePreview" style={{ backgroundImage: `url(${previewImage})` }}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarUpload;
