import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  PRODUCT_REQUEST_CREATE_MUTATION,
} from "./graphql";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import Typography from "../../../../../common/components/Typography";
import Input from "../../../../../common/components/Input";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import ReactInputMask from "react-input-mask";
import { productRequestCreate, productRequestCreateVariables } from "./types/productRequestCreate";
import { useContext } from "react";
import AuthContext from "../../../../../common/components/hooks/Auth";
import { Col } from "react-bootstrap";

const schema = yup.object().shape({
  firstName: yup.string().required().min(3).max(255),
  lastName: yup.string().required().min(3).max(255),
  email: yup.string().required().email(),
  phone: yup.string().min(3).max(255),
  message: yup.string().min(3).max(255),
});

const StoreModal: React.FC<{
  companyId?: string | null;
  productId?: string | null;
  isShown: boolean;
  hide: () => any;
}> = ({ isShown, hide, companyId, productId }) => {
  const authContext = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [productRequestCreate, productRequestCreateMutation] = useMutation<
    productRequestCreate,
    productRequestCreateVariables
  >(PRODUCT_REQUEST_CREATE_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: `Company:${companyId}`,
        fieldName: "productRequests",
      });
    }
  });

  const onSubmit = async (data: any) => {
    if (productRequestCreateMutation.loading) {
      return;
    }
    const result = await productRequestCreate({
      variables: {
        companyId: companyId || "",
        productId: productId || "",
        contact: data,
      },
    });

    if (result.data?.productRequestCreate.__typename !== "ProductRequest") {
      alert("Erro ao registrar a solicitação. Tente novamente...");
      return;
    }

    alert("Solicitação registrada com sucesso!");
    hide();
  };

  const disabled = productRequestCreateMutation.loading;

  const body = (
    <>
      <Typography fontSize="20px" fontWeight="500">
        Com quem devemos entrar em contato para iniciar a negociação?
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="firstName"
            defaultValue={authContext.companyAdmin?.firstName || ""}
            render={({ field }) => <Input onChange={field.onChange} onBlur={field.onBlur} value={field.value}  placeholder="Nome" disabled={disabled} />}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.firstName?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="lastName"
            defaultValue={authContext.companyAdmin?.lastName || ""}
            render={({ field }) => <Input onChange={field.onChange} onBlur={field.onBlur} value={field.value}  placeholder="Sobrenome" disabled={disabled} />}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.lastName?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="email"
            defaultValue={authContext.companyAdmin?.email || ""}
            render={({ field }) => <Input onChange={field.onChange} onBlur={field.onBlur} value={field.value}  placeholder="Email" disabled={disabled} />}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.email?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            render={({ field }) => (
              <ReactInputMask
                {...field}
                mask="(99) 9 9999-9999"
                disabled={disabled}
              >
                {(inputProps : any) =>
                  <Input
                    {...inputProps}
                    placeholder="Telefone"
                  />
                }
              </ReactInputMask>
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.phone?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="message"
            defaultValue=""
            render={({ field }) => <Input onChange={field.onChange} onBlur={field.onBlur} value={field.value}  placeholder="Mensagem" disabled={disabled} />}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.message?.message}
          </Typography>
        </div>
        <br />
        <Col md={{ span: 7, offset: 3 }}>
        <PrimaryButton
          disabled={disabled}
          fontSize="15px"
          paddingLeft="70px"
          paddingRight="70px"
          borderRadius="30px"
          onClick={() => {}}
          width="80%"
          type="submit"
        >
          Solicitar
        </PrimaryButton>
        </Col>
      </form>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default StoreModal;
