import React, { useContext } from 'react';
//import { } from './interface';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import AuthContext from '../common/components/hooks/Auth';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const context = useContext(AuthContext);

  if (!context.authenticated) {
    return <Redirect to="/login" />
  }

  return <Route {...props} />
};

export default PrivateRoute;
