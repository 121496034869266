import React, { memo } from "react";
import { IInput } from "./interface";
import { InputDefault, Label } from "./styles";

const Input: React.FC<IInput> = (props) => {
  return (
    <div>
      <Label>{props.label}</Label>
      <InputDefault
        {...props.register}
        {...props}
      />
    </div>
  );
};

export default memo(Input);
