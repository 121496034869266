import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import "./bannerStyle.css";
import { ThemeContext } from 'styled-components';

interface BannerUploadProps {
  images: string[];
  onChangeImage: (file: File) => void;
}

const BannerUpload: React.FC<BannerUploadProps> = ({ images, onChangeImage }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [activeIndicator, setActiveIndicator] = useState<number>(0);
  const theme = useContext(ThemeContext);
  useEffect(() => {
    if (images.length > 0) {
      setPreviewImage(images[currentImageIndex]);
      setActiveIndicator(currentImageIndex);
    } else {
      setPreviewImage('');
      setActiveIndicator(0);
    }
  }, [images, currentImageIndex]);

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const isValid = await verificaImage(e.target.files[0]);
      if (!isValid) {
        setPreviewImage(null)
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target?.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
      onChangeImage(e.target.files[0]);
    }
  };

  const verificaImage = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width < 1128 || height < 286) {
            window.alert("As imagens devem ser pelo menos 1128 × 286");
            resolve(false);
          } else {
            resolve(true);
          }
        };
      };
    });
  };

  const handleIndicatorClick = (index: number) => {
    setCurrentImageIndex(index);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="banner-container">
      <div className="banner-upload">
        <div className="banner-edit">
          <input
            type="file"
            id="bannerUpload"
            accept=".png, .jpg, .jpeg"
            name="banner"
            className="input-file"
            onChange={handleImageChange}
          />
          <label htmlFor="bannerUpload">
            <IconButton component="span">
              <EditIcon
                style={{
                  color: "#000",

                }} />
            </IconButton>
          </label>
        </div>
        <div className="banner-preview">
          {previewImage && (
            <div className="image-preview" style={{ backgroundImage: `url(${previewImage})` }}></div>
          )}
          {images.length > 1 && (
            <div className="carousel-indicators">
              {images.map((image, index) => (
                <span
                  key={index}
                  className={`indicator ${index === activeIndicator ? 'active' : ''}`}
                  onClick={() => handleIndicatorClick(index)}
                ></span>
              ))}
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {images.length > 1 && (
            <div className="carousel-controls">
              <IconButton onClick={handlePrevImage}>
                {/* Ícone para retroceder */}
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              </IconButton>
              <IconButton onClick={handleNextImage}>
                {/* Ícone para avançar */}
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24">
                  <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z" />
                </svg>
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerUpload;