import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import { BeneficConfig } from "./interfaces";
import { BenefitCategory, BenefitSubcategory, Partner } from "./interfacess";
import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { ThemeContext } from "styled-components";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";
import ProdutoImage from "./ProdutoImage";
import AuthContext from "../../../../common/components/hooks/Auth";


interface Errors {
  [field: string]: string;
}

const initialState = {
  produto: "",
  sku: "",
  preco: "",
  estoque: "",
  pagamento: "",
  categoria: "",
  shortdescription: "",



}

const NewProductShop: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedCompany, ...context } = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const [benefit, setBenefit] = useState(initialState);
  const [benefitLogo, setBenefitLogo] = useState<File>(null);
  const handleChangeBenefitData = (obj: Object): void => {
    setBenefit(prev => ({
      ...prev,
      ...obj
    }));
  }

  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  }




  const validateData = () => {
    let hasError = false;

    setErrorMessage({});

    if (benefit.produto.trim() === "") {
      setErrorMessage({ produto: "Campo obrigatório!" });
      hasError = true;
    }
    if (benefit.sku.trim() === "") {
      setErrorMessage({ sku: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.preco.trim() === "") {
      setErrorMessage({ preco: "Campo obrigatório!" });
      hasError = true;
    }
    if (benefit.estoque.trim() === "") {
      setErrorMessage({ estoque: "Campo obrigatório!" });
      hasError = true;
    }
    if (benefit.pagamento.trim() === "") {
      setErrorMessage({ pagamento: "Campo obrigatório!" });
      hasError = true;
    }
    if (benefit.categoria.trim() === "") {
      setErrorMessage({ categoria: "Campo obrigatório!" });
      hasError = true;
    }
    if (benefit.shortdescription.trim() === "") {
      setErrorMessage({ shortdescription: "Campo obrigatório!" });
      hasError = true;
    }
    return hasError;
  }

  const postBenefit = async () => {
    if (validateData()) return;

    try {
      const formData = new FormData();

      formData.append("produto", benefit.produto);
      formData.append("sku", benefit.sku);
      formData.append("preco", benefit.preco);
      formData.append("estoque", benefit.estoque);
      formData.append("pagamento", benefit.pagamento);
      formData.append("categoria", benefit.categoria);
      formData.append("companyId", context.companySelect.selectedValue);
      formData.append("shortdescription", benefit.shortdescription);

      if (benefitLogo) {
        formData.append("file", benefitLogo);
      }

      await api.post("/shop", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      resetForm();
      alert("Produto criado com sucesso!");
      history.push("/disponibilizar/produto");

    } catch (e) {
      console.log(e);
    }
  }

  const resetForm = (): void => {
    setBenefit(initialState);
    setBenefitLogo(null);
  }
  useEffect(() => {
    setLoading(false); // Set loading to false once component is fully loaded
  }, []);

  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>

        <GridWrapper>
          <WrapperBack>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "2%", marginBottom: "5%", paddingRight: "2%", paddingLeft: "2%" }}>
              <div>
                <PageTitle
                  title="Criar Produto"
                  subtitle="Aqui você pode criar um novo produto para sua loja."
                />
              </div>
              <Stack direction="row" spacing={1}>
                <Chip label="Lojinha"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "12px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                  onClick={() => history.push(`/disponibilizar/produto`)}
                />
                <Chip label="Produtos Disponiveis"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "12px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                  onClick={() => history.push(`/disponivel/lojinha`)}
                />

              </Stack>
            </div>

            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item xs={12} md={4} style={{ marginTop: "-3%" }}>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <ProdutoImage onChangeImage={setBenefitLogo} src="" />
                </div>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="produto"
                      name="produto"
                      fullWidth
                      autoFocus
                      label="Produto"
                      autoComplete="produto"
                      onChange={(e) =>
                        handleChangeBenefitData({ produto: e.target.value })
                      }
                      value={benefit.produto}
                      size="small"
                    />
                    {formErrors.produto && (
                      <S.ErrorMessage>
                        {formErrors.produto}
                      </S.ErrorMessage>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="sku"
                      name="sku"
                      fullWidth
                      label="Codigo SKU"
                      autoComplete="sku"
                      onChange={(e) =>
                        handleChangeBenefitData({ sku: e.target.value })
                      }
                      value={benefit.sku}
                      size="small"
                    />
                    {formErrors.sku && (
                      <S.ErrorMessage>
                        {formErrors.sku}
                      </S.ErrorMessage>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="preco"
                      name="preco"
                      label="Valor do Produto"
                      fullWidth
                      autoComplete="Valor"
                      size="small"
                      value={benefit.preco}
                      onChange={e => handleChangeBenefitData({ preco: e.target.value.replace(/[^0-9.,]/g, '') })}
                      inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      label="Quantidade em Estoque"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ estoque: e.target.value })
                      }
                      value={benefit.estoque}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="categoria"
                      fullWidth
                      select
                      name="categoria"
                      label="Categoria"
                      autoComplete="pagamento"
                      onChange={(e) =>
                        handleChangeBenefitData({ categoria: e.target.value })
                      }
                      value={benefit.categoria}
                      size="small"
                    >
                      <MenuItem value="Academia">Academia</MenuItem>
                      <MenuItem value="Saude">Saude</MenuItem>
                      <MenuItem value="Comida">Comida</MenuItem>
                      <MenuItem value="Bebidas">Bebidas</MenuItem>
                      <MenuItem value="Club">Club</MenuItem>
                      <MenuItem value="Cursos">Cursos</MenuItem>
                      <MenuItem value="Marketing">Marketing</MenuItem>
                      <MenuItem value="Outros">Outros</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="pagamento"
                      fullWidth
                      select
                      name="pagamento"
                      label="Forma de Pagamento"
                      autoComplete="pagamento"
                      onChange={(e) =>
                        handleChangeBenefitData({ pagamento: e.target.value })
                      }
                      value={benefit.pagamento}
                      size="small"
                    >
                      <MenuItem value="Desconto em Folha">Desconto em Folha</MenuItem>
                      <MenuItem disabled value="Dinheiro">Dinheiro</MenuItem>
                      <MenuItem disabled value="People Coins">People Coins</MenuItem>
                    </TextField>
                  </Grid>


                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      id="shortdescription"
                      fullWidth
                      name="shortdescription"
                      multiline
                      rows={4}
                      label="Descrição"
                      autoComplete="municipio"
                      onChange={(e) =>
                        handleChangeBenefitData({ shortdescription: e.target.value })
                      }
                      value={benefit.shortdescription}
                      size="small"
                    />
                    {formErrors.shortdescription && (
                      <S.ErrorMessage>
                        {formErrors.shortdescription}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ display: "flex", padding: "1.5rem" }}>
              <Chip label="Adicionar Produto"
                style={{
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.primaryContrast,
                  fontSize: "12px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                }}
                onClick={postBenefit}
              />
            </div>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

    </>
  );

  return (
    <>

      {content}
    </>
  );
};

export default NewProductShop;
