import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";

import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { ThemeContext } from "styled-components";
import BenefitBannerUpload from "./BenefitBannerUpload";
import { Beneficios } from "./interfaces";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";


interface Errors {
  [field: string]: string;
}

const initialState = {
  orderIndex: null,
  benefitId: "",

}

const NewBenefitsBanner: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [beneficios, setBeneficios] = useState<Beneficios[]>([]);
  const theme = useContext(ThemeContext);
  const [banner, setBanner] = useState(initialState);

  const [benefitLogo, setBenefitLogo] = useState<File>(null);

  const handleChangeBenefitData = (obj: Object): void => {
    setBanner(prev => ({
      ...prev,
      ...obj
    }));
  }


  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  }
  const getBeneficios = async () => {
    try {

      const { data } = await api.get("/benefits");

      setBeneficios(data);
    } catch (e) {
      console.log(e)

    } finally {
      setLoading(false);
    }
  }

  const validateData = () => {
    let hasError = false;

    setErrorMessage({});

    if (!Number.isInteger(banner.orderIndex)) {
      hasError = true;
    }
    if (banner.benefitId.trim() === "") {
      setErrorMessage({ benefitId: "Campo obrigatório!" });
      hasError = true;
    }

    return hasError;
  }


  const postBenefit = async () => {
    if (validateData()) return;

    try {
      const formData = new FormData();

      formData.append("orderIndex", String(banner.orderIndex));
      formData.append("benefitId", banner.benefitId);;

      if (benefitLogo) {
        formData.append("file", benefitLogo);
      }

      await api.post("/banner", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      resetForm();
      alert("Banner criado com sucesso!");
      history.push("/beneficios");

    } catch (e) {
      console.log(e);
    }
  }

  const resetForm = (): void => {
    setBanner(initialState);
    setBenefitLogo(null);
  }

  useEffect(() => {
    getBeneficios();

  }, []);


  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>
        <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
          <PageTitle
            title="Adicionar Banner"
            subtitle="Aqui você pode adicionar o Banner do Benefício."
          />

        </div>

        <GridWrapper>
          <WrapperBack>
            <div style={{ width: "95%", display: "flex", justifyContent: "flex-end", marginTop: "1%", marginBottom: "2%", }}>
              <Stack direction="row" spacing={1}>
                <Chip label="Novo Beneficio" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push(`/novos-beneficios`)} />
                <Chip label="Novo Parceiro"
                  style={{
                    backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",

                  }}
                  onClick={() => history.push('/novos-parceiros')}
                />
              </Stack>
            </div>
            <Grid
              container
              spacing={2}

            >
              <Grid item xs={12}>
                <BenefitBannerUpload
                  onChangeImage={setBenefitLogo}
                  src={null}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ marginLeft: "16%" }} >
                <TextField
                  variant="outlined"
                  id="benefit"
                  select
                  name="benefit"
                  autoFocus
                  label="Benefícios"
                  fullWidth
                  autoComplete="Benefícios"
                  className="inputdados"
                  size="small"
                  value={banner.benefitId}
                  onChange={e => handleChangeBenefitData({ benefitId: e.target.value })}
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 320, // Defina a altura máxima desejada para o menu
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null, // Garante que o menu comece no ponto de âncora definido
                    },
                  }}
                >
                  {beneficios.map((beneficio) => (
                    <MenuItem key={beneficio.id} value={beneficio.id}>
                      {beneficio.title}
                    </MenuItem>
                  ))}
                </TextField>
                {formErrors.partnerId && (
                  <S.ErrorMessage>{formErrors.partnerId}</S.ErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  id="orderIndex"
                  name="orderIndex"
                  fullWidth
                  autoFocus
                  label="Ordem"
                  autoComplete="Ordem"
                  type="number" // Adicione isso para garantir que o input aceite apenas números
                  onChange={(e) =>
                    handleChangeBenefitData({ orderIndex: parseInt(e.target.value, 10) })
                  }
                  value={banner.orderIndex || ""}
                  size="small"
                  error={!!formErrors.orderIndex}
                  helperText={formErrors.orderIndex}
                />
                {formErrors.title && (
                  <S.ErrorMessage>{formErrors.title}</S.ErrorMessage>
                )}
              </Grid>
            </Grid>
            <div style={{ padding: "1rem" }}>
              <Chip label="Adicionar Banner"
                style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                }}
                onClick={postBenefit}
              />
            </div>
          </WrapperBack>

        </GridWrapper>
      </SectionWrapper >

    </>
  );

  return (
    <>

      {content}
    </>
  );
};

export default NewBenefitsBanner;
