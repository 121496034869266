import React, { useContext, useEffect, useState } from "react";
import { Panel } from "../../../../common/components/Panel";
import PageTitle from "../../../../common/components/PageTitle";
import { Wrapper, ButtonWrapper, Button, SectionWrapper, GridWrapper, WrapperBack, TitleBox } from "./styles";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import AuthContext from "../../../../common/components/hooks/Auth";
import api from "../../../../services/api";
import { ThemeContext } from "styled-components";
import * as yup from "yup";
import { validate as validateCnpj, } from "cnpj";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as S from "./styles";
import ReactInputMask from "react-input-mask";
import { Col, Row } from "react-bootstrap";
import { Box, Grid, TextField, useMediaQuery } from "@material-ui/core";
import Typography from "../../../../common/components/Typography";
import Input from "../../../../common/components/Input";
import { useHistory } from "react-router-dom";
import { Background, Image } from "../../../../common/components";
import logo from "../../../../assets/img/logoPreto.png";
import nova from "../../../../assets/img/background.png";
import { Avatar } from "@mui/material";

const schema = yup.object().shape({
  name: yup.string().required().min(3).max(255),
  cnpj: yup.string().required().min(18).max(18),
  companyName: yup.string().required().min(3).max(255),
  contactEmail: yup.string().required().email(),
});
const CadastroEmpresa: React.FC = () => {
  const { selectedCompany } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width: 750px)");
  const themeContext = useContext(ThemeContext);
  const authContext = useContext(AuthContext);

  const { handleSubmit, control, formState: { errors }, reset, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e?: any) => {

    if (!e || !e.target.files || !e.target.files[0]) {
      return;
    }

    const allowedFileTypes = ['image/png', "image/jpeg", "image/jpg", "image/gif", "image/bmp", "image/tiff", "application/pdf"];

    if (e && e.target.files && e.target.files[0] && !allowedFileTypes.includes(e.target.files[0].type)) {
      alert('Apenas imagens ou pdf no campo "Anexo"!');
      return;
    }

    if (e && e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }

  const [logoThumbnailUrl, setLogoThumbnailUrl] = useState<string | null>();
  const erase = () => {
    reset({
      name: "",
      cnpj: "",
      companyName: "",
      contactEmail: "",
      logoUrl: "",
    });
    setFile(null);
    setLogoThumbnailUrl(null);
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const data = getValues();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (!validateCnpj(data.cnpj)) {
      alert("O CNPJ não é válido. Tente novamente...");
      return;
    }

    const cnpj = data.cnpj.replace(/\D/g, "");
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("cnpj", cnpj);
    formData.append("companyName", data.companyName);
    formData.append("contactEmail", data.contactEmail);
    formData.append("parentCompanyId", "0qjyNaZJ2TYndunFbXdc");
    if (file) {
      formData.append("file", file);
    }
    try {
      await api.post("/company/adm", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      erase();
      alert("Empresa criada com sucesso!");
      history.push("/login");
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Background>
      <Box style={{
        width: isMobileScreen ? "100vw" : "50vw",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center"
      }}>
        <div style={{
          minWidth: isMobileScreen ? "80%" : "50%",
          marginTop: isMobileScreen ? "35%" : "17%"
        }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5%"
          }}>
            <img style={{width:"300px"}} src={logo} />
          </div>
          <div style={{
            display: "flex",
            justifyContent: "start",
            marginLeft:"10%",
            marginBottom: "2%"
          }}>
          <PageTitle
            title="Cadastro"
            subtitle="Cadastre-se para utilizar todos os nossos beneficios"
          />
          </div>
          <form
            onSubmit={(e: any) => onSubmitForm(e)}
          >
            <div style={{
            marginTop: "3%",
           width:"80%",
           display:"flex",
           marginLeft:"8%"
          }}>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} md={12} >
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      placeholder="Nome Fantasia"
                      disabled={isLoading}
                    />
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.name?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name="companyName"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      placeholder="Razão social"
                      disabled={isLoading}
                    />
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.companyName?.message}
                </Typography>

              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name="cnpj"
                  defaultValue=""
                  render={({ field }) => (
                    <ReactInputMask
                      {...field}
                      mask="99.999.999/9999-99"
                      disabled={isLoading}
                    >
                      {(inputProps: any) => (
                        <Input {...inputProps} placeholder="CNPJ" />
                      )}
                    </ReactInputMask>
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.cnpj?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name="contactEmail"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      placeholder="Email"
                      disabled={isLoading}
                    />
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.contactEmail?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  control={control}
                  name="logoUrl"
                  defaultValue=""
                  render={({ field }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {logoThumbnailUrl ? (
                        <img
                          src={logoThumbnailUrl}
                          alt="Company Logo"
                          style={{ maxHeight: 50 }}
                        />
                      ) : null}

                      <S.InputFileContainer background={themeContext.colors.primary}>
                        <TextField
                          id="file-input"
                          type="file"
                          onChange={(e: any) => handleFileChange(e)}
                        />

                        <label htmlFor="file-input">
                          Anexar aquivo
                        </label>
                      </S.InputFileContainer>
                    </div>
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.logoUrl?.message}
                </Typography>
              </Grid>
              <div style={{
              marginTop: "3%",
              display: "flex",
              width: "100%",
              gap: "1.5rem",
              justifyContent: "center"
            }}>
                <PrimaryButton
                  disabled={isLoading}
                  fontSize="15px"
                  paddingLeft="70px"
                  paddingRight="70px"
                  borderRadius="30px"
                  onClick={() => { }}
                  width="100%"
                  type="submit"
                >
                  Salvar
                </PrimaryButton>

              </div>
            </Grid>
            </div>
          </form>
        </div>
      </Box>
      {!isMobileScreen && (
        <div style={{ width: "50%", marginTop: "6%" }}>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "5%" }}>
            <TitleBox>
              <h1>
                UM SISTEMA DE RH
              </h1>

              <Typography fontSize="32px" fontColor="#ffffff" fontWeight="300">
                COMPLETO PARA VOCÊ
              </Typography>
            </TitleBox>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
            <Avatar style={{ width: "300px", height: "300px" }} src={nova} />
          </div>
        </div>
      )}
    </Background>
  );
};

export default CadastroEmpresa;
