import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography"
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { Card as BenefitCard } from "../../../../common/components/Card";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Avatar, Button, Card, CardActions, CardContent, Chip, FormControlLabel, List, ListItem, ListItemAvatar, ListItemText, Paper, Switch, TextField, styled } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import PageSubTitle from "../../../../common/components/PagSubTitle";
import InfoIcon from '@mui/icons-material/Info';
import { format, differenceInDays, setDate } from 'date-fns';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import { UserInfo } from "../../../../common/components/hooks/Auth/types/MeFragment";
import { GridWrapper, SectionWrapper, Wrapper, WrapperBack } from "./styles";
import CancelIcon from '@mui/icons-material/Cancel';
interface Comentarios {
  id: number;
  usuarioId: number;
  chamadoId: number;
  comentario: string;
}
const UserMessage = styled(ListItemText)({

  borderRadius: '10px',
  padding: '5px',
  margin: '5px',
  maxWidth: '100%',
  minWidth:"300px",
  display: 'inline-block', // Alteração aqui
});

const OtherMessage = styled(ListItemText)({
  borderRadius: '10px',
  padding: '10px',
  marginRight: "1%",
  margin: '5px 0',
  maxWidth: '70%',
  display: 'inline-block', // Alteração aqui
});

interface Message {
  companyAdminId: string;
  workerName: string | null;
  companyAdminName: string | null;
  id: number;
  comentario: string;
  time: Date;
  media?: File;
  createdAt: string; // Alterado para string, pois vem da API como string
  usuario: {
    name: string;
  };
}


const initialState = {
  comentario: "",
};

function Chat() {
  const { selectedCompany, companyAdmin, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const [error, setError] = useState<string | null>(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const theme = useContext(ThemeContext);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [workerName, setWorkerName] = useState('');
  const [open, setOpen] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null);
  const [selectedMedia, setSelectedMedia] = useState<File | undefined>(undefined);
  const [selectedTecnicoId, setSelectedTecnicoId] = useState<number | null>(null); // Estado para armazenar o ID do técnico selecionado
  const [inputValue, setInputValue] = useState<string>(''); // Estado para armazenar o valor do input
  const handleOpenDialog = () => {
    setOpen(true);
  };

  console.log("teste", companyAdmin)
  const inputFileRef = useRef<HTMLInputElement>(null);
  const formatMessageTime = (time: Date): string => {
    if (!time || !(time instanceof Date) || isNaN(time.getTime())) {
      return ''; // Retorna uma string vazia se `time` não for válido
    }

    const now = new Date();
    const sameDay = now.getDate() === time.getDate() && now.getMonth() === time.getMonth() && now.getFullYear() === time.getFullYear();

    if (sameDay) {
      return format(time, 'HH:mm'); // Formato para mostrar apenas o horário se enviado hoje
    } else {
      return format(time, 'dd/MM HH:mm'); // Formato para mostrar dia e mês se não enviado hoje
    }
  };


  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const getChat = async () => {
    try {

      const { data } = await api.get(`/chat/info/${companyId}`);
      console.log("chamados", data);
      setMessages(data);
    } catch (e) {
      console.log(e);
    }
  };

  const DeleteMensagem = async (id: number) => {
    try {
      const { data } = await api.delete(`/delete/mensagem/${id}`,);
    } catch (e) {
      getChat();
      alert("Mensagem Deletada com sucesso!");
    } finally {
      setLoading(false);
    }
  }

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '' || selectedMedia !== undefined) {
      const formattedCreatedAt = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      const newMessageObj: Message = {
        id: messages.length + 1,
        comentario: newMessage,
        workerName: workerName || null,
        companyAdminName: companyAdmin?.firstName || null,
        media: selectedMedia !== null ? selectedMedia : undefined,
        time: new Date(),
        companyAdminId: companyAdmin.id,
        createdAt: formattedCreatedAt,
        usuario: {
          name: companyAdmin.firstName,
        }
      };

      try {
        await handleSignUpEntidade(newMessageObj);
        setMessages([...messages, newMessageObj]);
        setNewMessage('');
        setSelectedMedia(undefined);
      } catch (err) {
        setError(err);
      }
    }
  };



  const handleSignUpEntidade = async (newMessageObj: Message) => {
    const body = {
      comentario: newMessageObj.comentario,
      companyId: companyId,
      companyAdminId: companyAdmin.id,
    };

    try {
      const { data } = await api.post("/chat", body);
    } catch (err) {
      throw err; // Re-throw error to be caught in handleSendMessage
    }
  };
  useEffect(() => {
    getChat();
  }, []);
  return (
    <>
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
              <PageTitle
                title="Feed"
                subtitle="Aqui você pode interagir os usuários da sua empresa."
              />
            </div>
            <GridWrapper>
              <Wrapper style={{ overflowY: 'auto' }} ref={messageListRef}>
                <List style={{minHeight:"200px"}}>
                  {messages.map((message) => {
                    const isOwnMessage = message.companyAdminId === null;
                    const firstName = message.usuario?.name || 'U';
                    const isCompanyAdmin = message.companyAdminId !== null;
                    const senderName = isCompanyAdmin ? message.companyAdminName : message.workerName;
                    return (
                      <ListItem
                        key={message.id}
                        style={{
                          justifyContent: isOwnMessage ? 'flex-end' : 'flex-start'
                        }}
                      >
                        <ListItemAvatar
                          style={{ order: isOwnMessage ? 1 : 0 }}
                        >
                          <Avatar style={{ paddingLeft: "2%", paddingRight: "2%" }}>{firstName.charAt(0)}</Avatar>
                        </ListItemAvatar>
                        {isCompanyAdmin ? (
                          <UserMessage style={{ backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast }}>
                            <div>{message.comentario}</div>
                            <small style={{ marginTop: 5, fontSize: '0.8em', color: theme.colors.primaryContrast, }}>
                              {senderName} • {formatMessageTime(new Date(message.createdAt))}
                            </small>
                          </UserMessage>
                        ) : (
                          <OtherMessage style={{ backgroundColor: "#c7c7c7" }}>
                            <div>{message.comentario}</div>
                            <small style={{ marginTop: 5, fontSize: '0.8em', }}>
                              {senderName} • {formatMessageTime(new Date(message.createdAt))}
                            </small>
                          </OtherMessage>
                        )}
                            <div><IconButton onClick={() => DeleteMensagem(message.id)}>
                              <CancelIcon />
                            </IconButton></div>
                      </ListItem>
                    );
                  })}
                </List>
              </Wrapper>

              <Wrapper style={{ borderTop: '1px solid #ccc' }}>
                <TextField
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && newMessage.trim() === '') {
                      e.preventDefault();
                      alert("Por favor, digite uma mensagem antes de enviar.");
                    } else if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                  label="Digite sua mensagem"
                  variant="outlined"
                  fullWidth
                />
                <Button onClick={handleSendMessage} style={{ backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast, marginRight: "1%", marginLeft: "2%" }}>
                  <SendIcon />
                </Button>
                <input
                  type="file"
                  accept="image/*, video/*"
                  onChange={(e) => {
                    const file = e.target.files && e.target.files[0];
                    if (file) {
                      setSelectedMedia(file);
                    }
                  }}
                  style={{ display: 'none' }}
                  ref={inputFileRef}
                />
                <Button onClick={() => inputFileRef.current?.click()} style={{ backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast, }}>
                  <ImageIcon />
                </Button>
              </Wrapper>

            </GridWrapper>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>
    </>
  );
}

export default Chat;