import React from "react";
import { SelectStyled } from "../Selected/styles";
import { ISelectYear } from "./interface";
import { Select } from "@material-ui/core";

const YearSelect: React.FC<ISelectYear> = ({ startYear, endYear, value, onChange, marginBottom, marginBottomMobile, marginTop, marginTopMobile}) => {
  const maxOffset = endYear - startYear;
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(endYear - x);
  }

  const yearList = allYears.map((x) => {
    return <option key={x}>{x}</option>;
  });
  return (
    <div>
      <SelectStyled value={value} onChange={(event) => {
        onChange(event);
      }}>{yearList}</SelectStyled>
    </div>
  );
};

export default YearSelect;
