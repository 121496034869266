import Typography from "../Typography";

const NoResult: React.FC = () => {
    return<>
      <br />
      <Typography fontSize="16px" fontWeight="400">
        Não há resultados para exibir.
      </Typography>
      <br />
    </>;
};

export default NoResult;