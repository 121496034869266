import React, { useContext } from "react";
import { BarChart, XAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import { ThemeContext } from "styled-components";
import { IGraphicBar } from "./interface";

const GraphicBar: React.FC<IGraphicBar> = (props) => {
  const themeColor = useContext(ThemeContext)

  const labelStyled = {
    color: themeColor.colors.primary,
    fontSize: "12px",
  };

  const wrapperStyled = {
    border: "none",
    background: themeColor.colors.primaryContrast,
    borderRadius: "5px",
    boxShadow: "-1px 3px 17px 0px rgba(0,0,0,0.73)",
  };

  const height = props.height || 148;

  return (
    <ResponsiveContainer width={"100%"} height={height}>
      <BarChart
        barCategoryGap="35%"
        barGap={9}
        height={height}
        data={props.data}
      >
        <XAxis dataKey="name" />
        <Tooltip contentStyle={wrapperStyled} labelStyle={labelStyled} />

        <Bar
          maxBarSize={152}
          dataKey={props.dataKeySecondary}
          fill={props.color || ''}
          radius={[8, 8, 0, 0]}
        />
        <Bar
          maxBarSize={152}
          dataKey={props.dataKeyPrimary}
          fill={`${props.color}${99}`}
          radius={[8, 8, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GraphicBar;
