import styled from "styled-components";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
`;

export const Header = styled.div`
  display: flex;
  padding-Top: 2%;
  padding-Right: 2%;
  padding-Left: 2%;
`;

export const Logo = styled.img`
  height: 40px;
  margin-right: 15px;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #626262;
  margin: 7px 0;
  display: flex;
`;

export const LabelIconWrapper = styled.div`
  display: flex;
  width: 20px;
  align-items: center;
  justify-content: center;
`;

export const CheckoutCards = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`;

export const CheckoutCard = styled.div`
  padding: 18px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: calc(33% - (50px / 3));
  border-radius: 5px;
`;

export const HtmlDescriptionCard = styled.div`
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
  padding: 20px;
  margin-top: 30px;
`;



export const WrapperBanner = styled.div`
  /* Estilos para o wrapper do banner */
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HtmlDescriptionContainer = styled.div`
  max-height: 300px;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &.expanded {
    max-height: unset;
    &::after {
      display: none;
    }
  }
`;

export const ExpandButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ExpandButton = styled.button`
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primaryContrast};
`;

export const HtmlDescriptionContent = styled.div`
  color: black;
  font-size: 14px;

  & h1 {
    font-size: 20px;
    font-weight: 500;
  }
  & h2 {
    font-size: 16px;
    font-weight: 500;
  }
  & p {
    margin: 0;
  }
  & blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 15px 10px;
  }
  & .ql-indent-1 {
    margin-left: 20px;
  }
  & .ql-indent-2 {
    margin-left: 40px;
  }
  & .ql-indent-3 {
    margin-left: 60px;
  }
  & .ql-indent-4 {
    margin-left: 80px;
  }
  & .ql-indent-5 {
    margin-left: 100px;
  }
  & .ql-indent-6 {
    margin-left: 120px;
  }
  & .ql-indent-7 {
    margin-left: 140px;
  }
  & .ql-indent-8 {
    margin-left: 160px;
  }
`;

export const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 10px;
`;

export const Action = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryContrast};
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const ButtonAccessAll = styled.button`
  /* Adicione os estilos do botão aqui */
`;



export const WrapperBeneficios = styled.div`
  /* Estilos para o wrapper dos benefícios */
`;

export const WrapperLoading = styled.div`
  /* Estilos para o wrapper de loading */
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const SectionWrapper = styled.div`
  padding: 20px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const SectionTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 6%;
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content:center;
`;




export const WrapperImage = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Images = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 10px;
`;
