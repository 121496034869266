import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { useHistory } from "react-router-dom";
import { ButtonBuy, Details, GridWrapper, Images, Price, SectionTitle, SectionWrapper, Title, Wrapper, WrapperBack, WrapperImage, Categoryaa, Description } from "./styles";
import api from "../../../../services/api";
import {Chip } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

interface Pacotes {
  id: string,
  produto: string;
  produtoimage: string;
  sku: string;
  preco: number;
  estoque: string;
  pagamento: string;
  categoria: string;
  shortdescription: string;
  companyId: string;
}
interface PacksCompanyConfig {
  id: string,
  produtoshopid: string,
  companyId: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
}

function DisponibilizarProduto() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [shop, setShop] = useState<Pacotes[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFullDescription, setShowFullDescription] = useState<{ [key: string]: boolean }>({});
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const [error, setError] = useState<string | null>(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [buyPacks, setBuyPacks] = useState<PacksCompanyConfig[]>([]);
  const theme = useContext(ThemeContext);

  const getPacks = async () => {
    try {
      const params = {
        companyId
      }
      const { data } = await api.get(`/shop`, { params });
      setShop(data);
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  const handleBuyPack = async (produtoshopid: string) => {
    try {
      await api.post(`/sell/pacote/to-company`, {
        produtoshopid,
        companyId: selectedCompany.id
      });

      alert("Pacote adicionado à empresa!");
      history.push("/disponivel/lojinha");
      setBuyPacks([]);
      getPacks();

      // Remove o benefício do carrinho
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os produtos da empresa.");
    } finally {
      setLoading(false);
    }
  };


  const DeletProduto = async (id: string) => {
    try {
      const { data } = await api.delete(`/delete/produto/shop/${id}`,);
      setShop(data.data.data);
    } catch (e) {
      getPacks();
      alert("Produto Deletado com sucesso!");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPacks();
  }, []);

  const getStarRating = (qtdusuarios: number) => {
    if (qtdusuarios <= 25) return 1;
    if (qtdusuarios <= 50) return 2;
    if (qtdusuarios <= 75) return 3;
    if (qtdusuarios <= 100) return 4;
    return 5;
  };

  const toggleDescription = (id: string) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Disponibilizar Produto"
          subtitle="Aqui você pode disponibilizar o produto na sua loja para o colaborador."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>

          <div style={{ display: "flex", justifyContent: "end", width: "92%", marginBottom: "1%", marginTop: "10px" }}>
            <Stack direction="row" spacing={1}>
              <Chip
                label="Criar Produto"
                style={{
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                }}
                onClick={() => history.push('/novo/produto/shop')}
              />
            </Stack>
          </div>
          <GridWrapper>
            {shop.map(b => (
              <Wrapper key={b.id}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <IconButton onClick={() => DeletProduto(b.id)}>
                    <CancelIcon
                      style={{
                        color: "#ac3021",
                        width: "20px",
                        height: "20px"
                      }} />
                  </IconButton><div style={{ marginLeft: 'auto' }}>
                    <IconButton onClick={() => history.push(`/editar/produto/shop/${b.id}`)}>
                      <EditIcon
                        style={{
                          color: theme.colors.primary,
                          width: "20px",
                          height: "20px"
                        }} />
                    </IconButton>
                  </div>
                </div>
                <WrapperImage>
                  <Images src={b.produtoimage} alt={b.produto} />
                </WrapperImage>
                <Title>{b.produto}</Title>
                <Price>{formatCurrency(b.preco)}</Price>
                <Categoryaa>Categoria: {b.categoria}</Categoryaa>
                <Details>Estoque: {b.estoque}</Details>
                <Description>{b.shortdescription}</Description>
                <ButtonBuy onClick={() => handleBuyPack(b.id)}>Colocar na Loja</ButtonBuy>
              </Wrapper>
            ))}
          </GridWrapper>
        </WrapperBack>
      </GridWrapper>
    </SectionWrapper>
  );
}

export default DisponibilizarProduto;