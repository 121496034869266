import React, { useCallback } from "react";
import { ITabTitle } from "./interface";
import { Li, StyledTabTitle } from "./styles";
import { Row } from "react-bootstrap";

const TabTitle: React.FC<ITabTitle> = ({
  title,
  setActiveIndex,
  index,
  isActive,
}) => {
  const onClick = useCallback(() => {
    setActiveIndex(index);
  }, [setActiveIndex, index]);

  return (
    <>
      <Li>
        <StyledTabTitle onClick={onClick} isActive={isActive}>
          {title}
        </StyledTabTitle>
      </Li>
    </>
  );
};

export default TabTitle;
