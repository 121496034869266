import React, { useEffect, useState } from "react";
import Input from "../../../../../common/components/Input";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import Typography from "../../../../../common/components/Typography";
import * as yup from "yup";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import { MUTATION_CREATE_INVITE } from "./graphql";
import {
  createWorkerInvite,
  createWorkerInviteVariables,
} from "./types/createWorkerInvite";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import CompanySelectBorder from "../../../../../common/components/CompanySelectBorder";
import { Col, Row } from "react-bootstrap";
import api from "../../../../../services/api";

const schema = yup.object().shape({
  companyId: yup.string().required(),
  workerEmail: yup.string().required().email(),
});

interface Props {
  isShown: boolean;
  hide: () => void;
  refresh: () => void;
  companyId: string;
}

const ModalInvitePremium: React.FC<Props> = ({ isShown, hide, companyId, refresh }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onSubmitCreateInvite = async (data: any) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await api.post("/invites/premium", data);

      refresh();
      alert("Convite enviado com sucesso!");
      hide();
      reset({
        companyId: companyId || "",
        workerEmail: "",
      });
    } catch (e) {
      console.error(e);
      const backendMessage = e.response?.data?.message;

      if (backendMessage === "Limite de convites atingido para esta empresa com o pacote atual.") {
        alert(backendMessage); // Exibir a mensagem específica como alerta
      } else {
        alert("Limite de convites atingido para esta empresa com o pacote atual."); // Mensagem genérica para outros erros
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    reset({
      companyId: companyId || "",
      workerEmail: "",
    });
  }, [companyId]);

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Enviar convite para colaborador
      </Typography>
      <form onSubmit={handleSubmit(onSubmitCreateInvite)}>
        <div style={{ marginTop: "33px" }}>
          <Controller
            control={control}
            name="companyId"
            defaultValue={companyId || ""}
            render={({ field }) => (
              <CompanySelectBorder {...field} options={[]} />
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.companyId?.message}
          </Typography>
        </div>
        <div style={{ marginTop: "33px" }}>
          <Controller
            control={control}
            name="workerEmail"
            defaultValue=""
            render={({ field }) => (
              <Input
                placeholder="E-mail corporativo do colaborador"
                {...field}
                onChange={(event) => field.onChange(event.target.value.toLowerCase())}
              />
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.workerEmail?.message}
          </Typography>
        </div>
        <br />
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <PrimaryButton type="submit" disabled={isLoading}>
              Enviar convite
            </PrimaryButton>

          </Col>
        </Row>
      </form>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default ModalInvitePremium;
