import React from "react";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";

const UnsuccessModal: React.FC<{
  isShown: boolean;
  hide: () => any;
  children: any;
}> = ({ isShown, hide, children }) => {
  return (
    <ModalDefault isShown={isShown} hide={hide}>
      {children}
    </ModalDefault>
  );
};

export default UnsuccessModal;
