import styled from "styled-components";
import { prop, ifProp } from "styled-tools";

export const WrapperPage = styled.div`
  display: flex;
  background-color: #F7F7F7;
  max-width:100%;
`;

export const WrapperImg = styled.div`
  margin-bottom: 72px;
  margin-left: 15px;
`;

export const WrapperItems = styled.div`
  @media (max-width: 500px) {
    display: ${ifProp({ open: false }, "none", "block")};
  }
`;

export const WrapperChildren = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  width:100%;
  padding-bottom:1px;
  color: ${prop("theme.colors.text")};
`;

export const WrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HiddenLogo = styled.div`
  visibility: hidden;
`;
