import React from "react";
import { Iframe } from "./styles";


const Screen: React.FC = () => {
  return(
    <Iframe>

    </Iframe>
  )
};

export default Screen;
