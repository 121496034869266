import { useMutation } from "@apollo/client";
import React from "react";
import DangerButton from "../../../../../common/components/DangerButton";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import Typography from "../../../../../common/components/Typography";
import { deleteNotifications, deleteNotificationsVariables } from "../types/deleteNotifications";
import { MUTATION_DELETE_NOTIFICATIONS } from "./graphql";

const DeleteModalNotifications: React.FC<{
  id?: string | null;
  isShown: boolean;
  hide: () => any;
}> = ({ isShown, hide, id }) => {

    const [deleteNotifications, deleNotificationsMutation] = useMutation<
    deleteNotifications, deleteNotificationsVariables
    >(MUTATION_DELETE_NOTIFICATIONS, {
        variables: {
            id: id || "",
        },
        update: (cache) => {
            cache.evict({id: `id:${id}`})
        }
    });


  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja excluir essa notificação?
      </Typography>
      <br />
      <Typography fontSize="22px" fontWeight="300">
        Depois de confirmado, não poderá reverter essa ação.
      </Typography>
      <br />
      <DangerButton color="red" onClick={async () => {
          const result = await deleteNotifications();

          switch(result.data?.deleteWorkerNotification.__typename){
              case "EmptySuccess": {
                  alert("Notificação excluída com sucesso!");
                  hide();
                  return;
              }
              case "NotFoundError": {
                  alert("Notificação não encontrada.");
                  hide();
                  return;
              }
              default: {
                  alert("Erro ao excluir a notificação. Tente novamente.")
              }
          }
      }} disabled={deleNotificationsMutation.loading}>
        Excluir Notificação
      </DangerButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default DeleteModalNotifications;
