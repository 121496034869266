import React, { useContext, useEffect, useState } from "react";
import { Panel } from "../../../../common/components/Panel";
import PageTitle from "../../../../common/components/PageTitle";
import SelectCustom from "../../../../common/components/Selected";
import { Wrapper, Button, TableWrapper, ContainerTable, SectionWrapper, GridWrapper, WrapperBack } from "./styles";
import InputBackground from "../../../../common/components/InputBackground";
import {
  Table,
  Theader,
  TH,
  Tr,
} from "../../../../common/components/Table/styles";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import Item from "../../../../common/components/DropdownDefault/Items";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import Icon from "../../../../common/components/Icon";
import List from "../../../../common/components/DropdownDefault/List";
import Loading from "../../../../common/components/Loading";
import telefone from "telefone";
import { useHistory, useParams } from "react-router-dom";
import cpfLib from "cpf";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../../../../common/components/hooks/Auth";
import ReactInputMask from "react-input-mask";
import DisableModal from "./DisableModal";
import EnableModal from "./EnableModal";
import NoResult from "../../../../common/components/NoResult";
import Helmet from "react-helmet";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "../../../../common/components/Typography";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import api from "../../../../services/api";
import { WorkerType } from "./interface";
import { MenuItem, Paper, TablePagination, TextField } from "@material-ui/core";
import { ThemeContext } from "styled-components";
import { Stack, Chip, TableSortLabel } from "@mui/material";

const schema = yup.object().shape({
  email: yup.string(),
  cpf: yup.string(),
  canAccess: yup.string().nullable()
});

interface OptionVal {
  value: string;
  text: string;
}

const pageSize = 10;
const Worker: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [disablingId, setDisablingId] = useState<string | null>();
  const [enablingId, setEnablingId] = useState<string | null>();
  const companyId = context.companySelect.selectedValue;
  const [email, setEmail] = useState<string | null>();
  const [cpf, setCpf] = useState<string | null>();
  const [canAccess, setCanAccess] = useState<string | null>();

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const theme = useContext(ThemeContext);
  const [filterTrigger, setFilterTrigger] = useState(false);
  const [options, setOptions] = useState<OptionVal[]>([]);
  const handleChangePage = (__, newPage: number) => {
    setPage(newPage);
  };

  const getWorkers = async () => {
    setIsLoading(true);

    try {
      const params: any = {
        page: page + 1,
        email: email ? email.trim() : "",
        cpf: cpf ? cpf.trim() : "",
        companyId
      }

      if (canAccess) {
        params.canAccess = canAccess;
      }

      const { data } = await api.get("/worker", { params });

      setWorkers(data.data);
      setTotal(data.total);
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('firstName');

  const handleSortRequest = (property: string) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = (data) => {
    return data.sort((a, b) => {
      if (orderDirection === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  };

  const capitalizeName = (name: string) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };


  useEffect(() => {
    getWorkers();
  }, [page, filterTrigger])

  const getCompanyDropdown = async () => {
    try {
      const { data } = await api.get(`/company/${companyId}/dropdown`);
      console.log("empre", data)
      setOptions(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getCompanyDropdown();
  }, [companyId]);
  const transferir = async (workerId: string, selectedCompany: string) => {
    try {
      const { data } = await api.put(`/worker/${workerId}/transfer`, {
        companyId: selectedCompany // Envia o ID da empresa selecionada para o colaborador específico
      });
      // Atualiza a lista de colaboradores após a transferência
      setWorkers((prevWorkers) =>
        prevWorkers.map((worker) =>
          worker.id === workerId ? { ...worker, selectedCompany } : worker
        )
      );
      alert("Usuário transferido com sucesso!");
      getWorkers();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>

      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
              <PageTitle
                title=" Usuários"
                subtitle="Aqui você pode convidar, listar e filtrar os colaboradores que utilizam a PeopleClub."
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: "2%", paddingRight: "2%" }}>
              <form
                onSubmit={handleSubmit((data) => {
                  const onlyNumber = data.cpf.replace(/\D/g, "");
                  setCpf(onlyNumber || null);
                  setEmail(data.email || null);
                  setCanAccess(data.canAccess);
                  setFilterTrigger(prev => !prev);
                })}
                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Controller
                    control={control}
                    name="cpf"
                    defaultValue=""
                    render={({ field }) => (
                      <ReactInputMask {...field} mask="999.999.999-99">
                        {(inputProps: any) => (
                          <InputBackground
                            {...inputProps}
                            borderRadius="30px"
                            placeholder="Filtrar por CPF"
                          />
                        )}
                      </ReactInputMask>
                    )}
                  />
                  <Typography fontSize="15px" fontWeight="300" fontColor="red">
                    {errors.cpf?.message}
                  </Typography>


                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    render={({ field }) => (
                      <InputBackground
                        {...field}
                        borderRadius="30px"
                        placeholder="Filtrar por e-mail"
                        onChange={(event) =>
                          field.onChange(event.target.value.toLowerCase())
                        }
                      />
                    )}
                  />
                  <Typography fontSize="15px" fontWeight="300" fontColor="red">
                    {errors.email?.message}
                  </Typography>

                  <Controller
                    control={control}
                    name="canAccess"
                    defaultValue={null}
                    render={({ field: { onChange, value, ...field } }) => (
                      <SelectCustom
                        {...field}
                        marginTop="30px"
                        variant="large"
                        onChange={(event) => {
                          switch (event.target.value) {
                            case "Habilitado":
                              onChange("Habilitado");
                              break;
                            case "Desabilitado":
                              onChange("Desabilitado");
                              break;
                            default:
                              onChange(null);
                              break;
                          }
                        }}
                        marginBottom="5px"
                        width="300px"
                        value={value}
                        options={[
                          { value: "", text: "Filtrar por status..." },
                          { value: "Habilitado", text: "Habilitado" },
                          { value: "Desabilitado", text: "Desabilitado" },
                        ]}
                      />
                    )}
                  />
                  <Typography fontSize="15px" fontWeight="300" fontColor="red">
                    {errors.canAccess?.message}
                  </Typography>
                </div>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'start', marginLeft: "2%", marginBottom: "3%", marginTop: "3%" }}>
                  <Button
                    onClick={() => { }}
                    disabled={false}
                    style={{
                      backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                      fontSize: "10px",
                      width: "100px",
                      padding: "7px 7px",
                      borderRadius: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    Buscar
                  </Button>
                </div>
              </form>
            </div>
            <TableWrapper>
              <ContainerTable>
                <Table>
                  <thead>
                    <tr>
                      <Theader
                        style={{ color: theme.colors.title }}
                        onClick={() => handleSortRequest('firstName')}
                      >
                        <TableSortLabel
                          active={orderBy === 'firstName'}
                          direction={orderBy === 'firstName' ? orderDirection : 'asc'}
                        >
                          Nome
                        </TableSortLabel>
                      </Theader>
                      <Theader
                      style={{ color: theme.colors.title }}
                      onClick={() => handleSortRequest('cpf')}
                    >
                      <TableSortLabel
                        active={orderBy === 'cpf'}
                        direction={orderBy === 'cpf' ? orderDirection : 'asc'}
                      >
                        CPF
                      </TableSortLabel>
                    </Theader>
                    <Theader
                      style={{ color: theme.colors.title }}
                      onClick={() => handleSortRequest('phone')}
                    >
                      <TableSortLabel
                        active={orderBy === 'phone'}
                        direction={orderBy === 'phone' ? orderDirection : 'asc'}
                      >
                        Telefone
                      </TableSortLabel>
                    </Theader>
                    <Theader
                      style={{ color: theme.colors.title }}
                      onClick={() => handleSortRequest('email')}
                    >
                      <TableSortLabel
                        active={orderBy === 'email'}
                        direction={orderBy === 'email' ? orderDirection : 'asc'}
                      >
                        E-mail
                      </TableSortLabel>
                      </Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Status</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Transferir</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Ações</Theader>

                    </tr>
                  </thead>
                  <tbody>
                  {!isLoading && sortedData(workers).map((item) =>  (
                      <Tr key={item.id}>
                        <TH style={{ cursor: "pointer" }} onClick={() => history.push(`/usario/${item.id}/dependentes`)}>
                        {capitalizeName(`${item.firstName} ${item.lastName}`)}
                        </TH>
                        <TH style={{ cursor: "pointer" }} onClick={() => history.push(`/usario/${item.id}/dependentes`)}>
                          {item.cpf ? cpfLib.format(item.cpf) : "-"}
                        </TH>
                        <TH style={{ cursor: "pointer" }} onClick={() => history.push(`/usario/${item.id}/dependentes`)}>
                          {item.phone
                            ? telefone.format(item.phone)
                            : "-"}
                        </TH>
                        <TH style={{ cursor: "pointer" }} onClick={() => history.push(`/usario/${item.id}/dependentes`)}>
                          {item.email ? item.email : "-"}</TH>
                        <TH>

                          {item.canAccess ? (
                            <Chip
                              label="Ativo"
                              style={{
                                backgroundColor: "#3FBE3F",
                                color: "#fff",
                                width: "50px",
                                fontSize: "10px",
                                height: "20px",
                                fontWeight: "bold",
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#3FBE3F",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            />
                          ) : (
                            <Chip
                              label="Inativo"
                              style={{
                                backgroundColor: "#ab0303",
                                color: "#fff",
                                width: "60px",
                                fontSize: "10px",
                                height: "20px",
                                fontWeight: "bold",
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#ab0303",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            />
                          )}
                        </TH>
                        <TH>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <TextField
                              select
                              size="small"
                              style={{ width: "80px", marginRight: "2%" }}
                              value={item.selectedCompany}


                              onChange={(e) => {
                                const selectedCompanyId = e.target.value;
                                // Atualiza o estado local do colaborador com a empresa selecionada
                                const updatedWorkers = workers.map((worker) =>
                                  worker.id === item.id ? { ...worker, selectedCompany: selectedCompanyId } : worker
                                );
                                setWorkers(updatedWorkers);
                              }}
                            >
                              {options
                                .filter(option => {
                                  if (option.text === 'Nova Coop') {
                                    return option.value === '7da4f20a-f8bf-47c9-85fb-650898af0407';
                                  }
                                  return true;
                                })
                                .map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                  </MenuItem>
                                ))
                              }
                            </TextField>
                            <Chip
                              label="Transferir"
                              style={{
                                backgroundColor: "#3f61be",
                                color: "#fff",
                                width: "70px",
                                fontSize: "10px",
                                marginLeft: "4%",
                                marginTop: "1%",
                                height: "20px",
                                fontWeight: "bold",
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#3f61be",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                              onClick={() => transferir(item.id, item.selectedCompany)}
                            />
                          </div>
                        </TH>
                        <TH>
                          <Dropdown>
                            <Toggle>
                              <Icon name="more-vertical" />
                            </Toggle>
                            <List>
                              {item.canAccess ? (
                                <Item>
                                  <Button onClick={() => setDisablingId(item.id)}>Desabilitar</Button>
                                </Item>
                              ) : (
                                <Item>
                                  <Button onClick={() => setEnablingId(item.id)}>Habilitar</Button>
                                </Item>
                              )}
                            </List>
                          </Dropdown>

                        </TH>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </ContainerTable>

              {isLoading && (
                <Loading />
              )}

              {!isLoading && total === 0 && (
                <div className="px-4">
                  <NoResult />
                </div>
              )}

              <TablePagination
                count={total}
                page={page}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                component="div"
              />
            </TableWrapper>

          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

      {/* MODAL */}
      <DisableModal
        isShown={!!disablingId}
        hide={() => setDisablingId(null)}
        id={disablingId}
        refresh={getWorkers}
      />
      <EnableModal
        isShown={!!enablingId}
        hide={() => setEnablingId(null)}
        id={enablingId}
        refresh={getWorkers}
      />
    </>
  );
};

export default Worker;
