import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import AvatarUpload from "../NewBenefits/AvatarUpload";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Rating, Stack } from "@mui/material";
import { GridWrapper, SectionWrapper, Wrapper, WrapperBack } from "./styles";
import ProdutoImage from "../NewProductShop/ProdutoImage";

interface Errors {
  [field: string]: string;
}

const initialState = {
  name: "",
  qtdempresas: "",
  qtdusuarios: "",
  valor: "",
  descricao: "",
};

const NewPack: React.FC = () => {
  const [pack, setPack] = useState(initialState);
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [showFullDescription, setShowFullDescription] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(true); // Initially set to true

  const handleChangePartnerData = (obj: Object): void => {
    setPack(prev => ({
      ...prev,
      ...obj
    }));
  };

  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  };

  const validateData = () => {
    let hasError = false;

    setErrorMessage({});

    if (pack.name.trim() === "") {
      setErrorMessage({ name: "Campo obrigatório!" });
      hasError = true;
    }

    if (pack.qtdempresas.trim() === "") {
      setErrorMessage({ qtdempresas: "Campo obrigatório!" });
      hasError = true;
    }

    if (pack.qtdusuarios.trim() === "") {
      setErrorMessage({ qtdusuarios: "Campo obrigatório!" });
      hasError = true;
    }

    if (pack.valor.trim() === "") {
      setErrorMessage({ valor: "Campo obrigatório!" });
      hasError = true;
    }

    if (pack.descricao.trim() === "") {
      setErrorMessage({ descricao: "Campo obrigatório!" });
      hasError = true;
    }

    return hasError;
  };

  const postPartner = async () => {
    if (validateData()) return;

    // Converta os valores para os tipos corretos
    const packData = {
      name: pack.name,
      qtdempresas: parseInt(pack.qtdempresas, 10),
      qtdusuarios: parseInt(pack.qtdusuarios, 10),
      valor: parseFloat(pack.valor.replace(',', '.')), // Substituir vírgula por ponto para converter para número
      descricao: pack.descricao
    };

    console.log("packData being sent:", packData); // Adicione este log para verificar os dados antes de enviar

    try {
      await api.post("/criar-pacotes", packData);

      resetForm();
      alert("Pacote criado com sucesso!");
      history.push("/pacotes");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Update loading state after API call completes
    }
  };

  const resetForm = (): void => {
    setPack(initialState);
  };
  const toggleDescription = (id: string) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  const formatCurrency = (value) => {
    // Função para formatar valor em moeda (por exemplo, BRL)
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  useEffect(() => {
    setLoading(false); // Set loading to false once component is fully loaded
  }, []);

  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>

            <PageTitle
              title="Plano"
              subtitle="Aqui você pode criar planos para as empresas."
            />

            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item xs={12} md={4} style={{ marginTop: "-3%" }}>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <Wrapper style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                      <Rating
                        name="size-large"
                        value={
                          (() => {
                            const qtdusuarios = parseInt(pack.qtdusuarios, 10) || 0; // Converte a string para número, ou 0 se for inválido
                            if (qtdusuarios <= 25) return 1;
                            if (qtdusuarios <= 50) return 2;
                            if (qtdusuarios <= 75) return 3;
                            if (qtdusuarios <= 100) return 4;
                            return 5;
                          })()
                        }
                        readOnly
                        size="large"
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
                  <h1
                    style={{
                      fontSize: '16px',
                      fontFamily: "inherit",
                      fontWeight: 700,
                      lineHeight: '24px',
                      color: "#383838"
                    }}
                  >
                    {pack.name}
                    </h1>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <p style={{ fontFamily: "inherit", fontSize: '14px', color: "#757575", fontWeight: 'bold' }}>Filiais: {pack.qtdempresas}</p>
                    <p style={{ fontFamily: "inherit", fontSize: '14px', color: "#757575", fontWeight: 'bold' }}>Usuários: {pack.qtdusuarios}</p>
                    <p style={{ fontFamily: "inherit", fontSize: '12px', color: '#757575' }}>
                      {pack.descricao.length > 10 ? `${pack.descricao.substring(0, 10)}...` : pack.descricao}
                    </p>
        
                    </div>
                    <h1 style={{ fontSize: '22px', fontFamily: "inherit", fontWeight: 700, lineHeight: '24px', color: "#383838", marginTop: '10px' }}>
                      {formatCurrency(pack.valor)}
                    </h1>
                  </Wrapper>
                </div>
              </Grid>

              <Grid item xs={12} md={8} style={{marginTop:"1%"}}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="name"
                      name="name"
                      style={{ paddingBottom: "3%", marginTop: "-3%" }}
                      autoFocus
                      label="Nome"
                      fullWidth
                      autoComplete="Nome"
                      className="inputdados"
                      size="small"
                      value={pack.name}
                      onChange={e => handleChangePartnerData({ name: e.target.value })}
                    />
                    {formErrors.name && (
                      <S.ErrorMessage>
                        {formErrors.name}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="qtdempresas"
                      name="qtdempresas"
                      style={{ paddingBottom: "3%", marginTop: "-3%" }}
                      autoFocus
                      label="Quantidade de Empresas"
                      fullWidth
                      autoComplete="Quantidade de Empresas"
                      className="inputdados"
                      size="small"
                      value={pack.qtdempresas}
                      onChange={e => handleChangePartnerData({ qtdempresas: e.target.value.replace(/\D/g, '') })}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="qtdusuarios"
                      name="qtdusuarios"
                      style={{ paddingBottom: "3%", marginTop: "-3%" }}
                      autoFocus
                      label="Quantidade de Usuários"
                      fullWidth
                      autoComplete="Quantidade de Usuários"
                      className="inputdados"
                      size="small"
                      value={pack.qtdusuarios}
                      onChange={e => handleChangePartnerData({ qtdusuarios: e.target.value.replace(/\D/g, '') })}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="valor"
                      name="valor"
                      style={{ paddingBottom: "3%", marginTop: "-3%" }}
                      autoFocus
                      label="Valor do Pacote"
                      fullWidth
                      autoComplete="Valor"
                      className="inputdados"
                      size="small"
                      value={pack.valor}
                      onChange={e => handleChangePartnerData({ valor: e.target.value.replace(/[^0-9.,]/g, '') })}
                      inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      variant="outlined"
                      id="descricao"
                      name="descricao"
                      fullWidth
                      multiline
                      rows={5}
                      label="Descrição"
                      autoComplete="descricao"
                      onChange={e => handleChangePartnerData({ descricao: e.target.value })}
                      value={pack.descricao}
                      className="inputdados"
                      size="small"
                    />
                    {formErrors.descricao && (
                      <S.ErrorMessage>
                        {formErrors.descricao}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
              <Chip label="Cadastrar Pacote" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"
              }}
                onClick={postPartner} />
            </div>
              </Grid>
            </Grid>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

    </>
  );

  return (
    <>
      {content}
    </>
  );
};

export default NewPack;