import styled from "styled-components";

export const TitleBox = styled.div`
  display: flex;
  margin-top: 100px;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

export const ShowPassword = styled.button`
  position: relative;
  border-width: 0 0 1px;
  border-color: #767676;
  border-style: solid;
  bottom: 0px;
  background-color: transparent;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;
