import React, { useContext, useEffect } from "react";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Tabs from "../../../../common/components/TabDefault/Tabs";
import Tab from "../../../../common/components/TabDefault/Tab";
import {
  ProfileDataContainer,
  ButtonWrapperAccount,
  ButtonWrapperProfile,
  FormWrapper,
  SavePasswordButton,
  EditDataButton,
  SectionWrapper,
  SectionTitle,
  GridWrapper,
  WrapperBack,
} from "./styles";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../../common/components/Input";
import DangerButton from "../../../../common/components/DangerButton";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import Typography from "../../../../common/components/Typography";
import { useMutation } from "@apollo/client";
import { PROFILE_UPDATE_MUTATION } from "./graphql";
import { Helmet } from "react-helmet";
import { UpdateProfile, UpdateProfileVariables } from "./types/UpdateProfile";
import AuthContext from "../../../../common/components/hooks/Auth";
import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import { ThemeContext } from "styled-components";
const schemaProfileForm = yup.object().shape({
  firstName: yup.string().required().min(3).max(255),
  lastName: yup.string().required().min(3).max(255),
  department: yup.string().required().min(3).max(255),
  role: yup.string().required().min(3).max(255),
  contactEmail: yup.string().required().email(),
});

const Profile: React.FC<{ id?: string | null }> = ({ id }) => {
  const theme = useContext(ThemeContext);
  const context = useContext(AuthContext);
  const {
    handleSubmit: handleSubmitProfileForm,
    reset: resetProfileForm,
    control: controlProfileForm,
    formState: { errors: errorsProfileForm },
  } = useForm({
    resolver: yupResolver(schemaProfileForm),
  });

  useEffect(() => {
    if (!context.companyAdmin) {
      resetProfileForm({
        firstName: "",
        lastName: "",
        department: "",
        role: "",
        contactEmail: "",
      });
      return;
    }

    resetProfileForm({
      firstName: context.companyAdmin.firstName || "",
      lastName: context.companyAdmin.lastName || "",
      department: context.companyAdmin.department || "",
      role: context.companyAdmin.role || "",
      contactEmail: context.companyAdmin.email || "",
    });
  });

  const [updateProfile, mutationUpdateProfile] = useMutation<
    UpdateProfile,
    UpdateProfileVariables
  >(PROFILE_UPDATE_MUTATION);

  const onSubmitUpdateProfile = async (data: any) => {
    if (mutationUpdateProfile.loading) {
      return;
    }

    const result = await updateProfile({
      variables: {
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          department: data.department,
          role: data.role,
          id:
            context.companyAdmin?.__typename === "CompanyAdmin"
              ? context.companyAdmin.id
              : "",
        },
      },
    });

    if (result.data?.updateCompanyAdmin.__typename === "CompanyAdmin") {
      alert("Perfil atualizado com sucesso!");
      return;
    }

    if (result.data?.updateCompanyAdmin.__typename === "InputError") {
      alert("Erro nos dados informados. Tente novamente!");
      return;
    }

    alert("Erro desconhecido. Tente novamente!");
  };

  return (
    <>
      <SectionWrapper>
        <SectionTitle>
          <PageTitle
            title="Perfil"
            subtitle="Aqui você pode visualizar e editar os seus dados"
          />
        </SectionTitle>
        <GridWrapper>
          <WrapperBack>
            <Tabs>
              <Tab title="Dados Pessoais">
                <form onSubmit={handleSubmitProfileForm(onSubmitUpdateProfile)}>

                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: "2%", marginBottom:"5%" }}
                  >
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={controlProfileForm}
                        name="firstName"
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="" label="Nome" />
                        )}
                      />
                      <Typography fontSize="15px" fontWeight="300" fontColor="red">
                        {errorsProfileForm.firstName?.message}
                      </Typography>

                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={controlProfileForm}
                        name="lastName"
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="" label="Sobrenome" />
                        )}
                      />
                      <Typography fontSize="15px" fontWeight="300" fontColor="red">
                        {errorsProfileForm.lastName?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>

                      <Controller
                        control={controlProfileForm}
                        name="department"
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="" label="Departamento" />
                        )}
                      />
                      <Typography fontSize="15px" fontWeight="300" fontColor="red">
                        {errorsProfileForm.department?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={controlProfileForm}
                        name="role"
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="" label="Função" />
                        )}
                      />
                      <Typography fontSize="15px" fontWeight="300" fontColor="red">
                        {errorsProfileForm.role?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{display:"flex", justifyContent:"space-between", paddingRight:"3%", marginLeft:"3%"}}>
                      <Button
                        type="submit"
                        onClick={() => { }}
                        style={{  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                          borderRadius:"30px", width:"300px"
                        }}
                        disabled={false}
                      >
                        Editar dados
                      </Button>
                      <Button
                        disabled={false}
                        onClick={() => { }}
                        style={{  backgroundColor: "red", color: "#fff",
                          borderRadius:"30px", width:"300px"
                        }}
                      >
                        Excluir conta
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Tab>

              <Tab title="Recuperação de Senha">
                <form onSubmit={() => { }}>

                <Grid
                    container
                    spacing={3}
                    style={{ marginTop: "2%", marginBottom:"5%" }}
                  >
                  
                  <Grid item xs={12} md={12}>
                      <Controller
                        control={controlProfileForm}
                        name="contactEmail"
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled={true}
                            fontFamily="Montserrat"
                            placeholder=""
                            label="E-mail"
                            type="email"
                          />
                        )}
                      />
                      <Typography fontSize="15px" fontWeight="300" fontColor="red">
                        {errorsProfileForm.contactEmail?.message}
                      </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} style={{display:"flex", justifyContent:"space-between", paddingRight:"3%", marginLeft:"3%"}}>
                  
                      <Button
                        style={{  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                          borderRadius:"30px", width:"300px"
                        }}
                        disabled={false}
                        onClick={() => { }}
                      >
                        Recuperar Senha
                      </Button>

                      <Button
                        disabled={false}
                        onClick={() => { }}
                        style={{  backgroundColor: "red", color: "#fff",
                          borderRadius:"30px", width:"300px"
                        }}
                      >
                        Excluir conta
                      </Button>
                    </Grid>
                    </Grid>
                </form>
               
              </Tab>
            </Tabs>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper >
    </>
  );
};

export default Profile;
