import styled from "styled-components";
import { TextareaAutosize, TextareaAutosizeProps } from "@material-ui/core";

export const TextareaStyle = styled(TextareaAutosize)<TextareaAutosizeProps>`
  border-width: 0 0 1px;
  width: 100%;
  min-height: 30px;
  font-weight: 400;
  outline: 0;
  font-family: Montserrat;
`;
