import { TextareaAutosizeProps } from "@material-ui/core";
import React, { memo } from "react";
import { TextareaStyle } from "./styles";

const Textarea: React.FC<TextareaAutosizeProps> = (props) => {
  return (
    <TextareaStyle
      {...props}
    />
  );
};

export default memo(Textarea);
