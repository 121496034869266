import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    padding-top: 10px;
    background-color: transparent;

    &:hover {
        opacity: 0.5;
    }
`
