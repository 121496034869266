import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { IDateInput } from './interface';


export const WrapperDate = styled.div`
    display: flex;
    margin-bottom: 10px;
    margin-top: 20px;
`

export const Input = styled.input<IDateInput>`
  background-color: ${ifProp("bgColor", prop("bgColor"), "#F7F7F7")};
    border-radius: ${prop("borderRadius", "10px")};
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 19px;
    padding-bottom: 5px;
    padding-top: 5px;
    outline: 0;

  input::-ms-expand {
    display: none;
  }

  @media (max-width: '1200px'){
    margin-bottom: ${prop('marginBottomMobile')};
    margin-top: ${prop('marginTopMobile')};
  }
`

export const LabelDate = styled.label`
    margin-top: 7px;
    margin-right: 5px;
`

export const ContainerTable = styled.div`
  overflow: auto;
`