import { OperationVariables, QueryResult, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import NoResult from "../../../../common/components/NoResult";
import PageTitle from "../../../../common/components/PageTitle";
import Paginator from "../../../../common/components/Paginator";
import { Panel } from "../../../../common/components/Panel";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import * as yup from "yup";
import {
  Table,
  TH,
  Theader,
  Tr,
} from "../../../../common/components/Table/styles";
import { ButtonWrapper, TableWrapper } from "../Invite/styles";
import { QUERY_NOTIFICATIONS } from "./graphql";
import {
  workerNotifications,
  workerNotificationsVariables,
} from "./types/workerNotifications";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import List from "../../../../common/components/DropdownDefault/List";
import Items from "../../../../common/components/DropdownDefault/Items";
import { Button } from "../../../../modules/Sections/screens/Invite/styles";
import Loading from "../../../../common/components/Loading";
import Pagination from "../../../../common/components/Pagination";
import Icon from "../../../../common/components/Icon";
import AuthContext from "../../../../common/components/hooks/Auth";
import CompanySelect from "../../../../common/components/CompanySelect";
import { ContainerTable, Input, LabelDate, WrapperDate } from "./styles";
import NotificationsModal from "./Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import DeleteModalNotifications from "./DeleteModal";

const schema = yup.object().shape({
  maxCreatedAt: yup.date().required(),
  minCreatedAt: yup.date().required(),
});

const Notifications: React.FC = () => {
  const pageSize = 10;
  const context = useContext(AuthContext);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [maxDate, setMaxDate] = useState<string | null>(null);
  const companyId = context.companySelect.selectedValue;
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [updatingId, setUpdatingId] = useState<string | null>();
  const [deletingId, setDeletingId] = useState<string | null>();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const notifications = useQuery<
    workerNotifications,
    workerNotificationsVariables
  >(QUERY_NOTIFICATIONS, {
    variables: {
      first: 10,
      companyId: companyId,
      minCreatedAt: minDate,
      maxCreatedAt: maxDate,
    },
  });

  return (
    <>
     
      <NotificationsModal
        id={updatingId}
        isShown={!!updatingId || isCreating}
        hide={() => {
          setUpdatingId(null);
          setIsCreating(false);
        }}
        companyId={companyId}
      />
      <DeleteModalNotifications
        isShown={!!deletingId}
        hide={() => setDeletingId(null)}
        id={deletingId}
      />
      <Row>
        <Col md={7} sm={12}>
          <PageTitle
            title="Notificações"
            subtitle="Aqui você acompanha todas as notificações."
          />
        </Col>
      </Row>
      <Panel borderRadius="20px">
        <ButtonWrapper>
          <PrimaryButton
            onClick={() => setIsCreating(true)}
            disabled={false}
            iconName="plusButton"
            fontSize="12px"
            marginBottom="57px"
            paddingBottom="7px"
            paddingTop="7px"
            paddingLeft="32px"
            paddingRight="32px"
            display="flex"
          >
            Criar uma nova notificação
          </PrimaryButton>
        </ButtonWrapper>

        <form
          onSubmit={handleSubmit((data) => {
            const min = new Date(data.minCreatedAt).toISOString();
            const max = new Date(data.maxCreatedAt).toISOString();
            setMinDate(moment(min).format("YYYY-MM-DD") || null);
            setMaxDate(moment(max).format("YYYY-MM-DD") || null);
          })}
        >
          <Row>
            <Col md={6} lg={3}>
              <WrapperDate>
                <LabelDate>De:</LabelDate>
                <Controller
                  control={control}
                  name="minCreatedAt"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder="De:"
                      marginBottomMobile="10px"
                      marginTopMobile="10px"
                    />
                  )}
                />
              </WrapperDate>
            </Col>
            <Col md={6} lg={3}>
              <WrapperDate>
                <LabelDate>Até:</LabelDate>
                <Controller
                  control={control}
                  name="maxCreatedAt"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder="De:"
                      marginBottomMobile="10px"
                      marginTopMobile="10px"
                    />
                  )}
                />
              </WrapperDate>
            </Col>
            <Col sm={6} md={4} lg={2}>
              <PrimaryButton
                onClick={() => {}}
                disabled={false}
                marginTop="10px"
                fontSize="16px"
                paddingBottom="10px"
                paddingTop="10px"
                type="submit"
              >
                Buscar
              </PrimaryButton>
            </Col>
          </Row>
        </form>
        <Paginator
          pageSize={pageSize}
          queryResult={
            notifications as QueryResult<unknown, OperationVariables>
          }
          extractConnection={(data) => {
            const value = data as workerNotifications | undefined;
            if (
              value?.workerNotifications.__typename !==
              "WorkerNotificationsConnection"
            ) {
              return null;
            }
            return value.workerNotifications;
          }}
          render={(paginator) => {
            if (
              paginator.paginationState.loaded &&
              !paginator.paginationState.total
            ) {
              return <NoResult />;
            }

            return (
              <>
                <TableWrapper style={{ marginTop: "20px" }}>
                  <ContainerTable>
                    <Table>
                      <thead>
                        <tr>
                          <Theader>Título</Theader>
                          <Theader>Subtítulo</Theader>
                          <Theader>Data</Theader>
                        </tr>
                      </thead>
                      <tbody>
                        {paginator.paginationState.isLoadingMore
                          ? null
                          : paginator.items.map((item: any) => (
                              <Tr>
                                <TH>{item.title}</TH>
                                <TH>{item.subtitle}</TH>
                                <TH>
                                  {item.createdAt
                                    ? moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </TH>
                                <TH></TH>
                                <TH>
                                  <Dropdown>
                                    <Toggle>
                                      <Icon name="more-vertical" />
                                    </Toggle>
                                    <List>
                                      <Items
                                        onClick={() => setDeletingId(item.id)}
                                      >
                                        <Button>Excluir</Button>
                                      </Items>
                                      {/* <Items
                                      onClick={() => setUpdatingId(item.id)}
                                      >
                                      <Button>Editar</Button>
                                    </Items> */}
                                    </List>
                                  </Dropdown>
                                </TH>
                              </Tr>
                            ))}
                      </tbody>
                    </Table>
                  </ContainerTable>
                  {!paginator.paginationState.loaded ||
                  paginator.paginationState.isLoadingMore ? (
                    <Loading />
                  ) : null}
                  {paginator.paginationState.loaded ? (
                    <Pagination
                      onPrevClick={paginator.handlePrevPage}
                      onNextClick={paginator.handleNextPage}
                      disableNext={paginator.paginationState.disableNext}
                      disablePrev={paginator.paginationState.disablePrev}
                      to={paginator.paginationState.to?.toString()}
                      from={paginator.paginationState.from?.toString()}
                      total={paginator.paginationState.total?.toString()}
                    />
                  ) : null}
                </TableWrapper>
              </>
            );
          }}
        />
      </Panel>
    </>
  );
};

export default Notifications;
