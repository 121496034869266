import styled from "styled-components";
import { prop, ifProp } from "styled-tools";
import { INavBarItem } from "./interface";

export const NavButton = styled.button<INavBarItem>`
  display: flex;
  color: ${ifProp(
    { active: true },
    prop("theme.colors.primary"),
    prop("theme.colors.primaryContrast")
  )};
  background-color: ${ifProp(
    { active: true },
    prop("theme.colors.primaryContrast"),
    prop("theme.colors.primary")
  )};
  border: none;
  width: 100%;
  height: 36px;
  border-radius: 50px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 27px;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 15px;
  ${ifProp("disabled", "opacity: .7;")}
  @media(max-width: 500px) {
    display: ${ifProp({ compact: true }, "none", "flex")};
    text-align: center;
  }
`;

export const IconContainer = styled.div`
  margin-right: 10px;
  margin-bottom: 2px;
`;

export const Span = styled.span`
  font-weight: 400;
  margin-top: 4px;
`;
