import styled from 'styled-components';
import { IInputbackground } from './interface';
import { prop, ifProp } from 'styled-tools';

export const InputBackgroundDefault = styled.div<IInputbackground>`
    display: flex;
    background-color: ${ifProp("bgColor", prop("bgColor"), "#F7F7F7")};
    border-radius: ${prop("borderRadius", "30px")};;
    border: none;
    width: 10%;
    font-size: 12px;
    outline: 0;
    padding-top: 15px;
    height: 44px;
    margin-bottom: 30px;
    border: 1px solid #000;
    
    
`

export const Input = styled.input<IInputbackground>`
    display: flex;
    background-color: ${ifProp("bgColor", prop("bgColor"), "#F7F7F7")};
    border-radius: ${prop("borderRadius", "5px")};;
    border: none;
    width: 40%;
    font-size: 12px;
    outline: 0;
    padding-left: 1%;
    padding-right: 1%;
    margin-left: 1%;
    height: 40px;
    border: 1px solid #000;
    
        
`

