import React from "react";
import Helmet from 'react-helmet';
import WebView from "../../../../common/components/WebView";
import { useHistory, useLocation } from "react-router-dom";

const Gestao: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const url = params.get('url') || "";

  return (
    <>

      <WebView url={url} title="People Hub - Gestão Unificada" onBack={() => history.goBack()} />
    </>
  );
};

export default Gestao;
