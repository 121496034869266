import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography"
import { useHistory, useParams } from "react-router-dom";
import { GridWrapper, Images, SectionTitle, SectionWrapper, Wrapper, WrapperBack, } from "./styles";
import api from "../../../../services/api";
import { Card as BenefitCard } from "../../../../common/components/Card";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Rating from '@mui/material/Rating';
import { Avatar, Button, Card, Chip, FormControlLabel, Paper, Switch, styled } from "@material-ui/core";
import { Checkbox, IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import PageSubTitle from "../../../../common/components/PagSubTitle";
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


interface Pacotes {
  id: string,
  name: string;
  qtdempresas: number;
  qtdusuarios: number;
  valor: number;
  descricao: string;
}
interface PacksCompanyConfig {
  id: string,
  packId: string,
  companyid: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
  packs: Pacotes;
  dependet: boolean;
}


function PacotesAtivos() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [pacotes, setPacotes] = useState<PacksCompanyConfig[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFullDescription, setShowFullDescription] = useState<{ [key: string]: boolean }>({});
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const [error, setError] = useState<string | null>(null);
  const [dependet, setDependet] = useState(false);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const theme = useContext(ThemeContext);
  const getPacks = async () => {
    try {
      const params = { companyId };

      const { data } = await api.get("/pack-active", { params });

      // Filtrar pacotes ativos que pertencem ao companyId selecionado
      const pacotesAtivos = data.data.filter((pack: PacksCompanyConfig) => pack.companyid === companyId);

      setPacotes(pacotesAtivos);
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  const DeletPacote = async (id: string) => {
    try {
      const { data } = await api.delete(`/delete/pacote/ativo/${id}`,);
      setPacotes(data.data.data);
    } catch (e) {
      getPacks();
      alert("Plano Deletado com sucesso!");
      history.push("/pacotes");


    } finally {
      setLoading(false);
    }
  }


  const toggleActive = async (id: string) => {
    setIsProcessingRequest(true);

    try {
      const { data } = await api.put(`/pacote-config/update/${id}`, {
        dependet: !pacotes.find(b => b.id === id)?.dependet // Alterna o estado atual
      });

      setPacotes(prev => prev.map((b) => {
        if (b.id !== id) {
          return b;
        }

        return { ...b, dependet: data.data.dependet }; // Atualiza o estado com o novo valor de `dependet`
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsProcessingRequest(false);
    }
  };

  useEffect(() => {
    getPacks();
  }, [companyId]);

  const getStarRating = (qtdusuarios: number) => {
    if (qtdusuarios <= 25) return 1;
    if (qtdusuarios <= 50) return 2;
    if (qtdusuarios <= 75) return 3;
    if (qtdusuarios <= 100) return 4;
    return 5;
  };

  const toggleDescription = (id: string) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  function formatDate(dateString: string) {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  }
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Planos Ativos"
          subtitle="Aqui você pode gerenciar os planos ativos da sua empresa."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          {context && context.companyAdmin?.Company?.id === "0qjyNaZJ2TYndunFbXdc" ? (
            <div style={{ display: "flex", justifyContent: "end", width: "92%", marginBottom: "1%", marginTop: "10px" }}>
              <Stack direction="row" spacing={1}>
                <Chip
                  label="Novo Pacote"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                  }}
                  onClick={() => history.push('/novo-pacote')}
                />
              </Stack>
            </div>
          ) : null}
          <GridWrapper>
            {pacotes.map(b => (
              <Wrapper key={b.id} style={{
                padding: '20px',
                border: '1px solid #ddd',
                borderRadius: '15px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adiciona sombra para destaque
                position: 'relative', // Para posicionamento do botão de exclusão
                backgroundColor: '#fff', // Cor de fundo clara para contraste

              }}>

                {/* Título e Botão de Excluir */}
                <div style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start', // Distribui espaço entre o título e o botão de exclusão
                  alignItems: 'center',
                  marginBottom: '15px'
                }}>

                  {/* Botão de Excluir no Canto Superior Direito */}
                  {context && context.companyAdmin?.Company?.id === "0qjyNaZJ2TYndunFbXdc" ? (
                    <IconButton
                      onClick={() => DeletPacote(b.id)}
                      style={{
                        width: "20px",
                        height: "20"
                      }}
                    >
                      <CancelIcon style={{
                        color: "#ac3021",
                        width: "20px",
                        height: "20px"
                      }}
                      />
                    </IconButton>
                  ) : null}
                </div>

                <h1 style={{
                  fontSize: '24px',
                  fontFamily: "inherit",
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: theme.colors.primary,
                  textAlign: 'center',
                  marginBottom: '10px'
                }}>
                  {b.packs.name}
                </h1>

                <Rating name="size-large" value={getStarRating(b.packs.qtdusuarios)} readOnly size="small" style={{ marginBottom: '10px' }} />
                <div style={{
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.primaryContrast,
                  borderRadius: '50%',
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  position: 'absolute',
                  fontSize:"15px",
                  top: '-30px',
                  right: '-30px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                }}>
                  {formatCurrency(b.packs.valor)}
                </div>

                <ul style={{
                  listStyleType: 'none',
                  padding: 0,
                 textAlign: 'center',
                  width: '100%',
                  marginLeft:"25%",
                  marginTop: '30px'
                }}>
                  <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} />
                    Quantidade de Filiais : <strong style={{ marginLeft: "2%" }}>{b.packs.qtdempresas}</strong>
                  </li>
                  <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} />
                    Quantidade de Usuários: <strong style={{ marginLeft: "2%" }}>{b.packs.qtdusuarios}</strong>
                  </li>
                </ul>
                <p style={{
                  marginTop: '20px',
                  fontFamily: "inherit",
                  fontSize: '14px',
                  color: '#757575',
                  textAlign: 'center'
                }}>
                  <strong> Descrição :</strong> {b.packs.descricao}
                </p>
                <p style={{
                  marginTop: '20px',
                  fontFamily: "inherit",
                  fontSize: '14px',
                  color: '#757575',
                  textAlign: 'center'
                }}>
                  O Colaborador Podera ter Dependentes?
                </p>
                <FormControlLabel
                  style={{
                    fontFamily: "inherit",
                    fontSize: '12px',
                    color: '#757575',
                    whiteSpace: "nowrap",
                    marginTop: '5px'
                  }}
                  onChange={() => toggleActive(b.id)}
                  control={
                    <Switch
                      checked={b.dependet}
                      style={{ color: theme.colors.primary }}
                    />
                  }
                  label="" />
                <div style={{
                  backgroundColor: "#26ad26",
                  borderRadius: '30px',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  width: "100%",
                  marginTop: "20px",
                  textAlign: 'center'
                }}>
                  <p style={{
                    fontSize: '14px',
                    color: "#fff",
                    fontWeight: 'bold',
                    fontFamily: "inherit",
                    margin: 0,
                  }}>
                    Adquirido em: {formatDate(b.createdAt)}
                  </p>

                </div>

              </Wrapper>
            ))}
          </GridWrapper>
        </WrapperBack>
      </GridWrapper>
    </SectionWrapper>
  );
}

export default PacotesAtivos;