import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import AvatarUpload from "../NewBenefits/AvatarUpload";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Stack } from "@mui/material";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";

interface Errors {
  [field: string]: string;
}

const initialState = {
  name: "",
  shortDescription: "",
}

const NewPartner: React.FC = () => {
  const [partner, setPartner] = useState(initialState);
  const history = useHistory();
  const [partnerLogo, setPartnerLogo] = useState<File>(null);
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState<boolean>(true); // Initially set to true
  const handleChangePartnerData = (obj: Object): void => {
    setPartner(prev => ({
      ...prev,
      ...obj
    }));
  }
  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  }

  const validateData = () => {
    let hasError = false;

    setErrorMessage({});

    if (partner.name.trim() === "") {
      setErrorMessage({ name: "Campo obrigatório!" });
      hasError = true;
    }

    if (partner.shortDescription.trim() === "") {
      setErrorMessage({ shortDescription: "Campo obrigatório!" });
      hasError = true;
    }

    return hasError;
  }

  const postPartner = async () => {
    if (validateData()) return;

    try {
      const formData = new FormData();

      formData.append("name", partner.name);
      formData.append("shortDescription", partner.shortDescription);

      if (partnerLogo) {
        formData.append("file", partnerLogo);
      }

      await api.post("/parceiros", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      resetForm();
      alert("Parceiro criado com sucesso!");
      history.push("/beneficios");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Update loading state after API call completes
    }
  }

  const resetForm = (): void => {
    setPartner(initialState);
    setPartnerLogo(null);
  }

  useEffect(() => {
    setLoading(false); // Set loading to false once component is fully loaded
  }, []);

  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>

        <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
          <PageTitle
            title="Parceiros"
            subtitle="Aqui você pode adicionar um novo Parceiro."
          />
        </div>
        <GridWrapper>
          <WrapperBack>
            <div style={{width:"100%", display: "flex", justifyContent: "end", marginTop: "2%", marginBottom: "5%", marginRight: "2%" }}>
              <Stack direction="row" spacing={1}>
                <Chip label="Novo Beneficio" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push(`/novos-beneficios`)} />
                <Chip label="Novo Banner" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push(`/novos-banners`)} />
              </Stack>
            </div>
            <div style={{ maxWidth: "600px", marginLeft: "20%" }}>

              <AvatarUpload onChangeImage={setPartnerLogo} src='' />

              <TextField
                variant="outlined"
                id="name"
                name="name"
                style={{ paddingBottom: "3%", marginTop: "-3%" }}
                autoFocus
                label="Nome"
                fullWidth
                autoComplete="Nome"
                className="inputdados"
                size="small"
                value={partner.name}
                onChange={e => handleChangePartnerData({ name: e.target.value })}
              />

              {formErrors.name && (
                <S.ErrorMessage>
                  {formErrors.name}
                </S.ErrorMessage>
              )}


              <TextField
                variant="outlined"
                id="descricao"
                name="descricao"
                fullWidth
                multiline
                rows={5}
                label="Descrição"
                autoComplete="descricao"
                onChange={(e) =>
                  handleChangePartnerData({ shortDescription: e.target.value })
                }
                value={partner.shortDescription}
                className="inputdados"
                size="small"
              />

              {formErrors.shortDescription && (
                <S.ErrorMessage>
                  {formErrors.shortDescription}
                </S.ErrorMessage>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
              <Chip label=" Cadastrar Parceiro" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"

              }}
                onClick={postPartner} />
            </div>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper >
    </>
  );

  return (
    <>

      {content}
    </>
  );
};

export default NewPartner;
