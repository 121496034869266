import styled from "styled-components";
import { ifProp, prop } from "styled-tools";
import { INavBarStyled, IToggle } from "./interface";

export const NavbarDefault = styled.div<INavBarStyled>`
  background-color: ${prop("theme.colors.primary")};
  box-sizing: border-box;
  padding: 30px 25px;
  border: 1px solid ${prop("theme.colors.primary")};
  border-radius: 0px 30px 0px 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: width 0.1s ease-in;
  margin-top: -92px;


  ${ifProp(
  ({ open, isMobile }) => open && isMobile,
  `
    width: 95%;
    height: 100%;
  `
)}
  ${ifProp(
  ({ open, isMobile }) => !open && isMobile,
  `
    width: 97px;
    height: 90px;
  `
)}
  ${ifProp(
  ({ open, isMobile }) => open && !isMobile,
  `
    width: 290px;
    
  `
)}
  ${ifProp(
  ({ open, isMobile }) => !open && !isMobile,
  `
    width: 97px;
   
  `
)}
  ${ifProp(
  "isMobile",
  `
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10000;`,
  `position: sticky;
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.15);
    
`
)}
`;
export const Toggle = styled.div<IToggle>`
  position: absolute;
  top: 0;
  margin-top: 34px;
  margin-right: 25px;
  right: -40px;
  width: 35px;
  height: 35px;
  color: ${prop("theme.colors.primary")};
  padding-top: 5px;
  padding-left: 5px;
  transform: translateX(-30%);
  cursor: pointer;
  background-color: ${prop("theme.colors.primaryContrast")};
  border-radius: 50px;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.15);
  transform: ${ifProp({ open: true }, "rotateY(0deg)", "rotateY(180deg)")};
`;
