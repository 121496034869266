import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authentication: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          companies: relayStylePagination(["companyId"]),
          workers: relayStylePagination(["companyIds"]),
          workerInvites: relayStylePagination(["companyId", "workerEmail", "accepted"]),
          companyAdmins: relayStylePagination(["companyId", "search"]),
          workerNotifications: relayStylePagination(["companyId", "maxCreatedAt", "minCreatedAt"])
        },
      },
      Company: {
        fields: {
          companyAdminsConnection: relayStylePagination(["canAccess", "cpf"]),
          workersConnection: relayStylePagination(["canAccess", "cpf", "email"]),
        },
      },
    },
  }),
});
