import React, { useContext } from "react";
import Typography from "../Typography";
import { PageTitleWrapper } from "./styles";
import { IPageTitle } from "./interface";
import { ThemeContext } from "styled-components";

const PageTitle: React.FC<IPageTitle> = (props) => {
  const theme = useContext(ThemeContext);

  return (
    <PageTitleWrapper>
      <Typography
        fontSize="26px"
        fontFamily="Poppins"
        fontWeight="600"
        lineHeight="33px"
        fontColor={theme.colors.title}
      >
        {props.title}
      </Typography>
      <Typography
       fontSize="16px"
       fontFamily="Poppins"
       fontWeight="400"
        fontColor={theme.colors.text}
      >
        {props.subtitle}
      </Typography>
    </PageTitleWrapper>
  );
};

export default PageTitle;
