import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { ICarousel } from './types';


export const WrapperCarousel = styled.div<ICarousel>`
 width: ${prop("width", "100%")};
  max-width: 100%;
  position: relative;

  @media (max-width: 750px) {
    padding-bottom: 10px;
  }

  @media (max-width: 500px) {
    width: ${prop("width", "120%")};
    padding-bottom: 10px;
  }
`;