import styled from "styled-components";

export const FabButton = styled.button`
  position: fixed;
  bottom: 9px;
  right: 45px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  transform: translateX(calc(100% + 45px));
  &.shown {
    transform: translateX(0);
  }
`;

export const FabBadge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: #00C620;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-weight: 500;
`;
