import React from "react";
import Icon from "../Icon";
import { IPrimaryButton } from "./interface";
import { PrimaryButtonDefault, IconWrapper } from "./styles";

const PrimaryButton: React.FC<IPrimaryButton> = ({iconName, children, text, ...props}) => {
  return (
    <div>
      <PrimaryButtonDefault {...props}>
        <IconWrapper>
          <Icon name={iconName} />
        </IconWrapper>
        {children}
        {text}
      </PrimaryButtonDefault>
    </div>
  );
};

export default PrimaryButton;
