import React, { useState } from "react";
import DangerButton from "../../../../../common/components/DangerButton";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import api from "../../../../../services/api";

interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh?: () => void;
}

const DeleteModal: React.FC<Props> = ({ isShown, hide, id, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteCompany = async () => {
    try {
      setIsLoading(true);
      await api.delete(`/company/${id}`);

      refresh();
      alert("Empresa excluída com sucesso!");
      hide();
    } catch (e: any) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja excluir essa empresa?
      </Typography>
      <br />
      <Typography fontSize="22px" fontWeight="300">
        Depois de confirmado, não poderá reverter essa ação.
      </Typography>
      <br />
      <DangerButton
        color="red"
        disabled={isLoading}
        onClick={deleteCompany}
      >
        Excluir empresa
      </DangerButton>
      <SecondaryButton disabled={isLoading} onClick={hide}>
        Voltar
      </SecondaryButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default DeleteModal;
