import styled from "styled-components";
import { ISecondaryButton } from "./interface";
import { ifProp, prop } from "styled-tools";

export const SecondaryButtonDefault = styled.button<ISecondaryButton>`
  font-family: "Montserrat", sans-serif;
  padding: ${prop("padding", "10px")};
  margin-bottom: ${prop("paddingBottom")};
  margin-top: ${prop("marginTop")};
  padding-left: ${prop("paddingLeft")};
  padding-right: ${prop("paddingRight")};
  padding-top: ${prop("paddingTop")};
  padding-bottom: ${prop("paddingBottom")};
  font-size: ${prop("fontSize", "20px")};
  color: ${prop("theme.colors.secondaryContrast")};
  line-height: ${prop("lineHeight", "24px")};
  width: ${prop("width", "100%")};
  height: ${prop("height")};
  background-color: ${prop("theme.colors.secondary")};
  border: 1px solid ${prop("theme.colors.secondaryContrast")};
  border-radius: ${prop("borderRadius", "30px")};
  display: ${prop("display", "block")};
  margin: auto;
  font-weight: 500;
  ${ifProp("disabled", "opacity: 0.7;")}
  &:hover {
    opacity: 0.7;
  }
`;
