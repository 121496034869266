import styled from "styled-components";
import { switchProp, prop} from "styled-tools";

export const SelectStyled = styled.select<{variant?: "large"}>`
  width: ${prop("width", "100%")};
  height:${switchProp("variant", { large: "44px"}, "30px")};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-bottom: ${prop("marginBottom", "53px")};
  margin-top: ${prop("marginTop")};
  margin-left: ${prop("marginLeft")};
  margin-right: ${prop("marginRight")};
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 10px;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  padding-right: 34px;
  padding-left: 17px;
  z-index: 0;
  position: relative;
  box-sizing: border-box;

  select::-ms-expand {
    display: none;
  }

  @media (max-width: '1200px'){
    margin-bottom: ${prop('marginBottomMobile')};
    margin-top: ${prop('marginTopMobile')};
  }
`;
