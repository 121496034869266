import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { ISelected, ISelectOption } from "../Selected/interface";

import { QUERY_COMPANIES_SELECT } from "./graphql";
import { CompaniesBorder, CompaniesBorderVariables } from "./types/CompaniesBorder";
import SelectBorderCustom from "../SelectBorder";
import AuthContext from "../hooks/Auth";
import api from "../../../services/api";

interface OptionVal {
  value: string;
  text: string;
}

const CompanySelectBorder: React.FC<ISelected & { helperText?: React.ReactNode }> = (
  props
) => {
  const context = useContext(AuthContext);

  const [options, setOptions] = useState<OptionVal[]>([]);

  const companyId = context.companyAdmin?.Company
    ? context.companyAdmin.Company.id
    : "";

  const getCompanyDropdown = async () => {
    try {
      const { data } = await api.get(`/company/${companyId}/dropdown`);

      setOptions(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getCompanyDropdown();
  }, [companyId]);

  const selectProps = { ...props };

  return (
    <SelectBorderCustom   {...selectProps} options={options} />
  );
};

export default CompanySelectBorder;
