import React, { memo, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../../../../common/components/hooks/Auth';
import { AuthContextData } from '../../../../common/components/hooks/Auth/interface';

const Sso: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const auth = useContext<AuthContextData>(AuthContext);
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  
  if (!token) {
      return <Redirect to="/login" />
  }

  auth.unauthenticate().then(() =>
    auth.sso(token)
  ).then(() => history.push('/'));

  return <Spinner animation="border" />
}
export default memo(Sso);