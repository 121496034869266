import styled from "styled-components";
import { IInput } from "./interface";
import { prop } from "styled-tools";

export const InputDefault = styled.input<IInput>`
 border-color: ${prop("borderColor")};
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-weight: 400;
  outline: none;
  font-size: ${prop("fontSize")};
  font-family: ${prop("fontFamily", "Montserrat")};
  margin-top: ${prop("marginTop")};
  margin-bottom: ${prop("marginBottom")};
  margin-left: ${prop("marginLeft")};
  margin-right: ${prop("marginRight")};
  padding-left: 20px; /* Adicione padding aqui */
`;

export const Label = styled.label`
  font-weight: 400;
  line-height: 30px;
`;