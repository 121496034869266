import React from "react";
import GraphicBar from "../../../../../common/components/GraphicBar";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import { IModal } from "../../../../../common/components/ModalDefault/Modal/interface";

const GraphicDetail = (props: IModal) => {
  return (
    <ModalDefault {...props}>
      {props.children}
    </ModalDefault>
  );
};

export default GraphicDetail;
