import styled from "styled-components";
import { prop } from "styled-tools";

export const ImageWrapper = styled.div<{imageUrl: string}>`
  background-image: url(${prop('imageUrl')});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 70px;
  margin-right: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
