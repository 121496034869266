import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";
import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import { BeneficConfig } from "./interfaces";
import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { ThemeContext } from "styled-components";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";
import AvatarUpload from "../NewBenefits/AvatarUpload";
import { BenefitCategory, BenefitSubcategory, Partner } from "../NewBenefits/interfacess";


interface Errors {
  [field: string]: string;
}

const initialState = {
  title: "",
  subtitle: "",
  locationLabel: "",
  shortDescription: "",
  partnerId: "",
  benefitSubcategoryId: "",
  code: "",
  checkLabel: [] as string[],
  htmlDescriptionForCompanyAdmin: "",
  htmlDescriptionForWorker: "",
  link: false,
  linkText: null,
}

const Fornecedores: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [parceiros, setParceiros] = useState<Partner[]>([]);
  const [categoria, setCategoria] = useState<BenefitCategory[]>([]);
  const [subCategoria, setSubCategoria] = useState<BenefitSubcategory[]>([]);
  const [addComputadorModal, setAddComputadorModal] = useState(false);
  const theme = useContext(ThemeContext);
  const [benefit, setBenefit] = useState(initialState);

  const [benefitLogo, setBenefitLogo] = useState<File>(null);


  const handleChangeBenefitData = (obj: Object): void => {
    setBenefit(prev => ({
      ...prev,
      ...obj
    }));
  }

  const toggleIsLink = () => {
    setBenefit(prev => ({
      ...prev,
      link: !prev.link
    }));
  }

  const [selectedCategoria, setSelectedCategoria] = useState("");
  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  }

  function saveCheckLabel() {
    if (inputValue.trim()) {
      setBenefit(prevState => ({
        ...prevState,
        checkLabel: [...prevState.checkLabel, inputValue]
      }));
      setInputValue('');
    }
  }

  function removeCheckLabel(index: number) {
    setBenefit(prev => {
      const newLabels = [...prev.checkLabel];
      newLabels.splice(index, 1);

      return {
        ...prev,
        newLabels
      };
    });
  }

  const getParceiros = async () => {
    try {

      const { data } = await api.get("/parceiros");

      setParceiros(data);
    } catch (e) {
      console.log(e)

    } finally {
      setLoading(false);
    }
  }

  const getCategoria = async () => {
    try {

      const { data } = await api.get("/categoria");

      setCategoria(data);
    } catch (e) {
      console.log(e)

    } finally {
      setLoading(false);
    }
  }

  const validateData = () => {
    let hasError = false;

    setErrorMessage({});

    if (benefit.title.trim() === "") {
      setErrorMessage({ title: "Campo obrigatório!" });
      hasError = true;
    }

    if (selectedCategoria.trim() === "") {
      setErrorMessage({ selectedCategoria: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.subtitle.trim() === "") {
      setErrorMessage({ subtitle: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.shortDescription.trim() === "") {
      setErrorMessage({ shortDescription: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.locationLabel.trim() === "") {
      setErrorMessage({ locationLabel: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.partnerId.trim() === "") {
      setErrorMessage({ partnerId: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.benefitSubcategoryId.trim() === "") {
      setErrorMessage({ benefitSubcategoryId: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.code.trim() === "") {
      setErrorMessage({ code: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.htmlDescriptionForWorker.trim() === "") {
      setErrorMessage({ htmlDescriptionForWorker: "Campo obrigatório!" });
      hasError = true;
    }

    if (benefit.htmlDescriptionForCompanyAdmin.trim() === "") {
      setErrorMessage({ htmlDescriptionForCompanyAdmin: "Campo obrigatório!" });
      hasError = true;
    }

    return hasError;
  }

  const getSubCategoria = async () => {
    try {

      const { data } = await api.get(`/sub-categoria/${selectedCategoria}`);

      setSubCategoria(data);
    } catch (e) {

      console.log(e)

    } finally {
      setLoading(false);
    }
  }

  const postBenefit = async () => {
    if (validateData()) return;

    try {
      const formData = new FormData();

      formData.append("title", benefit.title);
      formData.append("subtitle", benefit.subtitle);
      formData.append("locationLabel", benefit.locationLabel);
      formData.append("shortDescription", benefit.shortDescription);
      formData.append("partnerId", benefit.partnerId);
      formData.append("benefitSubcategoryId", benefit.benefitSubcategoryId);
      formData.append("code", benefit.code);
      formData.append("checkLabel", benefit.checkLabel.join(";"));
      formData.append("htmlDescriptionForWorker", benefit.htmlDescriptionForWorker);
      formData.append("htmlDescriptionForCompanyAdmin", benefit.htmlDescriptionForCompanyAdmin);
      formData.append("link", String(benefit.link));
      formData.append("linkText", benefit.linkText);

      if (benefitLogo) {
        formData.append("file", benefitLogo);
      }

      await api.post("/benefit", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      resetForm();
      alert("Beneficio criado com sucesso!");
      history.push("/beneficios");

    } catch (e) {
      console.log(e);
    }
  }

  const resetForm = (): void => {
    setBenefit(initialState);
    setBenefitLogo(null);
  }

  useEffect(() => {
    getParceiros();
    getCategoria();
  }, []);

  useEffect(() => {
    if (selectedCategoria) {
      getSubCategoria();
    }
  }, [selectedCategoria]);


  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>

        <GridWrapper>
          <WrapperBack>
        <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
          <PageTitle
            title="Adicionar Fornecedor"
            subtitle="Aqui você pode adicionar um novo Fornecedor."
          />



        </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: "2%", marginBottom: "5%", marginRight: "2%" }}>
              <Stack direction="row" spacing={1}>
                <Chip label="Novo Banner" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => history.push(`/novos-banners`)} />
                <Chip label="Novo Parceiro"
                  style={{
                    backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",

                  }}
                  onClick={() => history.push('/novos-parceiros')}
                />
              </Stack>
            </div>
            <Grid
              container
              spacing={2}
              style={{ padding: "1rem" }}
            >
              <Grid item xs={12}>
              <AvatarUpload
                  onChangeImage={setBenefitLogo}
                  src=""
                />
              </Grid>

              <Grid item xs={12} md={4} style={{ display: "flex" }}>

                <TextField
                  variant="outlined"
                  id="parceiros"
                  select
                  name="parceiros"
                  autoFocus
                  label="Parceiros"
                  fullWidth
                  autoComplete="Parceiros"
                  className="inputdados"
                  size="small"
                  value={benefit.partnerId}
                  onChange={e => handleChangeBenefitData({ partnerId: e.target.value })}
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 320, // Defina a altura máxima desejada para o menu
                      },
                    },
                  }}
                >
                  {parceiros.map((parceiro) => (
                    <MenuItem key={parceiro.id} value={parceiro.id}>
                      {parceiro.name}
                    </MenuItem>
                  ))}
                </TextField>
                {formErrors.partnerId && (
                  <S.ErrorMessage>
                    {formErrors.partnerId}
                  </S.ErrorMessage>
                )}

              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="categoria"
                  select
                  name="categoria"
                  autoFocus
                  label="Categoria"
                  fullWidth
                  autoComplete="categoria"
                  className="inputdados"
                  size="small"
                  onChange={(e) => setSelectedCategoria(e.target.value)}
                  value={selectedCategoria}
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 320, // Defina a altura máxima desejada para o menu

                      },
                    },
                  }}
                >
                  {categoria.map((categoria) => (
                    <MenuItem key={categoria.id} value={categoria.id}>
                      {categoria.name}
                    </MenuItem>
                  ))}
                </TextField>

                {formErrors.selectedCategoria && (
                  <S.ErrorMessage>
                    {formErrors.selectedCategoria}
                  </S.ErrorMessage>
                )}

              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="subCategoria"
                  select
                  name="subCategoria"
                  fullWidth
                  autoFocus
                  label="Sub Categoria"
                  autoComplete="categoria"
                  className="inputdados"
                  size="small"
                  onChange={(e) => handleChangeBenefitData({ benefitSubcategoryId: e.target.value })}
                  value={benefit.benefitSubcategoryId}
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 320, // Defina a altura máxima desejada para o menu
                      },
                    },
                  }}
                >
                  {subCategoria.map((subCategoria) => (
                    <MenuItem key={subCategoria.id} value={subCategoria.id}>
                      {subCategoria.name}
                    </MenuItem>
                  ))}
                </TextField>


                {formErrors.benefitSubcategoryId && (
                  <S.ErrorMessage>
                    {formErrors.benefitSubcategoryId}
                  </S.ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="title"
                  name="title"
                  fullWidth
                  autoFocus
                  label="Título"
                  autoComplete="title"
                  onChange={(e) =>
                    handleChangeBenefitData({ title: e.target.value })
                  }
                  value={benefit.title}
                  size="small"
                />


                {formErrors.title && (
                  <S.ErrorMessage>
                    {formErrors.title}
                  </S.ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="subtitle"
                  name="subtitle"
                  fullWidth
                  autoFocus
                  label="Subtítulo"
                  autoComplete="subtitle"
                  onChange={(e) =>
                    handleChangeBenefitData({ subtitle: e.target.value })
                  }
                  value={benefit.subtitle}
                  className="inputdados"
                  size="small"
                />

                {formErrors.subtitle && (
                  <S.ErrorMessage>
                    {formErrors.subtitle}
                  </S.ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12} md={4}>

                <TextField
                  variant="outlined"
                  id="locationLabel"
                  name="locationLabel"
                  fullWidth
                  autoFocus
                  label="Localização"
                  autoComplete="locationLabel"
                  onChange={(e) =>
                    handleChangeBenefitData({ locationLabel: e.target.value })
                  }
                  value={benefit.locationLabel}
                  className="inputdados"
                  size="small"
                />

                {formErrors.locationLabel && (
                  <S.ErrorMessage>
                    {formErrors.locationLabel}
                  </S.ErrorMessage>
                )}

              </Grid>
              <Grid item xs={12} md={6}>

                <TextField
                  variant="outlined"
                  id="shortDescription"
                  fullWidth
                  name="shortDescription"
                  multiline
                  autoFocus
                  label="Descrição"
                  autoComplete="municipio"
                  onChange={(e) =>
                    handleChangeBenefitData({ shortDescription: e.target.value })
                  }
                  value={benefit.shortDescription}
                  className="inputdados"
                  size="small"
                />

                {formErrors.shortDescription && (
                  <S.ErrorMessage>
                    {formErrors.shortDescription}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} md={6}>

                <TextField
                  variant="outlined"
                  id="code"
                  fullWidth
                  name="code"
                  autoFocus
                  label="Código do Beneficio"
                  autoComplete="code"
                  onChange={(e) =>
                    handleChangeBenefitData({ code: e.target.value })
                  }
                  value={benefit.code}
                  className="inputdados"
                  size="small"
                />

                {formErrors.code && (
                  <S.ErrorMessage>
                    {formErrors.code}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} sm={12}>

                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="checkLabel"
                  id="checkLabel"
                  label="Tags"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") saveCheckLabel();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={saveCheckLabel}>
                          <AddIcon style={{ width: "20px" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {!!formErrors["checkLabels"] && (
                  <span style={{ color: "red", fontSize: 15 }}>{`* ${formErrors["checkLabels"]}`}</span>
                )}

                {benefit.checkLabel.length <= 0 ? (
                  <span style={{ color: "grey", fontSize: 12, whiteSpace: "nowrap" }}>
                    * Digite o checkLabel e pressione Enter para incluí-lo, ou pressione o botão "+"
                  </span>
                ) : (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {benefit.checkLabel.map((label, index) => (
                      <Chip
                        key={index}
                        style={{ margin: 5 }}
                        label={label}
                        // color={wrongLabels.includes(label) ? "error" : "default"}
                        onDelete={() => removeCheckLabel(index)}
                      />
                    ))}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={12}>

                <TextField
                  variant="outlined"
                  id="htmlDescriptionForWorker"
                  name="htmlDescriptionForWorker"
                  fullWidth
                  multiline
                  rows={5}
                  label="Mensagem para os  Usuários"
                  autoComplete="htmlDescriptionForWorker"
                  onChange={(e) =>
                    handleChangeBenefitData({ htmlDescriptionForWorker: e.target.value })
                  }
                  value={benefit.htmlDescriptionForWorker}
                  className="inputdados"
                  size="small"
                />

                {formErrors.htmlDescriptionForWorker && (
                  <S.ErrorMessage>
                    {formErrors.htmlDescriptionForWorker}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} md={12}>

                <TextField
                  variant="outlined"
                  id="htmlDescriptionForWorker"
                  name="htmlDescriptionForWorker"
                  fullWidth
                  multiline
                  rows={5}
                  label="Mensagem para os Gestores"
                  autoComplete="htmlDescriptionForWorker"
                  onChange={(e) =>
                    handleChangeBenefitData({ htmlDescriptionForCompanyAdmin: e.target.value })
                  }
                  value={benefit.htmlDescriptionForCompanyAdmin}
                  className="inputdados"
                  size="small"
                />

                {formErrors.htmlDescriptionForCompanyAdmin && (
                  <S.ErrorMessage>
                    {formErrors.htmlDescriptionForCompanyAdmin}
                  </S.ErrorMessage>
                )}

              </Grid>

              <Grid item xs={12} md={12}>
                <div className="d-flex w-full">

                  <S.CheckBoxContainer>

                    <TextField
                      type="checkbox"
                      style={{ display: "block", border: "0" }}
                      id="code-field"
                      name="code"
                      onChange={toggleIsLink}
                      // @ts-ignore
                      checked={benefit.link}
                      className="inputdados"
                      size="small"
                    />

                    <label htmlFor="code-field" style={{ whiteSpace: "nowrap" }}>
                      Link de acesso?
                    </label>

                  </S.CheckBoxContainer>

                  {benefit.link && (
                    <>
                      <TextField
                        variant="outlined"
                        id="linkText"
                        fullWidth
                        name="linkText"
                        autoFocus
                        label="Link"
                        autoComplete="linkText"
                        onChange={(e) =>
                          handleChangeBenefitData({ linkText: e.target.value })
                        }
                        value={benefit.linkText}
                        className="inputdados"
                        size="small"
                      />

                      {formErrors.linkText && (
                        <S.ErrorMessage>
                          {formErrors.linkText}
                        </S.ErrorMessage>
                      )}
                    </>
                  )}
                </div>

              </Grid>

            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
              <Chip label="Adicionar Beneficio"
                style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",

                }}
                onClick={postBenefit}
              />

            </div>
          </WrapperBack>

        </GridWrapper>
      </SectionWrapper >

    </>
  );

  return (
    <>

      {content}
    </>
  );
};

export default Fornecedores;
