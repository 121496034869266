import styled from "styled-components";
import { IButtonDefault } from "./interface";
import { ifProp, prop } from "styled-tools";

export const Button = styled.button<IButtonDefault>`
  background-color: ${prop("theme.colors.primary")};
  color: ${prop("theme.colors.secondary")};
  width: 70%;
  padding: ${prop("padding")};
  border-radius: ${prop("borderRadius", "30px")};
  border: none;
  font-size: ${prop("fontSize")};
  display: block;
  margin: auto;
  ${ifProp("disabled", "opacity: 0.7;")}
`;
