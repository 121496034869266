import React, { memo } from 'react';

import { BackgroundImage } from './styles';
import { IBackground } from './interfaces'

const Background: React.FC<IBackground> = ({ children }: IBackground) => {
    return (
        <BackgroundImage>
            {children}
        </BackgroundImage>
    )
}
export default memo(Background);