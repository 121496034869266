import styled from "styled-components";
import { ITypography } from "./interface";
import { prop, ifProp } from "styled-tools";

export const TypographyDefault = styled.span<ITypography>`
  font-size: ${prop("fontSize")};
  color: ${prop("fontColor", prop("theme.colors.black"))};
  font-family: ${prop("fontFamily")};
  font-weight: ${prop("fontWeight")};
  line-height: ${prop("lineHeight")};
  text-decoration: none;
  line-clamp: ${ifProp(
    "wrapLine",
    prop(`
        display: -webkit-box;
        -webkit-line-clamp: ${prop("wrapLine")};
        -webkit-box-orient: vertical;  
        overflow: hidden;
      `)
  )};
  text-align: ${prop('textAlign', 'left')};
`;

export const TypographyWrapper = styled.div<ITypography>`
  margin-top: ${prop("marginTop")};
  margin-left: ${prop("marginLeft")};
  margin-right: ${prop("marginRight")};
  margin-bottom: ${prop("marginBottom")};
  padding-bottom: ${prop("paddingBottom")};
  padding-right: ${prop("paddingRight")};
  padding-left: ${prop("paddingLeft")};
  padding-top: ${prop("paddingTop")};
`;
