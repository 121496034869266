import React from 'react';
import { useContext } from 'react';
import { DropdownContext } from '../DropdownContext';
import {Button} from './styles'

const Toggle: React.FC = ({children}) => {
    const {open, setOpen} = useContext(DropdownContext);

    return(
        <Button onClick={() => {setOpen(!open)}}>{children}</Button>
    );
};

export default Toggle;