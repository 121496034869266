import React from 'react';
import { IInputbackground } from './interface';
import { InputBackgroundDefault, Input } from './styles'; 
import Icon from '../Icon/index'

const InputBackground: React.FC<IInputbackground> = (props) => {
    return(
    <Input {...props} />
    )
} 
 
export default InputBackground;