import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography"
import { useHistory, useParams } from "react-router-dom";
import { GridWrapper, SectionTitle, SectionWrapper, Wrapper, WrapperBack, } from "./styles";
import api from "../../../../services/api";
import { Card as BenefitCard } from "../../../../common/components/Card";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { BeneficConfig, Category, SubCategory, Benefit } from "./interfaces";
import { Avatar, Box, Button, Card, Chip, Divider, Drawer, FormControl, FormControlLabel, List, Paper, Radio, RadioGroup, Switch, styled } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import PageSubTitle from "../../../../common/components/PagSubTitle";
import InfoIcon from '@mui/icons-material/Info';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
type Anchor = "right";
function BenefitsAdquirir() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [beneficios, setBeneficios] = useState<BeneficConfig[]>([]);
  const [benefitToBuy, setBenefitToBuy] = useState<Benefit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const theme = useContext(ThemeContext);
  const [cartItems, setCartItems] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('title'); // Ordenar por título inicialmente

  const handleSortRequest = (property: string) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = (data) => {
    return data.sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();

      if (orderDirection === 'asc') {
        return titleA < titleB ? -1 : 1;
      } else {
        return titleA > titleB ? -1 : 1;
      }
    });
  };

  const handleBuyBenefit = async (benefitId: string) => {
    try {
      await api.post(`/product/add-to-company`, {
        benefitId,
        companyId: selectedCompany.id
      });

      alert("Benefício adicionado à empresa!");
      setBeneficios([]);
      setBenefitToBuy([]);
      getProductsToBuy();
      getBeneficios();

      // Remove o benefício do carrinho
      removeFromCart(benefitId);
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os produtos da empresa.");
    } finally {
      setLoading(false);
    }
  };

  const getProductsToBuy = async () => {
    try {
      const { data } = await api.get(`/product/${selectedCompany.id}/store`);
      setBenefitToBuy(data);
    } catch (e) {
      console.log(e);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const addToCart = (benefit) => {
    setCartItems([...cartItems, benefit]);
  };

  const removeFromCart = (benefitId) => {
    setCartItems(cartItems.filter(item => item.id !== benefitId));
  };

  const list = (anchor: Anchor) => (
    <Box style={{ width: "300px" }}>
      <Button
        onClick={toggleDrawer}
        style={{ outline: "none" }}
      >
        <CloseIcon style={{ marginTop: "15px" }} />
      </Button>
      <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
        <h1
          style={{
            fontFamily: 'Poppins',
            fontSize: '28px',
            marginTop: "2%",
            fontWeight: 700,
            lineHeight: '24px',
            textAlign: 'center',
          }}>
          Carrinho de Compra
        </h1>
      </div>
      <Divider />
      <div style={{ padding: '2%' }}>
        {cartItems.map((item, index) => (
          <div key={index} style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '10px', overflow: 'hidden', backgroundColor: '#fff' }}>
            <div style={{ display: "flex", alignItems: "center", padding: "10px 20px" }}>
              <Avatar
                style={{ width: "40px", height: "40px", border: "2px solid #DDDDDD", marginRight: '15px' }}
                src={item.logoUrl}
              />
              <h1
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '16px',
                  fontWeight: 700,
                  margin: 0, // Remove margin para melhor alinhamento
                  flex: 1 // Faz o título ocupar o espaço disponível
                }}
              >
                {item.title}
              </h1>
              <IconButton
                style={{
                  backgroundColor: "#00b118",
                  color: "#fff",
                  fontSize: '10px',
                  width: "20px",
                  height: "20px",
                  fontWeight: 700,
                  textAlign: 'center',
                  padding: "10px",
                  borderRadius: "30px",
                  transition: 'background-color 0.3s',
                }}
                onClick={() => handleBuyBenefit(item.id)}

              >
                <CheckIcon />
              </IconButton>
              <IconButton
                style={{
                  backgroundColor: "#fff",
                  color: "#8b1717",
                  fontSize: '10px',
                  width: "20px",
                  height: "20px",
                  marginLeft: "4%",
                  fontWeight: 700,
                  textAlign: 'center',
                  padding: "10px",
                  borderRadius: "30px",
                  transition: 'background-color 0.3s',
                }}
                onClick={() => removeFromCart(item.id)}

              >
                <CancelIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </Box >
  );

  const toggleActive = async (configId: string) => {
    setIsProcessingRequest(true);

    try {
      const { data } = await api.put(`/benefit/${configId}/toggle-active`);

      setBeneficios(prev => prev.map((b) => {
        if (b.id !== configId) {
          return b;
        }

        return data.data;
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsProcessingRequest(false);
    }
  }

  const getBeneficios = async () => {
    try {
      const params = {
        companyId
      }

      const { data } = await api.get("/benefit-config", { params });

      setBeneficios(data.data);
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  }

  const getSubCategory = (): SubCategory[] => {
    const subcategories = benefitToBuy.map(b => b.BenefitSubcategory);
    // Usando Set para garantir unicidade
    const uniqueSubcategories = Array.from(new Set(subcategories.map(sc => sc.id)))
      .map(id => subcategories.find(sc => sc.id === id));
    return uniqueSubcategories;
  }

  const getProductsByCategory = (categoryId: string): Benefit[] => {
    return benefitToBuy.filter(b => b.benefitSubcategoryId === categoryId);
  }

  const handleRemoveBenefit = async (id: string) => {
    try {
      const { data } = await api.delete(`/product/remove-to-company/${id}`);
      setBeneficios(data.data.data);
    } catch (e) {
      alert("Benefício Removido da empresa!");
      getBeneficios();
      getProductsToBuy();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBeneficios();
    getProductsToBuy();
  }, []);

  const handleNextCategory = () => {
    setCurrentCategoryIndex((prevIndex) => Math.min(prevIndex + 1, getSubCategory().length - 1));
  };

  const handlePrevCategory = () => {
    setCurrentCategoryIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleToggleExpand = (categoryId: string) => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(expandedCategories.filter(id => id !== categoryId));
    } else {
      setExpandedCategories([...expandedCategories, categoryId]);
    }
  };

  const subCategories = getSubCategory();
  const currentCategory = subCategories[currentCategoryIndex];
  const productsByCategory = getProductsByCategory(currentCategory?.id);
  const productsToShow = expandedCategories.includes(currentCategory?.id) ? productsByCategory : productsByCategory.slice(0, 5);

  return (
    <>
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <Button
              onClick={toggleDrawer}
              style={{
                position: "fixed",
                zIndex: 10001,
                right: open ? 250 : 10,
                top: 300,
                background: open ? "transparent" : theme.colors.primary,
                color: theme.colors.primaryContrast,
                outline: "none",
                backgroundColor: theme.colors.primary,
              }}
            >
              {open ? null : <ShoppingCartOutlinedIcon />}
            </Button>

            {benefitToBuy.length > 0 ? (
              <div>
                {currentCategory && (
                  <div key={currentCategory.id}>
                    <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "3%", paddingBottom: "2%", marginRight: "2%" }}>
                      <PageTitle
                        title={currentCategory.name}
                        subtitle="Aqui estão os benefícios que podem ser adquiridos separados por categorias"
                      />
                      <Stack direction="row" spacing={1}>
                        <Chip label="Novo Beneficio" style={{
                          backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                          fontSize: "10px",
                          padding: "7px 7px",
                          fontWeight: "bold",
                          marginRight: "2%"
                        }}
                          onClick={() => history.push(`/novos-beneficios`)} />
                        <Chip label="Novo Banner" style={{
                          backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                          fontSize: "10px",
                          padding: "7px 7px",
                          fontWeight: "bold",
                          marginRight: "2%"
                        }}
                          onClick={() => history.push(`/novos-banners`)} />
                        <Chip label="Novo Parceiro"
                          style={{
                            backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                            fontSize: "10px",
                            padding: "7px 7px",
                            fontWeight: "bold",
                          }}
                          onClick={() => history.push('/novos-parceiros')}
                        />
                      </Stack>
                    </div>

                    <GridWrapper>
                      {sortedData(productsToShow).map(b => (
                        <Wrapper key={b.id}>
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <IconButton onClick={() => history.push(`/beneficio/${b.code}`)}>
                              <InfoIcon
                                style={{
                                  color: theme.colors.primary,
                                  width: "20px",
                                  height: "20px"
                                }}
                              />
                            </IconButton>
                          </div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <Avatar style={{ width: "100px", height: "100px", border: "1px solid #e0e0e0" }} src={b.logoUrl || ""} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", marginTop: "4%" }}>
                            <h1 style={{
                              fontSize: '16px',
                              fontFamily: "inherit",
                              fontWeight: 700,
                              lineHeight: '24px',
                              color: theme.colors.primary
                            }}>{b.title}</h1>
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", marginTop: "2%", marginRight: "2%", }}>
                            <IconButton
                              style={{
                                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                                fontSize: '12px',
                                width: "100%",
                                marginBottom: "2%",
                                fontWeight: 700,
                                lineHeight: '24px',
                                textAlign: 'center',
                                padding: "7px 7px",
                                borderRadius: "30px",
                              }}
                              onClick={() => addToCart(b)}
                            >
                              <ShoppingCartOutlinedIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </div>
                        </Wrapper>
                      ))}
                    </GridWrapper>

                    {productsByCategory.length > 5 && (
                      <div style={{ display: "flex", justifyContent: "center", width: "100%", zIndex: 1001, marginTop: "1%" }}>
                        <IconButton
                          style={{ backgroundColor: theme.colors.primary, }}
                          onClick={() => handleToggleExpand(currentCategory.id)}>
                          {expandedCategories.includes(currentCategory.id) ? <ArrowDropUpIcon style={{ color: theme.colors.primaryContrast }} /> : <ArrowDropDownIcon style={{ color: theme.colors.primaryContrast }} />}
                        </IconButton>
                      </div>
                    )}
                  </div>
                )}

                <Drawer anchor="right" open={open} onClose={toggleDrawer} style={{ backgroundColor: "transparent" }}>
                  {list("right")}
                </Drawer>
              </div>
            ) : (
              <Panel borderRadius="20px" padding="18px" marginBottom="61px" paddingBottom="30px">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography fontSize="16px" marginBottom="5px" marginTop="20px" fontWeight="400" fontColor="#505050">
                    Nenhum benefício disponível para ativação.
                  </Typography>
                </div>
              </Panel>
            )}
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

      {/* Botões de navegação fixos */}
      <IconButton
        style={{
          position: "fixed",
          top: "50%",
          left: 290,
          transform: "translateY(-50%)",
          backgroundColor: theme.colors.primary,
          color: theme.colors.primaryContrast,
          zIndex: 10002,
        }}
        onClick={handlePrevCategory}
        disabled={currentCategoryIndex === 0}
      >
        <ArrowBackIosIcon />
      </IconButton>

      <IconButton
        style={{
          position: "fixed",
          top: "50%",
          right: 30,
          transform: "translateY(-50%)",
          backgroundColor: theme.colors.primary,
          color: theme.colors.primaryContrast,
          zIndex: 10002,
        }}
        onClick={handleNextCategory}
        disabled={currentCategoryIndex === subCategories.length - 1}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </>
  );
}

export default BenefitsAdquirir;