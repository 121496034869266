import styled from "styled-components";
import { prop } from "styled-tools";
import { IModal } from "./interface";

export const Wrapper = styled.div<IModal>`
  position: fixed;
  top: 50%;
  left: 5%;
  width: 90%;
  transform: translateY(-50%);
  max-height: 90%;
  z-index: 700;
  outline: 0;
`;
export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.411);
  z-index: 500;
`;
export const StyledModal = styled.div`
  position: relative;
  width: 50%;
  z-index: 100;
  height: 100%;
  background: white;
  margin: auto;
  border-radius: 30px;
  padding-left: 40px;
  padding-right: 39px;
  padding-top: 20px;
  padding-bottom: 46px;
  @media (max-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;
export const Header = styled.div`
  justify-content: center;
`;
export const HeaderText = styled.div`
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
`;
export const CloseButton = styled.button`
  position: absolute;
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  right: 10px;
  top: 10px;
  background: none;
  outline: none;
  -moz-outline: none;
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
