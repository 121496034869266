import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { useHistory, useParams } from "react-router-dom";
import { GridWrapper, SectionTitle, SectionWrapper, WrapperBack, } from "./styles";
import api from "../../../../services/api";
import { Paper } from "@material-ui/core";
import { Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import { ThemeContext } from "styled-components";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import List from "../../../../common/components/DropdownDefault/List";
import { Item } from "../../../../common/components/DropdownDefault/Items/styles";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import Icon from "../../../../common/components/Icon";
import DisableModal from "../Colab/DisableModal";
import EnableModal from "../Colab/EnableModal";

interface Worker {
  id: string;
  dependent: string;
  phone: string;
  cpf: string;
  birth: string;
  firstName: string;
  gender: string;
  lastName: string;
  email: string;
  canAccess: boolean;

}


function Dependentes() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const { id } = useParams<{ id: string }>();
  const [worker, setWorker] = useState<Worker[]>([]);
  const [disablingId, setDisablingId] = useState<string | null>();
  const [enablingId, setEnablingId] = useState<string | null>();
  const theme = useContext(ThemeContext);
  const isMobileScreen = useMediaQuery('only screen and (max-width: 768px)');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getPacks = async () => {
    try {
      const { data } = await api.get(`/dependentes/${id}`);
      setWorker(data.data);
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPacks();
  }, [id]);


  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Dependentes Cadastrados"
          subtitle="Aqui você pode gerenciar os dependentes cadastrado pelo seu colaborador"
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          <TableContainer component={Paper} style={{ minHeight:700, maxHeight: 700, overflow: 'auto' }}>
            <Table stickyHeader>
              {/* Cabeçalhos das colunas */}
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Dependente</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>CPF</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Acesso</TableCell>
                  <TableCell align="center" style={{ fontSize: '16px', fontWeight: 'bold' }}>Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {worker.map((b) => (
                  <TableRow key={b.id}>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.firstName} {b.lastName}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.email}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                      {b.cpf}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>
                   
                    {b.canAccess ? (
                            <Chip
                              label="Ativo"
                              style={{
                                backgroundColor: "#3FBE3F",
                                color: "#fff",
                                width: "50px",
                                fontSize: "10px",
                                height: "20px",
                                fontWeight: "bold",
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#3FBE3F",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            />
                          ) : (
                            <Chip
                              label="Inativo"
                              style={{
                                backgroundColor: "#ab0303",
                                color: "#fff",
                                width: "60px",
                                fontSize: "10px",
                                height: "20px",
                                fontWeight: "bold",
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#ab0303",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            />
                          )}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '14px' }}>

                    <Dropdown>
                            <Toggle>
                              <Icon name="more-vertical" />
                            </Toggle>
                            <List>
                              {b.canAccess ? (
                                <Item>
                                  <Button onClick={() => setDisablingId(b.id)}>Desabilitar</Button>
                                </Item>
                              ) : (
                                <Item>
                                  <Button onClick={() => setEnablingId(b.id)}>Habilitar</Button>
                                </Item>
                              )}
                            </List>
                          </Dropdown>
                          </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </WrapperBack>
      </GridWrapper>
      <DisableModal
        isShown={!!disablingId}
        hide={() => setDisablingId(null)}
        id={disablingId}
        refresh={getPacks}
      />
      <EnableModal
        isShown={!!enablingId}
        hide={() => setEnablingId(null)}
        id={enablingId}
        refresh={getPacks}
      />
    </SectionWrapper>
  );
}

export default Dependentes;