import { ReactNode, useEffect, useState } from 'react';
import { PaginatorProps } from './interface';

const Paginator = <TEntity, TData>(props: PaginatorProps<TEntity, TData> & { children?: ReactNode })  => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [lastLoadedPage, setLastLoadedPage] = useState<number>(0);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const offset = currentPage * props.pageSize;

    const connection = props.queryResult.data ? props.extractConnection(props.queryResult.data) : null;

    const loading = props.queryResult.loading || !connection;
    const edges = connection?.edges || [];
    const items = edges.slice(offset, props.pageSize + offset).map((edge) => edge.node);
    const pageInfo = connection?.pageInfo;
    const hasMoreToFetch = pageInfo?.hasNextPage;
    useEffect(() => {
        setLastLoadedPage(connection
            ? Math.ceil(connection.edges.length / props.pageSize) - 1
            : 0
        );
        props.onChange?.(items)
    }, [connection, props.pageSize]);

    useEffect(() => {
        setCurrentPage(0);
    }, [props.queryResult.variables])

    const fetchMore = async () => {
        if (!hasMoreToFetch) {
            return;
        }
        setIsLoadingMore(true);
        await props.queryResult?.fetchMore({ variables: {
            first: props.pageSize,
            after: pageInfo?.endCursor,
        }})
        setIsLoadingMore(false);
    }

    const handleNextPage = async () => {
        const nextPage = currentPage + 1;
        if (currentPage < lastLoadedPage) {
            setCurrentPage(nextPage);
            return;
        }

        if (!hasMoreToFetch) {
            return;
        }

        await fetchMore();
        setCurrentPage(nextPage);
    }

    const handlePrevPage = async () => {
        const prevPage = currentPage - 1;
        if (!currentPage) {
            return;
        }

        setCurrentPage(prevPage);
    }

    return props.render({handleNextPage, handlePrevPage, items, paginationState: {
      loaded: !loading,
      currentPage,
      isLoadingMore,
      disablePrev: loading || isLoadingMore || !currentPage,
      disableNext: loading || isLoadingMore || (!hasMoreToFetch && currentPage === lastLoadedPage),
      from: items.length ? offset + 1 : 0,
      to: offset + items.length,
      total: pageInfo?.totalEdges || 0,
    }});
}
export default Paginator;