import {gql} from '@apollo/client'

export const MUTATION_DELETE_NOTIFICATIONS = gql` 

mutation deleteNotifications($id: ID!) {
  deleteWorkerNotification(id: $id) {
    ... on EmptySuccess {
      success
    }
    ... on NotFoundError {
      message
    }
    ... on UnauthorizedError {
      message
    }
    ... on TemporaryError {
      message
    }
    ... on ErrorWithMessage {
      message 
    }
  }
}
`
