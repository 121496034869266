import React, { useState } from "react";
import TabTitle from "../TabTitle";
import { ITabs } from "./interface";
import { StyledTabs, List } from "./styles";

const Tabs: React.FC<ITabs> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <StyledTabs>
      <List>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setActiveIndex={setActiveIndex}
            isActive={index === activeIndex}
          />
        ))}
      </List>
      {children[activeIndex]}
    </StyledTabs>
  );
};

export default Tabs;
