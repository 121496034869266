import { gql } from "@apollo/client";

export const FRAGMENT = gql`
    fragment CompanyAdminFields on CompanyAdmin {
    __typename
    id
    createdAt
    firstName
    lastName
    email
    createdAt
    company {
      __typename
      ... on Company {
        id
        name
      }
    }
    department
    role
    canAccess
  }

`

export const ME_QUERY = gql`
    query profile(
        $id: ID!
    ){
        companyAdmin(companyAdminId: $id){
            __typename
            ... on CompanyAdmin {
                ...CompanyAdminFields
            }
            ... on ErrorWithMessage {
                message
            }
        }
    }
${FRAGMENT}
`

export const PROFILE_UPDATE_MUTATION = gql`
  mutation UpdateProfile(
    $input: UpdateCompanyAdminInput!
  ){
    updateCompanyAdmin(companyAdminData: $input){
      __typename 
      ... on CompanyAdmin {
        ...CompanyAdminFields
      }
        ... on ErrorWithMessage {
          message
        }
        ... on ErrorWithInputPath {
          inputPath
        }
        ... on ErrorWithValidationRule {
          validationRule
        }
      }
    }

  ${FRAGMENT}
  `