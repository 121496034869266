import React from 'react';
import { Button } from './styles';
import { IButtonDefault } from './interface';



const ButtonDefault: React.FC<IButtonDefault> = (props) => {
    return(
        <Button {...props}>
            {props.text}
        </Button>

    )

}

export default ButtonDefault;