import React, { memo, useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Background, Image } from "../../../../common/components";
import { TitleBox, ShowPassword, InputWrapper, ConfirmPassword } from "./styles";
import { Panel } from "../../../../common/components/Panel";
import Icon from "../../../../common/components/Icon";
import Input from "../../../../common/components/Input";
import { IFormChangePassword } from "./interface";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Helmet from 'react-helmet'
import logo from "../../../../assets/img/logo.png";
import Typography from "../../../../common/components/Typography";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import { useHistory } from "react-router-dom";

import { useLocation } from "react-router";
import AuthContext from "../../../../common/components/hooks/Auth";
import api from "../../../../services/api";

const schema = yup.object().shape({
  password: yup.string().min(6).required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null]),
});

const ForgetPassword: React.FC = () => {
  <Helmet title="Alterar Senha - PeopleHub" />
  const [isLoading, setIsLoading] = useState(false);

  const [visiblePassword, setVisiblePassword] = useState(false);

  const { authenticate } = useContext(AuthContext);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [email, setEmail] = useState(params.get("email") || "");
  const token = params.get("token");

  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormChangePassword>({
    resolver: yupResolver(schema),
  });

  const onSubmitSetPassword = async (data: any) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const body = {
        password: data.password,
        companyAdminEmail: email || "",
        passwordChangeToken: token ?token.replaceAll(" ", "+") : "",
      };

      await api.put("/company-admin/reset-passoword", body);

      alert("Senha definida com sucesso!")

      const result = await authenticate(email || "", data.password)
      history.push(result ? "/home" : "/login");
      return;
    } catch (e) {
      let message = "Não foi possível definir sua senha. Tente novamente!";

      if (e?.response?.data?.error) {
        message = e?.response?.data?.error;
      }

      alert(message);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Background>
      <Container style={{ height: "100%", paddingTop: 50, paddingBottom: 30 }}>
        <Row>
          <Col>
            <Image url={logo} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <TitleBox>
              <Typography fontSize="40px" fontColor="#ffffff" fontWeight="500">
                UM SISTEMA DE RH
              </Typography>

              <Typography fontSize="32px" fontColor="#ffffff" fontWeight="300">
                COMPLETO PARA VOCÊ
              </Typography>
            </TitleBox>
          </Col>
          <Col xs={12} lg={6}>
            <Panel
              borderRadius="50px"
              paddingBottom="72px"
              paddingTop="72px"
              paddingLeft="45px"
              paddingRight="45px"
            >
              <Container style={{ marginBottom: "32px" }}>
                <Typography
                  fontSize="32px"
                  fontColor="#000000"
                  fontWeight="300"
                >
                  Recuperar senha
                </Typography>
              </Container>
              <form onSubmit={handleSubmit(onSubmitSetPassword)}>
                <Container style={{ marginBottom: "30px" }}>
                  <InputWrapper>
                    <Input
                      // disabled={true}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="E-mail"
                      type="email"
                      required
                      value={email}
                    />
                  </InputWrapper>
                  <Container style={{ marginBottom: "25px" }}></Container>
                  <div style={{ display: "flex" }}>
                    <InputWrapper>
                      <Input
                        disabled={isLoading ? true : false}
                        placeholder="Senha"
                        type={visiblePassword ? "text" : "password"}
                        register={register("password")}
                        required
                      ></Input>
                    </InputWrapper>
                    <ShowPassword
                      type="button"
                      onClick={() => {
                        setVisiblePassword(visiblePassword ? false : true);
                      }}
                    >
                      <Icon name="eye" />
                    </ShowPassword>
                  </div>
                  <Typography
                    fontColor="#ff0000"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    {errors.password?.message}
                  </Typography>
                  <ConfirmPassword>
                    <Input
                      disabled={isLoading ? true : false}
                      placeholder="Confirmar senha"
                      type={visiblePassword ? "text" : "password"}
                      register={register("passwordConfirmation")}
                      required
                    ></Input>
                  </ConfirmPassword>
                  <Typography
                    fontColor="#ff0000"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    {errors.passwordConfirmation?.message}
                  </Typography>
                </Container>
                <Container style={{ marginBottom: "120px" }}></Container>
                <Container>
                  <PrimaryButton
                    onClick={() => { }}
                    disabled={isLoading ? true : false}
                    text="Enviar"
                  ></PrimaryButton>
                </Container>
              </form>
            </Panel>
          </Col>
        </Row>
      </Container>
    </Background>
  );
};
export default memo(ForgetPassword);
