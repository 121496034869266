import { gql } from "@apollo/client";
import { FRAGMENT_ACTION, FRAGMENT_BENEFIT, FRAGMENT_BENEFIT_CATEGORY, FRAGMENT_BENEFIT_CHECKOUT_CARD, FRAGMENT_BENEFIT_COMPANY_CONFIG, FRAGMENT_BENEFIT_SUBCATEGORY, FRAGMENT_PARTNER } from "../../../../modules/Sections/screens/Benefits/graphql";

export const MUTATION_GENERATE_TOKEN = gql`
  mutation GenerateToken (
    $email: String!
    $password: String!
  ) {
    createToken(
      companyAdminEmail: $email
      companyAdminPassword: $password
    ) {
      __typename
      ... on CreatedToken {
        secretJwt
        selectedCompanyId
      }
      ... on ErrorWithMessage {
        message
      }
      ... on ErrorWithInputPath {
        inputPath
      }
      ... on ErrorWithValidationRule {
        validationRule
      }
    }
  }
`;

export const MUTATION_REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      __typename
      ... on CreatedToken {
        secretJwt
        selectedCompanyId
      }
      ... on ErrorWithMessage {
        message
      }
    }
  }
`;

export const MUTATION_SELECT_COMPANY = gql`
  mutation SelectCompany($id: ID!) {
    setSelectedCompany(id: $id) {
      __typename
      ... on CreatedToken {
        secretJwt
        selectedCompanyId
      }
      ... on ErrorWithMessage {
        message
      }
    }
  }
`;

export const FRAGMENT_THEME = gql`
  fragment ThemeFragment on CompanyTheme {
    __typename
    primaryColorHex
    primaryContrastColorHex
    secondaryColorHex
    secondaryContrastColorHex
    titleColorHex
    textColorHex
    logoUrl
  }
`;

export const FRAGMENT_ME = gql`
  fragment MeFragment on CompanyAdmin {
    __typename
    id
    firstName
    lastName
    email
    department
    role
    company {
      __typename
      ... on Company {
        id
        name
        hasChildren
        childrenCompanies {
          __typename
          id
          name
          hasChildren
        }
        availableProducts {
          __typename
          id
        }
        microApps {
          __typename
          beneficioFacilUrl
        }
        __typename
        theme {
          ...ThemeFragment
        }
      }
    }
  }
  ${FRAGMENT_THEME}
`;

export const QUERY_ME = gql`
  query me {
    me {
      __typename
      ... on CompanyAdmin {
        ...MeFragment
      }
      ... on ErrorWithMessage {
        message
      }
    }
  }

  ${FRAGMENT_ME}
`;

export const SELECTED_COMPANY_FRAGMENT = gql`
  fragment SelectedCompanyFragment on Company {
    id
    name
    hasChildren
    theme {
      ...ThemeFragment
    }
    benefitConfigs(filter: { active: true, visibleToCompanyAdmins: true }) {
      ...BenefitCompanyConfigFragment
    }
  }
  ${FRAGMENT_THEME}
  ${FRAGMENT_BENEFIT_COMPANY_CONFIG}
  ${FRAGMENT_BENEFIT}
  ${FRAGMENT_PARTNER}
  ${FRAGMENT_ACTION}
  ${FRAGMENT_BENEFIT_CATEGORY}
  ${FRAGMENT_BENEFIT_SUBCATEGORY}
  ${FRAGMENT_BENEFIT_CHECKOUT_CARD}
`;

export const QUERY_SELECTED_COMPANY = gql`
  query selectedCompany($id: ID!) {
    company(companyId: $id) {
      __typename
      ... on Company {
        ...SelectedCompanyFragment
      }
      ... on ErrorWithMessage {
        message
      }
    }
  }
  ${SELECTED_COMPANY_FRAGMENT}
`;
