import styled from "styled-components";

export const Lists = styled.div`
  background-color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 4%;
  width: 200px;
  border-radius: 5px;
  line-height: 10px;
  z-index: 999;
`;
