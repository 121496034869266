import {gql} from '@apollo/client'

export const FRAGMENT_NOTIFICATIONS = gql`
fragment workerNotificationFields on WorkerNotification {
  id
 companyId
 createdAt
 title
 subtitle
 sent
}
`

 export const QUERY_NOTIFICATIONS = gql`
 query workerNotifications(
  $first:Int!
  $after:String
  $companyId: ID!
  $minCreatedAt: String
  $maxCreatedAt: String
) {
  workerNotifications(
  first: $first 
  after: $after 
  companyId: $companyId 
  minCreatedAt: $minCreatedAt 
  maxCreatedAt: $maxCreatedAt
  ) {
    __typename
    ... on WorkerNotificationsConnection {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
        totalEdges
      }
      edges {
        cursor
        node {
          __typename 
            ... on WorkerNotification {
              ... workerNotificationFields
            }
        }
      }
    }
    ... on ErrorWithMessage {
      message
    }
    ... on WorkerNotificationsConnection {
      edges {
        cursor
        node {
          ... workerNotificationFields
        }
      }
    }
  }
}
${FRAGMENT_NOTIFICATIONS}
 `


 export const NOTIFICATION_LIST = gql`
 query WorkerNotificationsList (
  $first: Int!
  $companyId:ID!
) {
  workerNotifications (first:$first companyId: $companyId) {
    __typename
    ... on WorkerNotificationsConnection {
      edges {
        cursor
        node {
          ... workerNotificationFields
        }
      }
    }
  }
}
  ${FRAGMENT_NOTIFICATIONS}
 `

