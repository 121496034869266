import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";
import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";

import { BenefitCategory, BenefitSubcategory, Partner } from "./interfacess";
import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Beneficios } from "./interfaces";
import BannerUpload from "./BannerUpload";
import { ThemeContext } from "styled-components";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";
import CancelIcon from '@mui/icons-material/Cancel';

const EditarBanner: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imagesUrls, setImagesUrls] = useState<{ id: string, imageUrl: string }[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [benefitLogo, setBenefitLogo] = useState<File>(null);
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const { id } = useParams<{ id: string }>();
  const getBenefitBanners = async () => {
    try {
      const { data } = await api.get(`/benefit/banner/${id}`);
      const urls = data.data.BenefitCarouselImage.map((image: any) => ({
        id: image.id,
        imageUrl: image.imageUrl
      }));
      setImagesUrls(urls);
    } catch (error) {
      console.error("Erro ao obter benefícios:", error);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBenefitBanners();
  }, [id]);

  const deleteBenefit = async (imageId: string) => {
    setLoading(true);
    try {
      await api.delete(`/delete/banner/${imageId}`);
      setImagesUrls(prevImages => prevImages.filter(image => image.id !== imageId));
      alert("Banner deletado com sucesso!");
    } catch (error) {
      console.error("Erro ao deletar o banner:", error);
      alert("Erro ao deletar o banner.");
    } finally {
      setLoading(false);
    }
  }

  const postBenefit = async (imageId: string) => {
    try {
      const formData = new FormData();
      if (benefitLogo) {
        formData.append("file", benefitLogo);
      }

      await api.put(`/banner/update/${imageId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      alert("Benefício atualizado com sucesso!");
      history.push("/beneficios");
    } catch (error) {
      console.error("Erro ao atualizar benefício:", error);
      alert("Erro ao atualizar o benefício.");
    }
  }

  const selectImage = (index: number) => {
    setSelectedImageIndex(index);
  }

  const renderBannerUpload = () => {
    if (imagesUrls.length > 0) {
      return (
        <BannerUpload
          onChangeImage={setBenefitLogo}
          images={[imagesUrls[selectedImageIndex]?.imageUrl]} // Passa a URL da imagem selecionada se houver uma imagem disponível
        />
      );
    } else {
      return (
        <BannerUpload
          onChangeImage={setBenefitLogo}
          images={[]} // Passa um array vazio se não houver imagens disponíveis
        />
      );
    }
  }

  const content = loading ? (
    <Loading />
  ) : (
    <SectionWrapper>
      <div style={{ paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%" }}>
        <PageTitle
          title="Editar Banner"
          subtitle="Aqui você pode editar o Banner do Benefício."
        />
      </div>
      <GridWrapper>
        <WrapperBack>
          <div style={{width:"95%", display: "flex", justifyContent: "flex-end", marginTop: "1%", marginBottom: "2%" }}>
            <Stack direction="row" spacing={1}>
              <Chip label="Novo Beneficio" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"
              }} onClick={() => history.push(`/novos-beneficios`)} />
              <Chip label="Novo Banner" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"
              }} onClick={() => history.push(`/novos-banners`)} />
              <Chip label="Novo Parceiro" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
              }} onClick={() => history.push('/novos-parceiros')} />
            </Stack>
          </div>
          <div style={{ width:"100%", display:"flex", justifyContent:"center"}}>
            {imagesUrls.map((image, index) => (
              <div key={image.id} style={{ marginRight: "1%" }}>
                <Button
                  onClick={() => selectImage(index)}
                  variant={selectedImageIndex === index ? "contained" : "outlined"}
                  style={{
                    fontSize: '12px',
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    marginTop: "2%",
                    fontWeight: 700,
                    lineHeight: '24px',
                  }}
                >
                  Imagem {index + 1}
                </Button>
                <IconButton onClick={() => deleteBenefit(image.id)}>
                  <CancelIcon />
                </IconButton>
              </div>
            ))}
          </div>
          <Grid container spacing={2} style={{ padding: "1rem" }}>
            <Grid item xs={12}>
              {renderBannerUpload()}
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
            <Chip label=" Editar Banner" style={{
              backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
              fontSize: "10px",
              padding: "7px 7px",
              fontWeight: "bold",
              marginRight: "2%"
            }} onClick={() => postBenefit(imagesUrls[selectedImageIndex]?.id)} />
          </div>
        </WrapperBack>
      </GridWrapper>
    </SectionWrapper>
  );

  return <>{content}</>;
};

export default EditarBanner;
function setError(arg0: string) {
  throw new Error("Function not implemented.");
}
