import { useContext, useState } from "react";
import { BenefitContext } from "../../hooks/Benefit";
import Icon from "../../Icon";
import Input from "../../Input";
import PrimaryButton from "../../PrimaryButton";
import Textarea from "../../Textarea";
import Typography from "../../Typography";
import { CartContainer, CloseButton, Contact, ContactFields, FabContainer, Footer, Form, Header, Product, ProductDescription, ProductHeader, ProductImage, ProductRemove, Products, ProductSubtitle, ProductTitle, Sidebar, Title } from "./styles";
import * as yup from 'yup';
import { InferType } from 'yup';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthContext from "../../hooks/Auth";
import Fab from "../Fab";
import ReactInputMask from "react-input-mask";
import SecondaryButton from "../../SecondaryButton";

const contactSchema = yup.object().shape({
  name: yup.string().required().min(3).max(255),
  email: yup.string().required().email("Por favor, informe um e-mail válido"),
  phone: yup.string().required().test({
    name: 'phone',
    test(value, ctx) {
      if (value.replace(/\D/g, '').length !== 11) {
        return ctx.createError({ message: 'Telefone deve ter 11 dígitos' })
      }
      return true
    }
  }),
  message: yup.string().max(140),
});

const SidebarComponent: React.FC = () => {
  const benefitContext = useContext(BenefitContext);
  const [contactShown, setContactShown] = useState(false);
  const authContext = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const onSubmit: SubmitHandler<InferType<typeof contactSchema>> = async (data) => {
    if (benefitContext.finishingRequest) {
      return;
    }

    const success = await benefitContext.finishCompanyBenefitRequest({
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
    });

    alert(success ? "Cotação enviada com sucesso!" : "Erro ao enviar a cotação. Tente novamente.");

    if (success) {
      reset();
    }
  };

  return (
    <Sidebar>
      <CartContainer>
        <Form onSubmit={handleSubmit(onSubmit)} className={`${benefitContext.isSidebarOpen && 'open'}`}>
          <Header>
            <Title>Cotação</Title>
            <CloseButton onClick={() => benefitContext.setIsSidebarOpen(false)} type="button">
              <Icon name="close" />
            </CloseButton>
          </Header>
          <Products style={contactShown ? { display: 'none'} : undefined}>
            {benefitContext.draftCompanyBenefitRequest?.benefits.map((benefit) => (
              <Product key={benefit.id}>
                <ProductHeader>
                  <ProductImage src={benefit.logoUrl} />
                  <div>
                    <ProductTitle>{benefit.title}</ProductTitle>
                    <ProductSubtitle>{benefit.subtitle}</ProductSubtitle>
                  </div>
                  <ProductRemove
                    type="button"
                    onClick={() => benefitContext.removeBenefitFromRequestDraft(benefit.id)}
                    disabled={benefitContext.changingDraft}
                  >
                    <Icon name="close" height="18px" width="18px" />
                  </ProductRemove>
                </ProductHeader>
                <ProductDescription>{benefit.shortDescription}</ProductDescription>
              </Product>
            ))}
          </Products>
          <Contact style={!contactShown ? { display: 'none'} : undefined}>
            <Typography fontWeight="400" fontSize="14px" fontColor="#505050">
              Com quem devemos entrar em contato para prosseguir com a cotação?
            </Typography>
            <ContactFields>
              <div>
                <Controller
                  control={control}
                  name="name"
                  defaultValue={authContext.companyAdmin ? (authContext.companyAdmin.firstName + ' ' + authContext.companyAdmin.lastName) : ""}
                  render={({ field }) => (
                    <Input {...field} placeholder="Nome" disabled={benefitContext.finishingRequest} />
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.name?.message}
                </Typography>
              </div>
              <div>
                <Controller
                  control={control}
                  name="phone"
                  defaultValue=""
                  render={({ field }) => (
                    <ReactInputMask
                      {...field}
                      mask="(99) 9 9999-9999"
                      disabled={benefitContext.finishingRequest}
                    >
                      {(inputProps : any) =>
                        <Input {...inputProps} placeholder="Telefone" disabled={benefitContext.finishingRequest} />
                      }
                    </ReactInputMask>
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.phone?.message}
                </Typography>
              </div>
              <div>
                <Controller
                  control={control}
                  name="email"
                  defaultValue={authContext.companyAdmin?.email || ""}
                  render={({ field }) => (
                    <Input {...field} placeholder="E-mail" disabled={benefitContext.finishingRequest} />
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.email?.message}
                </Typography>
              </div>
              <div>
                <Controller
                  control={control}
                  name="message"
                  defaultValue=""
                  render={({ field }) => (
                    <Textarea {...field} placeholder="Mensagem" disabled={benefitContext.finishingRequest} style={{ minHeight: 60 }} />
                  )}
                />
                <Typography fontSize="15px" fontWeight="300" fontColor="red">
                  {errors.message?.message}
                </Typography>
              </div>
            </ContactFields>
          </Contact>

          <Footer>
            {!contactShown ? (
              <PrimaryButton
                type="button"
                fontSize="14px"
                disabled={benefitContext.changingDraft}
                onClick={() => setContactShown(true)}
                key="next-button"
                padding="5px"
              >
                Iniciar cotação
              </PrimaryButton>
            ) : (
              <>
                <SecondaryButton
                  type="button"
                  fontSize="14px"
                  disabled={benefitContext.changingDraft}
                  key="back-button"
                  onClick={() => setContactShown(false)}
                  padding="5px"
                >
                  Voltar
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  fontSize="14px"
                  disabled={benefitContext.changingDraft}
                  key="submit-button"
                  padding="5px"
                >
                  Finalizar cotação
                </PrimaryButton>
              </>
            )}
          </Footer>
        </Form>
      </CartContainer>
      <FabContainer>
        <Fab />
      </FabContainer>
    </Sidebar>
  )
};

export default SidebarComponent;