import React, { useContext, useEffect, useState } from "react";
import { Panel } from "../../../../common/components/Panel";
import PageTitle from "../../../../common/components/PageTitle";
import { Wrapper, ButtonWrapper, Button, SectionWrapper, GridWrapper, WrapperBack } from "./styles";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import {
  Table,
  Theader,
  TH,
  Tr,
} from "../../../../common/components/Table/styles";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import Item from "../../../../common/components/DropdownDefault/Items";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import Icon from "../../../../common/components/Icon";
import List from "../../../../common/components/DropdownDefault/List";
import Pagination from "../../../../common/components/Pagination";
import { format as formatCnpj } from "cnpj";
import { OperationVariables, QueryResult, useQuery } from "@apollo/client";
import { COMPANIES_QUERY } from "./graphql";
import { companies, companiesVariables } from "./types/companies";
import Paginator from "../../../../common/components/Paginator";
// import * as yup from "yup";
import Helmet from "react-helmet";
import CompaniesModal from "./Modal";
import DeleteModal from "./DeleteModal";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import NoResult from "../../../../common/components/NoResult";
import { TableWrapper } from "../Colab/styles";
import api from "../../../../services/api";
import { Company } from "./interfaces";
import { Chip, Paper, TablePagination } from "@material-ui/core";
import { ThemeContext } from "styled-components";

const pageSize = 10;
const Companies: React.FC = () => {
  const context = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const companyId = context.companySelect.selectedValue;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [updatingId, setUpdatingId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const getCompanies = async () => {
    try {
      const params: any = { page: page + 1 };
      if (companyId) {
        params.companyId = companyId;
      }

      const { data } = await api.get("/company", { params });

      setCompanies(data.data);
      setTotal(data.total);
    } catch (e) {
      console.log(e);
    }
  };

  const refreshTable = () => {
    setPage(0);
    setCompanies([]);
    getCompanies();
  };

  const isDisabled = (company: Company): boolean => {
    return company._count.Worker > 0 || company._count.WorkerInvite > 0;
  };

  useEffect(() => {
    getCompanies();
  }, [page, companyId]);

  return (
    <>


      <CompaniesModal
        id={updatingId}
        isShown={!!updatingId || isCreating}
        refresh={refreshTable}
        hide={() => {
          setUpdatingId(null);
          setIsCreating(false);
        }}
      />

      <DeleteModal
        id={deletingId}
        isShown={!!deletingId}
        refresh={refreshTable}
        hide={() => {
          setDeletingId(null);
        }}
      />

      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
          <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "3%", marginRight:"2%", width:"100%"}}>
              <PageTitle
                title="Empresas"
                subtitle="Aqui você pode cadastrar, listar e editar as empresas que utilizam os benefícios PeopleClub."
              />

              <div style={{ display: "flex", justifyContent: "end", marginTop: "1%", marginBottom: "5%", marginRight: "2%" }}>


                <Chip label="Nova Empresa" style={{
                  backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                  fontSize: "10px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                  marginRight: "2%"

                }}
                  onClick={() => setIsCreating(true)}
                />
              </div>
            </div>
            <br />

            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <Theader style={{
                      color: theme.colors.title,
                    }}
                    >Nome</Theader>
                    <Theader style={{
                      color: theme.colors.title,
                    }}
                    >CNPJ</Theader>
                    <Theader style={{
                      color: theme.colors.title,
                    }}
                    > Usuários</Theader>
                    <Theader style={{
                      color: theme.colors.title,
                    }}
                    >Ações</Theader>

                  </tr>
                </thead>

                <tbody>
                  {companies.map((row) => (
                    <Tr>
                      <TH>{row.name
                        ? row.name
                        : row.companyName || "-"}</TH>
                      <TH>
                        {row.cnpj ? formatCnpj(row.cnpj) : "-"}
                      </TH>
                      <TH>
                        {row._count.Worker || "-"} cadastrados
                        <br />
                        {row._count.WorkerInvite || "-"} convidados
                      </TH>

                      <TH>
                        <Dropdown>
                          <Toggle>
                            <Icon name="more-vertical" />
                          </Toggle>
                          <List>
                            <Item onClick={() => setUpdatingId(row.id)}>
                              <Button>
                                Editar empresa
                              </Button>
                            </Item>
                            <Item disabled={isDisabled(row)} onClick={() =>
                              isDisabled(row)
                                ? alert(
                                  "Essa empresa não pode ser excluída pois possui convites, colaboradores ou gestores cadastrados."
                                )
                                : setDeletingId(row.id)
                            }>
                              <Button>
                                Excluir empresa
                              </Button>
                            </Item>
                          </List>
                        </Dropdown>
                      </TH>
                    </Tr>
                  ))}
                </tbody>
              </Table>

              <TablePagination
                count={total}
                page={page}
                rowsPerPage={10}
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                component="div"
              />
            </TableWrapper>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>
    </>
  );
};

export default Companies;
