import styled from "styled-components";

/**
 * CONTAINER
 */
export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin-left: auto;
  width: 350px;
  height: 100vh;
  z-index: 1000;
  pointer-events: none;
  &>*>*{
    pointer-events: all;
  }
`;

export const CartContainer = styled.div`
  position: relative;
  height: calc(100vh - 85px);
  width: calc(100% - 25px);
  padding: 10px 0;
  display: flex;
`;

export const FabContainer = styled.div`
  height: 85px;
`;

export const Form = styled.form`
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid rgba(142, 90, 237, 0.32);
  transition: all 0.3s ease-in-out;
  transform: translateX(calc(100% + 36px));
  &.open {
    transform: translateX(0);
  }
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  padding: 0 5px;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: stretch;
  flex: 1;
`;

/**
 * HEADER
 */
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const Title = styled.h2`
  margin: 0;
  color: #505050;
  font-weight: 500;
  font-size: 16px;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-top: -20px;
  margin-right: -5px;
`;

/**
 * BODY
 */
export const Products = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden auto;
  padding: 10px;
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Product = styled.div`
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  padding: 20px 15px;
`;

export const ProductHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

export const ProductImage = styled.img`
  width: 50px;
  margin-right: 10px;
`;

export const ProductTitle = styled.h3`
  font-size: 13px;
  font-weight: 500;
  margin: 0;
`;

export const ProductSubtitle = styled.h4`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #A7A7A7;
`;

export const ProductDescription = styled.p`
  font-size: 10px;
  font-weight: 500;
  margin: 0;
`;

export const ProductRemove = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  line-height: 18px;
  margin-left: auto;
  align-self: flex-start;
`;

export const Contact = styled.div`
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

export const ContactFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  & input, & textarea {
    font-size: 14px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
