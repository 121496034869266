import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import { QUERY_BENEFIT_REQUEST } from "./graphql";
import { useHistory } from "react-router-dom";
import { BenefitRequests, BenefitRequestsVariables } from "./types/BenefitRequests";
import { BenefitCard, BenefitCardButton, BenefitCardImage, BenefitCardInfo, BenefitCardSubtitle, BenefitCardTitle, BenefitCardTitles, BenefitList, ContactButton, EmptyMessage, GridWrapper, KanbanColumn, KanbanColumnTitle, KanbanWrapper, ProgressTitle, RequestCard, RequestCardContent, RequestCardHeader, SectionTitle, SectionWrapper, WrapperBack, YearSelect } from "./styles";
import { Paper, Step, StepLabel, Stepper } from "@material-ui/core";
import SecondaryButton from "../../../../common/components/SecondaryButton";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import { BeneficConfig } from "../Benefits/interfaces";
import api from "../../../../services/api";
import { BeneficiosRequest, BeneficiosRequestHasBenefit } from "./interfaces";

const BenefitRequestsPage: React.FC = () => {
  const context = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [beneficios, setBeneficios] = useState<BeneficiosRequestHasBenefit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const years = Array.from({ length: startYear - currentYear + 1 }, (_, i) => startYear - i);

  const getBeneficios = async () => {
    try {
      const params = { companyId };
      const { data } = await api.get("/benefit-request", { params });
      setBeneficios(data.data);
    } catch (e) {
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBeneficios();
  }, []);

  const steps = [
    { label: 'Recebido', value: "DRAFT" },
    { label: 'Em análise', value: "ANALYSIS" },
    { label: 'Em implementação', value: "IMPLEMENTATION" },
    { label: 'Concluído', value: "FINISHED" },
  ];

  const getStatusBenefits = (status: string) => {
    return beneficios.filter(b => b.CompanyBenefitRequest.status === status);
  };

  return (
    <SectionWrapper >
      <GridWrapper>
        <WrapperBack>
          <SectionTitle>
            <PageTitle
              title="Fila de Aprovação"
              subtitle="Aqui você pode visualizar o status da aprovação do seu produto."
            />
          </SectionTitle>

          <KanbanWrapper>
            {steps.map((step) => (
              <KanbanColumn key={step.value}>
                <BenefitCardTitles>
                <KanbanColumnTitle>{step.label}</KanbanColumnTitle>
                </BenefitCardTitles>
                {getStatusBenefits(step.value).length === 0 ? (
                  <EmptyMessage>Nenhum benefício</EmptyMessage>
                ) : (
                  getStatusBenefits(step.value).map((benefit) => (
                    <BenefitCard key={benefit.Benefit.code}>
                      <BenefitCardImage src={benefit.Benefit.logoUrl} alt={benefit.Benefit.title} />
                      <BenefitCardInfo>
                        <BenefitCardTitle>{benefit.Benefit.title}</BenefitCardTitle>
                        <BenefitCardSubtitle>{benefit.Benefit.subtitle}</BenefitCardSubtitle>
                      </BenefitCardInfo>
                      <BenefitCardButton onClick={() => history.push(`/beneficio/${benefit.Benefit.code}`)}>
                        Detalhes
                      </BenefitCardButton>
                    </BenefitCard>
                  ))
                )}
              </KanbanColumn>
            ))}
          </KanbanWrapper>
        </WrapperBack>

      </GridWrapper>
    </SectionWrapper>
  );
};
export default BenefitRequestsPage;
