import { gql } from "@apollo/client";

export const PRODUCT_ACTIVATE_MUTATION = gql`
    mutation activateProduct(
        $productId: ID!
        $companyId: ID!
    ){
        activateProduct(
            productId: $productId
            companyId: $companyId
        ) {
            __typename
            ... on Company {
                id
                activeProducts {
                    __typename
                    id
                }
            }
            ... on ErrorWithMessage {
                message
            }
        }
    }
`;

export const PRODUCT_INACTIVATE_MUTATION = gql`
    mutation inactivateProduct(
        $productId: ID!
        $companyId: ID!
    ){
        inactivateProduct(
            productId: $productId
            companyId: $companyId
        ) {
            __typename
            ... on Company {
                id
                activeProducts {
                    __typename
                    id
                }
            }
            ... on ErrorWithMessage {
                message
            }
        }
    }
`;
