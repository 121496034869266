import React, { FunctionComponent, useEffect, ReactElement } from "react";
import FocusLock from "react-focus-lock";
import Icon from "../../Icon";
import ReactDOM from "react-dom";
import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  Backdrop,
  Content,
} from "./styles";
import { IModal } from "./interface";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  refresh?: () => void;
  children?: ReactElement[] | any;
  headerText?: string;
  id?: string | null;
  width?: string;
  companyId?: string | null;
}

export const ModalDefault: FunctionComponent<IModal> = (props: IModal) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && props.isShown) {
      props.hide();
    }
  };
  useEffect(() => {
    props.isShown
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  });
  const modal = (
    <React.Fragment>
      <Backdrop onClick={props.hide} />
      <FocusLock>
        <Wrapper
          {...props}
          aria-modal
          aria-labelledby={props.headerText}
          tabIndex={-1}
          role="dialog"
        >
          <StyledModal>
            <CloseButton onClick={props.hide}>
              <Icon name="close" />
            </CloseButton>
            <Header>
              <HeaderText>{props.headerText}</HeaderText>
            </Header>
            <Content>{props.children}</Content>
          </StyledModal>
        </Wrapper>
      </FocusLock>
    </React.Fragment>
  );
  return props.isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
