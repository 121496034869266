import styled from 'styled-components';
import bg from '../../../assets/img/background.png';

export const BackgroundImage = styled.div`
    display: flex;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-image: url(${bg});
    background-size: cover;
    background-position: center bottom;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(142, 90, 237, 0.8) 100%);


`;