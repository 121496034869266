import styled from "styled-components";
import { ifProp } from "styled-tools";

export const Item = styled.div`
  height: 45px;
  border: 1px solid #f1f1f1;
  padding-top: 15px;
  padding-left: 14px;
  cursor: pointer;
  z-index: 999;
  ${ifProp("disabled", "opacity: 0.6;")}

  &:hover {
    background-color: #f3edff;
  }
`;
