import React from 'react';
import { ITab } from './interface';


// O COMPONENTE PRECISA SER MONTADO DA SEGUINTE FORMA:
// <Tabs>
//      <Tab></Tab>
//      <Tab></Tab>
// </Tabs>


const Tab: React.FC<ITab> = ({children}) => {
    return(
        <div>{children}</div>
    )
}

export default Tab;