import React from "react";
import { IPanel } from "./interface";
import { PanelDefault } from "./styles";

export const Panel: React.FC<IPanel> = (props) => {
  return (
    <PanelDefault
      borderRadius={props.borderRadius}
      padding={props.padding}
      bgColor={props.bgColor}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginRight={props.marginRight}
      marginLeft={props.marginLeft}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      minHeight={props.minHeight}
      height={props.height}
    >
      {props.children}
    </PanelDefault>
  );
};
