import React, {
  CSSProperties,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCSVReader } from "react-papaparse";
import { Table, TH } from "../../../../common/components/Table/styles";
import * as EmailValidator from "email-validator";
import Typography from "../../../../common/components/Typography";
import Loading from "../../../../common/components/Loading";
import {
  Arrows,
  ButtonInvite,
  FileName,
  GridWrapper,
  ItemPerPage,
  PageNumbers,
  PaginationButton,
  SectionWrapper,
  Select,
  SendInviteButton,
  SubtitleModalRemove,
  TableBox,
  TitleModalRemove,
  WrapperAlert,
  WrapperBack,
  WrapperButtonRemove,
  WrapperInvalidEmails,
  WrapperModalRemove,
  WrapperPagination,
  WrapperShowInvalidEmails,
  WrapperSuccess,
  WrapperTable,
  WrapperUnsuccessEmails,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import CompanySelect from "../../../../common/components/CompanySelect";
import AuthContext from "../../../../common/components/hooks/Auth";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  WorkerMultiplesInvites,
  WorkerMultiplesInvitesVariables,
} from "./types/WorkerMultiplesInvites";
import { MUTATION_WORKER_INVITES } from "./graphql";
import Icon from "../../../../common/components/Icon";
import { ModalDefault } from "../../../../common/components/ModalDefault/Modal";
import DangerButton from "../../../../common/components/DangerButton";
import SecondaryButton from "../../../../common/components/SecondaryButton";
import InvalidEmailModal from "./InvalidEmail";
import SuccessModal from "./SuccessModal";
import { TypographyDefault } from "../../../../common/components/Typography/styles";
import UnsuccessModal from "./UnsuccessEmail";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import Checkbox from "../../../../common/components/Checkbox";
import { ThemeContext } from "styled-components";
import api from "../../../../services/api";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  } as CSSProperties,
  fileName: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  } as CSSProperties,
  icon: {
    marginRight: "5px",
  } as CSSProperties,
  browseFile: {
    display: "flex",
    borderRadius: 30,
    color: "white",
    background: "#8E5AED",
    width: "220px",
    padding: "10px 10px 10px 32px",
  } as CSSProperties,
  acceptedFile: {
    height: 30,
    borderRadius: 10,
    background: "#E9F5EB",
    lineHeight: 2,
    paddingLeft: 20,
    paddingRight: 26,
    width: "300px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat",
  } as CSSProperties,
  remove: {
    marginLeft: "2px",
    cursor: "pointer",
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: "#8E5AED",
  } as CSSProperties,
};

const WorkeSheets: React.FC = () => {
  const context = useContext(AuthContext);
  const theme = useContext(ThemeContext);

  const [rows, setRows] = useState<string[][]>([]);
  const [selectedCol, setSelectedCol] = useState<number>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [validEmails, setValidEmails] = useState<string[]>();
  const [invalidEmails, setInvalidEmails] = useState<string[]>();
  const [noRepeats, setNoRepeats] = useState<number>();
  const [hoverColumn, setHoverColumn] = useState<number>();
  const [withSpaceEmail, setWithSpaceEmail] = useState<string[]>();
  const [valuePage, setValuePage] = useState<number>(5);
  const location = useLocation();
  const [emailArray, setEmailArray] = useState<string[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [unsuccess, setUnsuccess] = useState<boolean>(false);
  const [showEmails, setShowEmails] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [getFileName, setGetFileName] = useState<string>();
  const [errorIndex, setErrorIndex] = useState<number[]>();
  const [alreadyExist, setAlreadyExist] = useState<number>(0);
  const params = new URLSearchParams(location.search);
  const companyId = context.companySelect.selectedValue;

  const styles = {
    csvReader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    } as CSSProperties,
    fileName: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    } as CSSProperties,
    icon: {
      marginRight: "5px",
    } as CSSProperties,
    browseFile: {
      display: "flex",
      borderRadius: 30,
      color: theme.colors.primaryContrast,
      background: theme.colors.primary,
      width: "220px",
      padding: "10px 10px 10px 32px",
    } as CSSProperties,
    acceptedFile: {
      height: 30,
      borderRadius: 10,
      background: "#E9F5EB",
      lineHeight: 2,
      paddingLeft: 20,
      paddingRight: 26,
      width: "300px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
    } as CSSProperties,
    remove: {
      marginLeft: "2px",
      cursor: "pointer",
    } as CSSProperties,
    progressBarBackgroundColor: {
      backgroundColor: theme.colors.primary,
    } as CSSProperties,
  };
  const { CSVReader } = useCSVReader();

  useEffect(() => {
    if (selectedCol === undefined) {
      setValidEmails(undefined);
      setInvalidEmails(undefined);
      setNoRepeats(undefined);
      return;
    }

    const col = rows.map((row) => row[selectedCol]);

    const noSpace = col.map((item) => item?.replace(/\s+/g, "") || "");

    const lowerCase = noSpace.map((lower) => lower.toLowerCase());

    const newArray = Array.from(new Set(lowerCase));

    const isComma = lowerCase.filter(
      (item) =>
        item.split("").length <= 1 || EmailValidator.validate(item) === false
    );

    const isInvalidNoRepeat = isComma.length;

    const withSpace = col.filter(
      (item) => EmailValidator.validate(item) === false
    );

    const invalidEmail = newArray.filter(
      (item) => EmailValidator.validate(item) === false
    );
    const validEmail = newArray.filter(
      (item) => EmailValidator.validate(item) === true
    );

    setEmailArray(newArray);
    setValidEmails(validEmail);
    setInvalidEmails(invalidEmail);
    setNoRepeats(isInvalidNoRepeat);
    setWithSpaceEmail(withSpace);
  }, [selectedCol, rows]);

  // VERIFICAR EMAILS

  const getClassName = (colIndex: number, text: string) => {
    if (colIndex !== selectedCol && colIndex === hoverColumn) {
      return "#e1f8fa";
    }
    if (colIndex !== selectedCol) {
      return "";
    }

    return EmailValidator.validate(text) ? "#E9F5EB" : "#fadede";
  };

  const [workerMultiplesInvites, workerMultiplesInvitesMutation] = useMutation<
    WorkerMultiplesInvites,
    WorkerMultiplesInvitesVariables
  >(MUTATION_WORKER_INVITES);

  const onSubmitCreate = async (data: any) => {
    if (workerMultiplesInvitesMutation.loading) {
      return;
    }

    const result = await api.post("/invites/multiple", {
      data: validEmails.map((workerEmail) => ({
        workerEmail,
        companyId,
        resendIfPending,
        sendEmail: true,
      })),
    });

    const isSuccess = result.data?.createWorkerInvites?.some(
      (item) => item.__typename === "WorkerInvite"
    );

    setSuccess(isSuccess);

    const hasError = result.data?.createWorkerInvites?.some(
      (item) => item.__typename !== "WorkerInvite"
    );

    if (hasError) {
      const errorIdx = result.data?.createWorkerInvites
        ?.map((item, idx) => (item.__typename === "WorkerInvite" ? null : idx))
        ?.filter((value) => value !== null);
      setErrorIndex(errorIdx);
      setAlreadyExist(errorIdx?.length || 0);
    }
    return;
  };

  const showInvalidEmails = (
    <WrapperShowInvalidEmails>
      <Typography fontSize="22px" fontWeight="500" marginBottom="26px">
        {noRepeats > 1 ? "Foram encontrados" : "Foi encontrado"} {noRepeats}{" "}
        {noRepeats > 1 ? "valores inválidos" : "valor inválido"}
      </Typography>
      <WrapperInvalidEmails>
        <Typography fontSize="22px" fontColor="#C0272D" fontWeight="300">
          {withSpaceEmail?.map((item) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {item}
            </div>
          ))}
        </Typography>
      </WrapperInvalidEmails>
    </WrapperShowInvalidEmails>
  );

  const showSuccessModal = (
    <WrapperSuccess>
      <div>
        <Icon name="success" />
      </div>
      <Typography fontSize="22px" fontWeight="500" marginBottom="26px">
        Convites enviados com sucesso!
      </Typography>
      <div style={{ width: "80%" }}>
        <SecondaryButton onClick={() => setSuccess(false)}>Ok</SecondaryButton>
      </div>
    </WrapperSuccess>
  );

  const showUnsuccesModal = (
    <>
      <WrapperAlert>
        <Icon name="alert" width="58" height="58" />
        <Typography fontSize="22px" fontWeight="500">
          Não conseguimos enviar o convite para os e-mails seguintes:
        </Typography>
      </WrapperAlert>
      <WrapperUnsuccessEmails>
        {validEmails
          ?.filter((email, idx) => errorIndex?.includes(idx))
          .map((email) => (
            <Typography fontSize="22px" fontWeight="300" fontColor="#C0272D">
              {email}
            </Typography>
          ))}
      </WrapperUnsuccessEmails>
      <PrimaryButton onClick={() => setUnsuccess(false)}>Ok</PrimaryButton>
    </>
  );

  // PAGINATION

  const startIndex = (page - 1) * valuePage;
  const selectedItems = rows.slice(startIndex, startIndex + valuePage);
  const totalPages = Math.ceil(rows.length / valuePage);

  useEffect(() => {
    setPage(1);
    setValuePage(5);
    setAlreadyExist(0);
  }, [getFileName]);

  // CHECKBOXES

  const [resendIfPending, setResendIfPeding] = useState<boolean>(true);

  console.log(resendIfPending);

  const emailLimit = 500;

  const isOverSending = validEmails?.length > emailLimit;

  return (
    <>
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>
            <UnsuccessModal isShown={unsuccess} hide={() => setUnsuccess(false)}>
              {showUnsuccesModal}
            </UnsuccessModal>
            <InvalidEmailModal isShown={showEmails} hide={() => setShowEmails(false)}>
              {showInvalidEmails}
            </InvalidEmailModal>
            <SuccessModal isShown={success} hide={() => setSuccess(false)}>
              {showSuccessModal}
            </SuccessModal>
            <Row>
              <Col md={8}>
                <PageTitle
                  title="Enviar Convites"
                  subtitle="Aqui você pode enviar suas planilhas em formato CSV e escolher qual coluna de e-mails deseja enviar."
                />
                <div
                  style={{
                    display: "flex",
                    marginBottom: "26px",
                    marginTop: "-20px",
                  }}
                >
                  <Typography fontSize="16px" fontWeight="700">
                    Importante:&nbsp;
                  </Typography>
                  <br />
                  <Typography fontSize="16px" fontWeight="400">
                    Sua planilha deve conter uma coluna de e-mails validos dos
                    colaboradores
                  </Typography>
                </div>
              </Col>
              <Col>
                <CSVReader
                  onUploadAccepted={(results: any) => {
                    setSelectedCol(undefined);
                    setRows(results.data);
                  }}
                  config={{
                    header: false,
                  }}
                >
                  {({
                    getRootProps,
                    acceptedFile,
                    ProgressBar,
                    getRemoveFileProps,
                  }: any) => (
                    <>
                      <ModalDefault isShown={remove} hide={() => setRemove(false)}>
                        <WrapperModalRemove>
                          <TitleModalRemove>
                            <Typography fontSize="22px" fontWeight="500">
                              Tem certeza que deseja remover essa planilha?
                            </Typography>
                          </TitleModalRemove>
                          <SubtitleModalRemove>
                            <Typography fontSize="22px" fontWeight="300">
                              Ao remover, os convites são serão enviados.
                            </Typography>
                          </SubtitleModalRemove>
                          <WrapperButtonRemove
                            type="button"
                            {...getRemoveFileProps()}
                          >
                            <DangerButton
                              onClick={() => setRemove(false)}
                              disabled={false}
                            >
                              Remover Planilha
                            </DangerButton>
                          </WrapperButtonRemove>
                          <WrapperButtonRemove>
                            <SecondaryButton onClick={() => setRemove(false)}>
                              Voltar
                            </SecondaryButton>
                          </WrapperButtonRemove>
                        </WrapperModalRemove>
                      </ModalDefault>

                      <div style={styles.csvReader}>
                        {!acceptedFile ? (
                          <div
                            type="button"
                            {...getRootProps()}
                            style={styles.browseFile}
                          >
                            <div style={styles.icon}>
                              <Icon name="plusButton" />
                            </div>
                            Adicionar planilha
                          </div>
                        ) : null}
                      </div>

                      {acceptedFile ? (
                        <Panel borderRadius="30px">
                          <FileName>
                            <div style={{ display: "flex" }}>
                              <div
                                {...setGetFileName(acceptedFile.name)}
                                style={styles.acceptedFile}
                              >
                                {acceptedFile && acceptedFile.name}
                              </div>
                              <div
                                {...getRemoveFileProps()}
                                style={styles.remove}
                                onClick={() => setRemove(true)}
                              >
                                <Icon name="close" />
                              </div>
                            </div>
                          </FileName>

                          <ProgressBar style={styles.progressBarBackgroundColor} />

                          {validEmails?.length === 0 ? (
                            <Typography fontSize="15px" fontWeight="400">
                              <FileName>
                                <div
                                  style={{ marginRight: "8px", marginTop: "-3px" }}
                                >
                                  <Icon name="alert" />
                                </div>
                                Não identificamos e-mails na coluna selecionada
                              </FileName>
                            </Typography>
                          ) : (
                            <>
                              {validEmails?.length || invalidEmails?.length ? (
                                <>
                                  <Typography
                                    fontSize="15px"
                                    fontWeight="400"
                                    marginBottom="10px"
                                    marginTop="20px"
                                  >
                                    Agora clique na coluna que deseja enviar os
                                    convites.
                                  </Typography>
                                  <Typography fontSize="15px" fontWeight="400">
                                    {noRepeats > 0 ? (
                                      <FileName>
                                        <div
                                          style={{
                                            display: "flex",
                                            marginRight: "5px",
                                            marginTop: "-2px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: "5px",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <Icon name="deny" />
                                          </div>

                                          <div style={{ marginTop: "5px" }}>
                                            {noRepeats > 1
                                              ? "Serão ignoradas"
                                              : "Será ignorada"}{" "}
                                            {noRepeats}{" "}
                                            {noRepeats > 1 ? "linhas" : "linha"}
                                          </div>
                                        </div>
                                        {invalidEmails ? (
                                          <div style={{ marginLeft: "10px" }}>
                                            <SecondaryButton
                                              fontSize="10px"
                                              paddingBottom="2px"
                                              paddingTop="2px"
                                              onClick={() => setShowEmails(true)}
                                            >
                                              Veja quais são
                                            </SecondaryButton>
                                          </div>
                                        ) : null}
                                      </FileName>
                                    ) : null}
                                    <div style={{ display: "flex" }}>
                                      <div style={{ marginRight: "5px" }}>
                                        <Icon
                                          name="success"
                                          width="16px"
                                          height="16px"
                                        />
                                      </div>
                                      {validEmails.length} e-mails válidos
                                    </div>
                                    {alreadyExist > 0 ? (
                                      <div
                                        style={{ display: "flex", marginTop: "5px" }}
                                      >
                                        <div style={{ marginRight: "5px" }}>
                                          <Icon
                                            name="alert"
                                            width="16px"
                                            height="16px"
                                          />
                                        </div>
                                        {alreadyExist}{" "}
                                        {alreadyExist === 1
                                          ? "e-mail já enviado"
                                          : "e-mails já enviados"}
                                      </div>
                                    ) : null}
                                  </Typography>
                                </>
                              ) : null}
                            </>
                          )}
                          <TableBox>
                            <WrapperTable>
                              <Table>
                                <tbody>
                                  {selectedItems.map((row) => (
                                    <tr>
                                      {row.map((col, index) => (
                                        <>
                                          <TH
                                            style={{
                                              cursor:
                                                selectedCol !== index
                                                  ? "pointer"
                                                  : "auto",
                                              transition: "background-color .3s",
                                              background: `${getClassName(
                                                index,
                                                col
                                              )}`,
                                            }}
                                            onClick={() => setSelectedCol(index)}
                                            onMouseOver={() => setHoverColumn(index)}
                                          >
                                            {col}
                                            {console.log(emailArray.length)}
                                          </TH>
                                        </>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </WrapperTable>
                            <WrapperPagination>
                              <ItemPerPage>
                                <div>
                                  Itens por página
                                  <Select
                                    onChange={(e) =>
                                      setValuePage(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                  </Select>
                                </div>
                              </ItemPerPage>
                              <PageNumbers>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignSelf: "center",
                                    marginTop: "5px",
                                  }}
                                >
                                  Página {page} - {totalPages}
                                </div>
                                <Arrows>
                                  <PaginationButton
                                    onClick={() => {
                                      if (page > 1) {
                                        setPage(page - 1);
                                      }
                                      return;
                                    }}
                                  >
                                    <Icon name="arrow-left" />
                                  </PaginationButton>
                                  <PaginationButton
                                    onClick={() => {
                                      if (page < totalPages) {
                                        setPage(page + 1);
                                      }
                                      return;
                                    }}
                                  >
                                    <Icon name="arrow-right" />
                                  </PaginationButton>
                                </Arrows>
                              </PageNumbers>
                            </WrapperPagination>
                          </TableBox>

                          {validEmails?.length ? (
                            <div>
                              <SendInviteButton>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <Checkbox
                                    checked={resendIfPending}
                                    onChange={(e) =>
                                      setResendIfPeding(e.target.checked)
                                    }
                                  />

                                  <Typography
                                    marginLeft="10px"
                                    fontSize="18px"
                                    fontWeight="400"
                                    marginTop="-2px"
                                  >
                                    Reenviar caso esteja pendente
                                  </Typography>
                                </div>
                                <ButtonInvite
                                  onClick={onSubmitCreate}
                                  disabled={
                                    workerMultiplesInvitesMutation.loading ||
                                    isOverSending
                                  }
                                >
                                  {workerMultiplesInvitesMutation.loading ? (
                                    <Loading />
                                  ) : (
                                    "Enviar convites da coluna selecionada"
                                  )}
                                </ButtonInvite>
                                {isOverSending ? (
                                  <Typography
                                    fontColor="red"
                                    fontSize="15px"
                                    fontWeight="400"
                                  >
                                    Não é possível enviar mais de {emailLimit} emails
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </SendInviteButton>
                            </div>
                          ) : null}
                        </Panel>
                      ) : null}
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
          </WrapperBack>

        </GridWrapper>
      </SectionWrapper >
    </>
  );
};

export default memo(WorkeSheets);
