import styled from "styled-components";

export const TabWrapper = styled.div`
  margin-left: -21px;
`;

export const AddLogo = styled.div`
  margin-bottom: 190px;
  @media (max-width: 992px) {
    margin-bottom: 44px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const ExampleWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
`;

export const BackgroundExample = styled.div`
  padding: 50px;
  margin-top: 30px;
`;

export const Savebutton = styled.button`
  border: none;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00c620;
  color: #ffffff;
  width: 289px;

  @media (max-width: 850px) {
    display: block;
    margin-top: 10px;
  }
`;

export const Testbutton = styled.button`
  border: none;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  color: #00c620;
  width: 289px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.28);
  font-weight: 500;
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  @media (max-width: 850px) {
    display: block;
    margin-left: 10px;
  }
`;

export const WrapperLoadImages = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VerticalLine = styled.div`
  display: flex;
  justify-content: center;
  border-left: 2px solid gray;
  height: 150px;
  @media (max-width: 992px) {
    border-left: none;
    border-top: 2px solid gray;
    margin-left: 15px;
  }
`;

export const PreviewLogo = styled.div`
  display: flex;
  width: "100%";
  justify-content: "flex-end";
  @media (max-width: 992px) {
    jusfify-content: center;
  }
`;

export const WrapperUpdateLogo = styled.div`
  @media (max-width: 992px) {
    margin-top: -10px;
  }
`;
export const ButtonAccessAll = styled.button`
  /* Adicione os estilos do botão aqui */
`;

export const WrapperBanner = styled.div`
  /* Estilos para o wrapper do banner */
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const WrapperBeneficios = styled.div`
  /* Estilos para o wrapper dos benefícios */
`;

export const WrapperLoading = styled.div`
  /* Estilos para o wrapper de loading */
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const SectionWrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const SectionTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 6%;
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content:center;
`;


export const WrapperImage = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Images = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;
export const WrapperBack = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 10px;
`;
