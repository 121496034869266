import React from "react";
import { ITypography } from "./interface";
import { TypographyDefault, TypographyWrapper } from "./styles";

const Typography: React.FC<ITypography> = (props) => {
  return (
    <TypographyWrapper {...props}>
      <TypographyDefault
        fontSize={props.fontSize}
        fontColor={props.fontColor}
        fontWeight={props.fontWeight}
        fontFamily={props.fontFamily}
        wrapLine={props.wrapLine}
      >
        {props.children}
      </TypographyDefault>
    </TypographyWrapper>
  );
};

export default Typography;
