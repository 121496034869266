import React from 'react';
import { ISelectBorder } from './interface';
import { SelectStyled, Label } from './styles';

const SelectBorderCustom: React.FC<ISelectBorder> = ({options, label, ...props}) => {
  
  return(
    <div>
      <Label>{label}</Label>
      <SelectStyled {...props}>
        {options.map((option) => (
          <option value={option.value}>{option.text}</option>
        ))}
      </SelectStyled>
    </div>
  )
}

export default SelectBorderCustom;