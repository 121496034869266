import styled from "styled-components";
import { IPanel } from "./interface";
import {prop, ifProp} from 'styled-tools';

export const PanelDefault = styled.div<IPanel>`  
 background-color: ${ifProp("bgColor", prop("bgColor"), "#F8F9FC")};
 padding: ${ifProp("padding", prop("padding"), "px")};
 border-radius: ${ifProp("borderRadius", prop("borderRadius"), "20px")};
 align-items: center;
 box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
 padding-top: ${prop("paddingTop")};
 padding-bottom: ${prop("paddingBottom")};
 padding-left: ${prop("paddingLeft")};
 padding-right: ${prop("paddingRight")};
 margin-top: ${prop("marginTop")};
 margin-bottom: ${prop("marginBottom")};
 margin-right: ${prop("marginRight")};
 margin-left: ${(prop("marginLeft"))};
 max-width: ${ifProp("maxWidth", prop("maxWidth"), "100%")};
 max-height: ${ifProp("maxHeight", prop("maxHeight"), "100%")};
 min-height: ${ifProp("minHeight", prop("minHeight"), "100%")};
 height: ${prop("height")};

`