import React from 'react';
import { IItems } from './interface';
import { Item } from './styles';

const Items: React.FC<IItems> = ({children, ...props}) => {
    return(
        <Item {...props}>
            {children}
        </Item>
    )
}

export default Items;