import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import DangerButton from "../../../../../common/components/DangerButton";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import SecondaryButton from "../../../../../common/components/SecondaryButton";
import Typography from "../../../../../common/components/Typography";
import { MUTATION_SEND_WORKER_INVITE_EMAIL } from "./graphql";
import { sendWorkerInviteEmail, sendWorkerInviteEmailVariables } from "./types/sendWorkerInviteEmail";
import api from "../../../../../services/api";

const SendEmailModal: React.FC<{
  id?: string | null;
  isShown: boolean;
  hide: () => any;
}> = ({ isShown, hide, id }) => {
  const [isLoading, setIsLoading] = useState(false);

  const sendWorkerInviteEmail = async () => {
    setIsLoading(true);

    try {
      await api.put(`/invites/${id}/re-send`);

      alert("E-mail enviado com sucesso!");
      hide();
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const body = (
    <>
      <Typography fontSize="22px" fontWeight="500">
        Tem certeza que deseja enviar um novo e-mail de convite para este colaborador?
      </Typography>
      <br />
      <Typography fontSize="22px" fontWeight="300">
        Verifique se o e-mail anterior não caiu na caixa de spam.
      </Typography>
      <br />
      <PrimaryButton
        disabled={isLoading}
        onClick={sendWorkerInviteEmail}
      >
        Enviar novamente
      </PrimaryButton>
      <SecondaryButton
        disabled={isLoading}
        onClick={hide}
      >
        Voltar
      </SecondaryButton>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default SendEmailModal;
