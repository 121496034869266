import React, { memo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Background, Image } from "../../../../common/components";
import { TitleBox, ShowPassword, InputWrapper } from "./styles";
import { Panel } from "../../../../common/components/Panel";
import Icon from "../../../../common/components/Icon";
import Input from "../../../../common/components/Input";
import { IFormInputs } from "./interface";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Helmet from 'react-helmet'
import logo from "../../../../assets/img/logoPreto.png";
import nova from "../../../../assets/img/background.png";
import Typography from "../../../../common/components/Typography";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import DangerButton from "../../../../common/components/DangerButton";
import { useContext } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import SecondaryButton from "../../../../common/components/SecondaryButton";
import { useHistory, useLocation } from "react-router-dom";
import { ptShort } from 'yup-locale-pt';
import { Avatar, Box, IconButton, Link, Paper, useMediaQuery } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
yup.setLocale(ptShort);

const schemaEmail = yup.object().shape({
  email: yup.string().required().email(),
});

const schemaPassword = yup.object().shape({
  password: yup.string().required(),
});

const Login: React.FC = () => {
  const [next, setNext] = useState(true);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const authContext = useContext(AuthContext);
  const { push } = useHistory();
  const isMobileScreen = useMediaQuery("(max-width: 750px)");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  const {
    register: registerPasswordForm,
    handleSubmit: handleSubmitPassword,
    getValues: getValuesPassword,
    formState: { errors: errorsPassword },
    setValue: setValuePassword,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schemaPassword),
  });

  const {
    register: registerEmailForm,
    handleSubmit: handleSubmitEmail,
    getValues: getValuesEmail,
    formState: { errors: errorsEmail },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schemaEmail),
    defaultValues: {
      email: email || "",
    }
  });

  const onSubmitEmail = () => {
    setNext(false);
  };

  const onSubmitPassword = async () => {
    const password = getValuesPassword().password;
    const email = getValuesEmail().email;

    const result = await authContext.authenticate(email, password);

    if (result) {
      push("/home");
      return;
    }

    alert('O Email ou Senha que você inseriu pode estar Incorreto, ou sua Conta pode estar Inativa. Por favor, verifique suas credenciais e tente novamente.');
  };

  return (
    <Background>

      <Box style={{
        width: isMobileScreen ? "100vw" : "50vw",
        height: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center"
      }}>
        <div style={{
          minWidth: isMobileScreen ? "80%" : "50%",
          marginTop: isMobileScreen ? "35%" : "25%"
        }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "12%"
          }}>
            <img style={{ width: "50%", objectFit: "contain" }} src={logo} />
          </div>
          <div style={{
            marginTop: "3%",
            gap: "1rem",
            flexDirection: "column",
            display: "flex"
          }}>
            <Input
              placeholder="Email"
              register={registerEmailForm("email")}
              required
            />
            <Typography
              fontColor="#ff0000"
              fontSize="12px"
              fontWeight="400"
            >
              {errorsEmail.email?.message}
            </Typography>
            <div style={{
              marginTop: "3%",
              gap: "1rem",
              flexDirection: "column",
              display: "flex",
              position: "relative"
            }}>
              <Input
                disabled={authContext.authenticating}
                placeholder="Senha"
                type={visiblePassword ? "text" : "password"}
                register={registerPasswordForm("password")}
                required
              ></Input>
              <IconButton
                onClick={() => setVisiblePassword(!visiblePassword)}
                style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)' }}
              >
                {visiblePassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </IconButton>
              <Typography fontColor="#ff0000" fontSize="12px" fontWeight="400">
                {errorsEmail.password?.message}
              </Typography>
            </div>
          </div>
          <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
            <div style={{ marginTop: "3%", marginBottom: "3%" }}>
              <a
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  marginTop: "2%",
                  fontFamily: "Montserrat",
                  cursor: "pointer"
                }}
                onMouseEnter={(e) => e.currentTarget.style.textDecoration = "underline"}
                onMouseLeave={(e) => e.currentTarget.style.textDecoration = "none"}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => push(`/password-reset-request?email=${encodeURIComponent(getValuesEmail().email)}`)}
              >
                Esqueci minha senha
              </a>
            </div>

            <div style={{
              marginTop: "3%",
              display: "flex",
              width: "100%",
              gap: "1.5rem",
              justifyContent: "center"
            }}>
              <Button
                variant="outlined"
                style={{
                  border: "1px solid #8e5aed",
                  color: "#8e5aed",
                  width: "50%",
                  height: "50px",
                  borderRadius: "30px"
                }}
                onClick={() => push('/cadastro-empresa')}>
                Fazer Cadastro
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#8e5aed",
                  color: "#fff",
                  width: "50%",
                  height: "50px",
                  borderRadius: "30px"
                }}
                onClick={handleSubmitPassword(onSubmitPassword)}
                disabled={authContext.authenticating}
                type="submit"
              >
                Acessar
              </Button>
            </div>

          </form>
        </div>
      </Box>
      {!isMobileScreen && (
        <div style={{ width: "50%", marginTop: "6%" }}>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "5%" }}>
            <TitleBox>
              <h1>
                UM SISTEMA DE RH
              </h1>

              <Typography fontSize="32px" fontColor="#ffffff" fontWeight="300">
                COMPLETO PARA VOCÊ
              </Typography>
            </TitleBox>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
            <Avatar style={{ width: "300px", height: "300px" }} src={nova} />
          </div>
        </div>
      )}
    </Background>
  );
};
export default memo(Login);