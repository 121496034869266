import styled from "styled-components";

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    position: relative;
  }
`;


export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: left;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: right;
  }
`;
export const TitleBox = styled.div`
  display: flex;
  margin-top: 100px;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;


export const ContainerTable = styled.div`
  overflow: auto;
`;


export const ButtonAccessAll = styled.button`
  /* Adicione os estilos do botão aqui */
`;

export const WrapperBanner = styled.div`
  /* Estilos para o wrapper do banner */
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const WrapperBeneficios = styled.div`
  /* Estilos para o wrapper dos benefícios */
`;

export const WrapperLoading = styled.div`
  /* Estilos para o wrapper de loading */
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const SectionWrapper = styled.div`
  padding: 20px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const WrapperBack = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;

export const SectionTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 6%;
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content:center;
`;




export const WrapperImage = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Images = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 600px;
  padding: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
  margin-top: 20%;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 10px;
`;

interface Props {
  background?: string;
}

export const InputFileContainer = styled.div<Props>`
  input {
      display: none;
  }

  label {
      padding: 0.5rem 1rem;
      border-radius: 25px;
      border: 1px solid ${(props)=>props.background};
      background-color: ${(props)=>props.background};
      color: white;
      cursor: pointer;
  }
`;