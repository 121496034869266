import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { validate as validateCnpj, format as formatCnpj } from "cnpj";
import { Widget } from "react-cloudinary-upload-widget";
import * as S from "./styles";

import { useEffect } from "react";
import { company, companyVariables } from "./types/company";
import {
  MUTATION_CREATE_COMPANY,
  MUTATION_UPDATE_COMPANY,
  QUERY_COMPANY,
} from "./graphql";
import PrimaryButton from "../../../../../common/components/PrimaryButton";
import Typography from "../../../../../common/components/Typography";
import Input from "../../../../../common/components/Input";
import { ModalDefault } from "../../../../../common/components/ModalDefault/Modal";
import { createCompany, createCompanyVariables } from "./types/createCompany";
import { UpdateCompany, UpdateCompanyVariables } from "./types/UpdateCompany";
import { useContext } from "react";
import AuthContext from "../../../../../common/components/hooks/Auth";
import { env } from "process";
import { ThemeContext } from "styled-components";
import ReactInputMask from "react-input-mask";
import { Col, Row } from "react-bootstrap";
import api from "../../../../../services/api";
import { TextField } from "@material-ui/core";
// import { toast } from "react-toastify";

const schema = yup.object().shape({
  name: yup.string().required().min(3).max(255),
  cnpj: yup.string().required().min(18).max(18),
  companyName: yup.string().required().min(3).max(255),
  contactEmail: yup.string().required().email(),
});

interface Props {
  id?: string | null;
  isShown: boolean;
  hide: () => any;
  refresh: () => void
}

const CompaniesModal: React.FC<Props> = ({ isShown, hide, id, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedCompany } = useContext(AuthContext);

  const themeContext = useContext(ThemeContext);
  const authContext = useContext(AuthContext);

  const { handleSubmit, control, formState: { errors }, reset, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e?: any) => {

    if (!e || !e.target.files || !e.target.files[0]) {
      return;
    }

    const allowedFileTypes = ['image/png', "image/jpeg", "image/jpg", "image/gif", "image/bmp", "image/tiff", "application/pdf"];

    if (e && e.target.files && e.target.files[0] && !allowedFileTypes.includes(e.target.files[0].type)) {
      alert('Apenas imagens ou pdf no campo "Anexo"!');
      return;
    }

    if (e && e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }

  const [logoThumbnailUrl, setLogoThumbnailUrl] = useState<string | null>();

  const getCompanyById = async () => {
    try {
      const { data } = await api.get("/company/" + id);

      reset({
        name: data.data.name || "",
        cnpj: data.data.cnpj ? formatCnpj(data.data.cnpj) : "",
        companyName: data.data.companyName || "",
        contactEmail: data.data.contactEmail || "",
      });
      setFile(null);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const erase = () => {
    reset({
      name: "",
      cnpj: "",
      companyName: "",
      contactEmail: "",
      logoUrl: "",
    });
    setFile(null);
    setLogoThumbnailUrl(null);
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const data = getValues();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (!validateCnpj(data.cnpj)) {
      alert("O CNPJ não é válido. Tente novamente...");
      return;
    }

    const cnpj = data.cnpj.replace(/\D/g, "");

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("cnpj", cnpj);
    formData.append("companyName", data.companyName);
    formData.append("contactEmail", data.contactEmail);

    if (!id && selectedCompany) {
      formData.append("parentCompanyId", selectedCompany.id);
    }

    if (file) {
      formData.append("file", file);
    }

    try {
      const url = id
        ? "/company/" + id
        : "/company";

      const method = id
        ? "put"
        : "post";


      await api[method](url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });

      alert(id
        ? "Empresa atualizada com sucesso!"
        : "Empresa criada com sucesso!"
      );
      refresh()
      erase();
      hide();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    erase();

    if (!id) {
      return;
    }

    getCompanyById();
  }, [id]);

  const body = (
    <>
      <Typography fontSize="20px" fontWeight="500">
        {isLoading
          ? "Carregando..."
          : id
            ? "Edite os dados da Empresa abaixo:"
            : "Para adicionar uma nova Empresa, preencha os campos abaixo:"}
      </Typography>
      <form
        onSubmit={(e: any) => onSubmitForm(e)}
      >
        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                placeholder="Nome Fantasia"
                disabled={isLoading}
              />
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.name?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="companyName"
            defaultValue=""
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                placeholder="Razão social"
                disabled={isLoading}
              />
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.companyName?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="cnpj"
            defaultValue=""
            render={({ field }) => (
              <ReactInputMask
                {...field}
                mask="99.999.999/9999-99"
                disabled={isLoading}
              >
                {(inputProps: any) => (
                  <Input {...inputProps} placeholder="CNPJ" />
                )}
              </ReactInputMask>
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.cnpj?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px" }}>
          <Controller
            control={control}
            name="contactEmail"
            defaultValue=""
            render={({ field }) => (
              <Input
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                placeholder="Email"
                disabled={isLoading}
              />
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.contactEmail?.message}
          </Typography>
        </div>

        <div style={{ marginTop: "35px", marginBottom: "35px" }}>
          <Controller
            control={control}
            name="logoUrl"
            defaultValue=""
            render={({ field }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {logoThumbnailUrl ? (
                  <img
                    src={logoThumbnailUrl}
                    alt="Company Logo"
                    style={{ maxHeight: 50 }}
                  />
                ) : null}

                <S.InputFileContainer background={themeContext.colors.primary}>
                  <TextField
                    id="file-input"
                    type="file"
                    onChange={(e: any) => handleFileChange(e)}
                  />

                  <label htmlFor="file-input">
                    Anexar aquivo
                  </label>
                </S.InputFileContainer>
              </div>
            )}
          />
          <Typography fontSize="15px" fontWeight="300" fontColor="red">
            {errors.logoUrl?.message}
          </Typography>
        </div>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <PrimaryButton
              disabled={isLoading}
              fontSize="15px"
              paddingLeft="70px"
              paddingRight="70px"
              borderRadius="30px"
              onClick={() => { }}
              width="100%"
              type="submit"
            >
              {id ? "Salvar" : "Adicionar nova Empresa"}
            </PrimaryButton>
          </Col>
        </Row>
      </form>
    </>
  );

  return (
    <>
      <ModalDefault isShown={isShown} hide={hide}>
        {body}
      </ModalDefault>
    </>
  );
};

export default CompaniesModal;
