import React from "react";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import Icon from "../Icon";
import { IconWrapper } from "../PrimaryButton/styles";
import { ISecondaryButton } from "./interface";
import { SecondaryButtonDefault } from "./styles";

const SecondaryButton: React.FC<ISecondaryButton> = ({text, children, iconName,...props}) => {
  const context = useContext(ThemeContext);
  return (
    <div>
      <SecondaryButtonDefault
        {...props}
      >
        <IconWrapper>
          <Icon name={iconName} color={context.colors.secondaryContrast} />
        </IconWrapper>
        {children}
        {text}
      </SecondaryButtonDefault>
    </div>
  );
};

export default SecondaryButton;
