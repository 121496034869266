import styled from "styled-components";
import { IDangerButton } from "./interface";
import { ifProp, prop } from "styled-tools";

export const DangerButtonDefault = styled.button<IDangerButton>`
  font-family: "Montserrat", sans-serif;
  padding: ${prop("padding", "10px")};
  font-size: ${prop("fontSize", "20px")};
  line-height: 24px;
  width: 100%;
  background-color: ${prop("theme.colors.dangerContrast")};
  color: ${prop("theme.colors.danger")};
  border-color: ${prop("theme.colors.danger")};
  border-width: 1px;
  border-style: solid;
  border-radius: ${prop("borderRadius", "30px")};
  display: block;
  margin: auto;
  font-weight: 500;
  padding-top: ${prop("paddingTop")};
  padding-bottom: ${prop("paddingBottom")};
  padding-left: ${prop("paddingLeft")};
  padding-right: ${prop("paddingRight")};
  margin-bottom: ${prop("marginBottom")};
  ${ifProp("disabled", "opacity: 0.7;")}
  &:hover {
    opacity: 0.7;
  }
`;
