import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import AvatarUpload from "../NewBenefits/AvatarUpload";
import { useHistory, useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Rating, Stack } from "@mui/material";
import { GridWrapper, SectionWrapper, Wrapper, WrapperBack } from "./styles";
import ProdutoImage from "../NewProductShop/ProdutoImage";

interface Errors {
  [field: string]: string;
}


interface Pacotes {
  id: string,
  name: string;
  valor: string;
  descricao: string;
}


const EditarPackPremium: React.FC = () => {
  const [pack, setPack] = useState<Pacotes[]>([]);
  const [showFullDescription, setShowFullDescription] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState("");
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");
  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});
  const theme = useContext(ThemeContext);
  const { id } = useParams<{ id: string }>()
  const handleChangePartnerData = (obj: Object): void => {
    setPack(prev => ({
      ...prev,
      ...obj
    }));
  };

  const getPacoteInfos = async () => {
    try {
      const { data } = await api.get(`/pacote/premium/info/${id}`,);
      console.log("beneeeeee", data)
      setName(data.data.name || "");
      setDescricao(data.data.descricao || "");
      setValor(data.data.valor || "");
    } catch (e) {

      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");

    } finally {
      setLoading(false);
    }
  }



  const postPack = async () => {

    try {
      const packData = {
        name: name,
        valor: parseFloat(valor.replace(',', '.')),
        descricao: descricao
      };


      await api.put(`/pacote/premium/update/${id}`, packData, {
      });
      alert("Pacote Atualizado com sucesso!");
      history.push("/pacotes/premium");

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getPacoteInfos();

  }, [id]);

  const toggleDescription = (id: string) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  const formatCurrency = (value) => {
    // Função para formatar valor em moeda (por exemplo, BRL)
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };




  useEffect(() => {
    setLoading(false); // Set loading to false once component is fully loaded
  }, []);

  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>
        <GridWrapper>
          <WrapperBack>

            <PageTitle
              title="Plano"
              subtitle="Aqui você pode criar planos para as empresas."
            />

            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item xs={12} md={4} style={{ marginTop: "-3%" }}>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <Wrapper style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                      <Rating
                        name="size-large"
                        value={
                          (() => {
                            const qtdusuario = parseInt(valor, 10) || 0; // Converte a string para número, ou 0 se for inválido
                            if (qtdusuario <= 1000) return 1;
                            if (qtdusuario <= 2000) return 2;
                            if (qtdusuario <= 3000) return 3;
                            if (qtdusuario <= 4000) return 4;
                            return 5;
                          })()
                        }
                        readOnly
                        size="large"
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
                      <h1
                        style={{
                          fontSize: '16px',
                          fontFamily: "inherit",
                          fontWeight: 700,
                          lineHeight: '24px',
                          color: "#383838"
                        }}
                      >
                        {name}
                      </h1>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <p style={{ fontFamily: "inherit", fontSize: '12px', color: '#757575' }}>
                        {descricao.length > 10 ? `${descricao.substring(0, 10)}...` : descricao}
                      </p>

                    </div>
                    <h1 style={{ fontSize: '22px', fontFamily: "inherit", fontWeight: 700, lineHeight: '24px', color: "#383838", marginTop: '10px' }}>
                      {formatCurrency(valor)}
                    </h1>
                  </Wrapper>
                </div>
              </Grid>

              <Grid item xs={12} md={8} style={{ marginTop: "1%" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="name"
                      name="name"
                      style={{ paddingBottom: "3%", marginTop: "-3%" }}
                      autoFocus
                      label="Nome"
                      fullWidth
                      autoComplete="Nome"
                      className="inputdados"
                      size="small"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {formErrors.name && (
                      <S.ErrorMessage>
                        {formErrors.name}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="valor"
                      name="valor"
                      style={{ paddingBottom: "3%", marginTop: "-3%" }}
                      autoFocus
                      label="Valor do Pacote"
                      fullWidth
                      autoComplete="Valor"
                      className="inputdados"
                      size="small"
                      value={valor}
                      onChange={e => setValor(e.target.value.replace(/\D/g, ''))}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}

                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      variant="outlined"
                      id="descricao"
                      name="descricao"
                      fullWidth
                      multiline
                      rows={5}
                      label="Descrição"
                      autoComplete="descricao"
                      onChange={(e) => setDescricao(e.target.value)}
                      value={descricao}
                      className="inputdados"
                      size="small"
                    />
                    {formErrors.descricao && (
                      <S.ErrorMessage>
                        {formErrors.descricao}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
                  <Chip label="Cadastrar Plano" style={{
                    backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                    onClick={postPack} />
                </div>
              </Grid>
            </Grid>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

    </>
  );

  return (
    <>
      {content}
    </>
  );
};

export default EditarPackPremium;

function setError(arg0: string) {
  throw new Error("Function not implemented.");
}
