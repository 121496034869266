import styled from 'styled-components';

export const YearSelect = styled.select`
  box-shadow: 0px 0px 3px 0px #00000014;
  border-radius: 10px;
  border: none;
  background: white;
  padding: 2px 17px;
  margin-bottom: 21px;
  width: 150px;
  font-size: 14px;
  color: #505050;
  font-weight: 300;
`;

export const ProgressTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  text-align: center;
  margin-top: 20px;
`;

export const RequestCardContent = styled.div`

`;
export const ButtonAccessAll = styled.button`
  /* Adicione os estilos do botão aqui */
`;

export const WrapperBanner = styled.div`
  /* Estilos para o wrapper do banner */
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const WrapperBeneficios = styled.div`
  /* Estilos para o wrapper dos benefícios */
`;

export const WrapperLoading = styled.div`
  /* Estilos para o wrapper de loading */
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;


export const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content:center;
`;


export const WrapperImage = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Images = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;
export const WrapperBack = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 95%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 10px;
`;



export const RequestCard = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #F2F2F2;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  margin-bottom: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

export const RequestCardHeader = styled.div`
  background: #F7F7F7;
  color: #626262;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #E0E0E0;
`;

export const BenefitList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 30px;
`;



export const ContactButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const PrimaryButton = styled.button`
  background-color: #6a1b9a;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #4a148c;
  }
`;
export const SectionWrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const SectionTitle = styled.div`
  margin-bottom: 20px;
`;
export const KanbanWrapper = styled.div`
  display: flex;
  width: 100%;  /* Garante que o contêiner ocupe toda a largura */
  flex-wrap: nowrap;  /* Evita quebra de linha das colunas */
  justify-content: space-between;  /* Espaçamento uniforme entre as colunas */
  margin-top: 20px;
  padding: 10px;
`;
export const KanbanColumn = styled.div`
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 15px;
  flex: 1;  /* Garante que cada coluna ocupe espaço igual */
  min-height: 800px;
  margin: 0 10px;  /* Espaçamento horizontal entre as colunas */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-left: 0;  /* Remove margem extra na primeira coluna */
  }

  &:last-child {
    margin-right: 0;  /* Remove margem extra na última coluna */
  }
`;

export const KanbanColumnTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

export const EmptyMessage = styled.div`
  font-size: 14px;
  color: #aaa;
  text-align: center;
  margin-top: 20px;
`;

export const BenefitCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BenefitCardTitles = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  height: 70px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BenefitCardImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 10px;
`;

export const BenefitCardInfo = styled.div`
  flex: 1;
  margin-left: 10px;
`;

export const BenefitCardTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #333;
`;

export const BenefitCardSubtitle = styled.p`
  font-size: 12px;
  color: #666;
  margin: 0;
`;

export const BenefitCardButton = styled.button`
  background-color: #6a1b9a;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: #4a148c;
  }
`;
